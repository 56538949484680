import React from "react";
import styled from "styled-components";
import Markdown from "react-markdown";
import { BentoItem } from "../../BentoBox/BentoBox";
import { media } from "../../../styles/breakpoints";

export const MissionItem = styled(BentoItem)`
  grid-column: 1 / -1;
  grid-row: auto;
  align-self: start;
  grid-row: 2;

  h2 {
    position: relative;
    z-index: 10;
  }

  ${media.up("desktop")`
    grid-column: span 5;
  `}

  ${media.between("laptopS", "desktop")`
    grid-column: span 4;
  `}

  ${media.between("tabletM", "laptopS")`
    grid-column: span 4;
  `}

  ${media.down("tabletM")`
    grid-column: 2 / span 2;
    margin-right: 0.5rem;
  `}
`;

const MissionContent = styled(BentoItem)`
  border-radius: 10px;
  padding: 1.5rem 2rem 1rem 2rem;
  transition: all 0.3s ease;

  ${media.up("desktop")`
    padding: 1.5rem 2rem 1rem 2rem;
  `}

  ${media.between("laptopS", "desktop")`
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  `}

  ${media.between("tabletM", "laptopS")`
    padding: 1rem 1.25rem 0.5rem 1.25rem;
  `}

  ${media.down("tabletM")`
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  `}

  &:hover {
    background-color: rgba(255, 255, 255, 0.75);
    transform: translateY(-5px);
  }
`;

const Mission = ({ mission }) => {
  if (!mission) return null;

  return (
    <MissionItem disableBackground={true}>
      <h2>Mission</h2>
      <MissionContent>
        <Markdown>{mission}</Markdown>
      </MissionContent>
    </MissionItem>
  );
};

export default Mission;
