// components/SlideOut/SlideOutDetailsContainer.jsx

import React from "react";
import styled from "styled-components";
import { X } from "lucide-react";
import { breakpoints, heightBreakpoints } from "../../../styles/breakpoints";
import { typography } from "../../../styles/abstracts/typography";
import useStore from "../../../stores/store";

let itemHeight = "";
let fontSize = "";

const StyledDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  overflow-y: auto;
  grid-column: 2;
  grid-row: 2;
  opacity: ${({ isCollapsed }) => (isCollapsed ? 0 : 1)};
  transition: opacity 0.2s ease-in-out;

  ${({ $viewport }) => {
    if ($viewport.width <= breakpoints.mobileS) {
      return `
        padding: 0.5rem;
      `;
    }
    if ($viewport.width <= breakpoints.mobileM) {
      return `
        padding: 0.5rem;
      `;
    }
    if ($viewport.width <= breakpoints.mobileL) {
      return `
        padding: 0.5rem;
      `;
    }
    if ($viewport.width <= breakpoints.tabletS) {
      return `
        padding: 0.5rem;
      `;
    }
    if ($viewport.width <= breakpoints.tabletM) {
      return `
        padding: 0.5rem;
      `;
    }
    if ($viewport.width <= breakpoints.tabletL) {
      return `
        padding: 0.5rem;
      `;
    }

    if ($viewport.width <= breakpoints.laptopS) {
      return `
      `;
    }
    if ($viewport.width <= breakpoints.laptopL) {
      return `

      `;
    }
    if ($viewport.width <= breakpoints.desktop) {
      return `

      `;
    }
    if ($viewport.width <= breakpoints.desktopL) {
      return `

      `;
    }
  }}
`;

const DetailsItem = styled.div`
  position: relative;
  display: flex;
  background-color: ${({ theme, isActive, isHovered }) =>
    isActive || isHovered ? theme.accentColorPrimary : theme.primaryColor};
  border-radius: 5px;
  transition: background-color 0.3s ease;
  cursor: pointer;
  padding: 0.5rem 1rem;
  margin: 0.625rem 0;
  height: 40px;
  align-items: center;
  justify-content: flex-start;

  & h2,
  p {
    margin: 0;
    color: ${({ theme, isActive, isHovered }) =>
      isActive || isHovered ? theme.textColorDark : theme.textColorSecondary};
    transition: color 0.3s ease;
  }

  & p {
    font-size: ${typography.fontSizeXxs};
    font-style: italic;
    line-height: ${typography.fontHeightXs};
  }

  ${({ $viewport }) => {
    const { width, height, isLandscape } = $viewport;
    if (
      width <= breakpoints.mobileS ||
      width <= breakpoints.mobileM ||
      width <= breakpoints.mobileL
    ) {
      return `
        padding: 0.25rem 0.5rem;
        margin: 0.5rem 0;
        height: 2rem;

        & h2 {
          font-size: ${typography.fontSizeXs};
          line-height: ${typography.fontHeightXxs}; 
        }

        & p {
          font-size: ${typography.fontSizeXxs};
          line-height: ${typography.fontHeightXxs}; 
        }
      `;
    }
    if (width <= breakpoints.tabletS) {
      return `
        padding: 0.25rem 0.5rem;
        margin: 0.5rem 0;
        height: 2.5rem;

        ${
          isLandscape
            ? `
          width: calc(100% - 1.25rem);
          height: 2rem;
          margin: 0.25rem 0;
          flex-direction: row;
        `
            : ""
        }

        & h2 {
          font-size: ${typography.fontSizeXs};
          line-height: ${typography.fontHeightXxs}; 
        }
        & p {
          font-size: ${isLandscape ? typography.fontSizeXxxs : typography.fontSizeXxs};
          line-height: ${typography.fontHeightXxs}; 
        }
      `;
    }
    if (width <= breakpoints.tabletM) {
      return `
        padding: 0.25rem 0.5rem;
        margin: 0.5rem 0;
        height: 2.5rem;

        ${
          isLandscape
            ? `
          width: calc(100% - 1.25rem);
          height: 2rem;
          margin: 0.25rem 0;
          flex-direction: row;
        `
            : ""
        }

        & h2 {
          font-size: ${typography.fontSizeXs};
          line-height: ${typography.fontHeightXxs}; 
        }
        & p {
          font-size: ${isLandscape ? typography.fontSizeXxxs : typography.fontSizeXxs};
          line-height: ${typography.fontHeightXxs}; 
        }
      `;
    }
    if (width <= breakpoints.tabletL) {
      if (height <= heightBreakpoints.heightMIN) {
        itemHeight = "1.25rem";
        fontSize = typography.fontSizeXs;
      } else {
        itemHeight = "2rem";
        fontSize = typography.fontSizeSmall;
      }

      return `
        padding: 0.25rem 0.5rem;
        margin: 0.5rem 0;
        height: 2.5rem;
        align-items: center;

        ${
          isLandscape
            ? `
              width: calc(100% - 1.25rem);
              height: ${itemHeight};
              margin: 0.25rem 0;
              flex-direction: row;
              align-items: center;
            `
            : ""
        }

        & h2 {
          font-size: ${fontSize};
          line-height: ${typography.fontHeightXxs}; 
        }
        & p {
          font-size: ${isLandscape ? typography.fontSizeXxxs : typography.fontSizeXxs};
          line-height: ${typography.fontHeightXxs}; 
        }
      `;
    }
    if (
      width <= breakpoints.laptopS ||
      width <= breakpoints.laptopM ||
      width <= breakpoints.laptopL
    ) {
      return `
        flex-direction: row;
        
        & h2 {
          font-size: ${typography.fontSizeSmall};
        }

        & p {
          font-size: ${typography.fontSizeXxs};
          font-style: italic;
        }
      `;
    }
  }}
`;

const SlideOutDetailsContainer = ({
  items,
  selectedItem,
  hoveredItem,
  onItemClick,
  onItemHover,
  onItemLeave,

  viewport,
  isCollapsed,
}) => {
  const { theme } = useStore();

  return (
    <StyledDetailsContainer
      isCollapsed={isCollapsed}
      $viewport={viewport}
      className="details-container"
    >
      {items.map((item) => (
        <DetailsItem
          key={item.id}
          theme={theme}
          isActive={selectedItem === item.id}
          isHovered={hoveredItem === item.id}
          $viewport={viewport}
          onClick={() => onItemClick(item.id)}
          onMouseEnter={() => onItemHover(item.id)}
          onMouseLeave={onItemLeave}
          className="details-item"
        >
          <h2>{item.title || item.company}</h2>
          <p>{item.subtitle || item.role_title}</p>
        </DetailsItem>
      ))}
    </StyledDetailsContainer>
  );
};

export default SlideOutDetailsContainer;
