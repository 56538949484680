import React from "react";
import styled from "styled-components";
import { StyledImage } from "../StyledComponents";
import { BentoItem, ImageBentoItem } from "../../BentoBox/BentoBox";
import { media } from "../../../styles/breakpoints";

const HeroWrapper = styled.div`
  grid-column: 1 / -1;
  grid-row: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  height: 90vh;
  margin-top: -7.5vh;
  margin-bottom: 5rem;

  ${media.up("desktop")`
    grid-column: 1 / -1;
  `}

  ${media.between("laptopS", "desktop")`
    margin-top: -12.5vh;
    margin-bottom: 3.5rem;
  `}

  ${media.between("tabletM", "laptopS")`
    grid-column: 1 / -1;
  `}

 
  ${media.between("mobileL", "tabletM")`
    height: fit-content;
    margin-top: -0.5vh;
    margin-bottom: 1.75rem;
    grid-template-columns: 1fr;
    grid-column: 2 / span 2;
    gap: 0;
  `}

  ${media.down("mobileL")`
    height: fit-content;
    margin-top: -0.5vh;
    margin-bottom: 3.5rem;
    grid-template-columns: 1fr;
    grid-column: 2 / span 2;
    gap: 0;
  `}
`;

const HeroContentItem = styled(BentoItem)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 2rem;
  margin-bottom: -2.25rem;
  order: 2;

  h1 {
    font-size: 6.25rem;
    line-height: 5.5rem;
    margin: 0;
    color: ${(props) => props.theme.primaryColor};
    text-align: right;
    text-transform: uppercase;
  }

  h2 {
    font-size: 2rem;
    text-align: right;
    margin-bottom: 0;
    color: ${(props) => props.theme.secondaryColor};
  }

  ${media.up("desktop")`
    order: 0;
    padding: 2rem;
    justify-content: flex-end;

    h1 {
      font-size: 6rem;
      line-height: 5rem;
    }

    h2 {
      font-size: 2rem;
    }
  `}

  ${media.between("tabletL", "desktop")`
    justify-content: flex-end;
    padding: 0 0 2rem 0;

    h1 {
      font-size: 3rem;
      line-height: 2.25rem;
    }
    h2 {
      font-size: 1.25rem;
    }
  `}

  ${media.between("tabletS", "tabletL")`
    justify-content: flex-end;
    padding: 0 0 2rem 0;
    order: 2;

    h1 {
      font-size: 2.5rem;
      line-height: 2.25rem;
    }
    h2 {
      font-size: 1.25rem;
    }
  `}

  ${media.between("mobileL", "tabletS")`
    order: 2;
    justify-content: flex-start;
    padding: 0.5rem 0 2rem 0;

    h1 {
      font-size: 2.75rem;
      line-height: 2.25rem;
    }

    h2 {
      font-size: 1rem;
    }
  `}

  ${media.down("mobileL")`
    order: 2;
    padding: 1rem 0.5rem 2rem 0 ;

    h1 {
      font-size: 2.5rem;
      line-height: 2rem;
    }

    h2 {
      font-size: 1rem;
    }
  `}
`;

const HeroImageItem = styled(ImageBentoItem)`
  overflow: hidden;
  height: 100%;
  min-height: 75vh;
  order: 2;

  ${media.down("tabletM")`
    order: 1;
  `}
`;

const StyledHeroImage = styled(StyledImage)`
  object-position: 75% 0%;
  &:hover {
    transform: scale(1) translateY(0);
  }
`;

const Hero = ({ caseStudy }) => (
  <HeroWrapper>
    <HeroContentItem disableBackground={true}>
      <h2>{caseStudy?.company}</h2>
      <h1>{caseStudy?.name}</h1>
    </HeroContentItem>
    <HeroImageItem>
      <StyledHeroImage src={caseStudy?.featured_img} alt={caseStudy?.name} />
    </HeroImageItem>
  </HeroWrapper>
);

export default Hero;
