import React, {
  useMemo,
  useState,
  useRef,
  useCallback,
  useEffect,
} from "react";
import "./stylesGameDev.scss";
import useStore from "../stores/store";
import {
  BentoBox,
  BentoItem,
  BentoWrapper,
} from "../components/BentoBox/BentoBox";
import breakpoints, { media } from "../styles/breakpoints";
import styled from "styled-components";
import {
  Wrapper,
  Content,
  ScrollContent,
  ContentWrapper,
  StyledImage,
} from "../components/BentoBox/StyledComponents";
import Scrollbar from "../components/Scrollbar/Scrollbar";
import Hero from "../components/BentoBox/Hero";
import { useViewport } from "../context/ViewportContext";
import { preloadBackgroundImages, preloadImages } from "../utils/preloadImages";
import SplitContent from "../experiences/Photography/Imprisoned/SplitContent";

const Title = styled.h1`
  && {
    font-size: calc(4.5 * (1vh + 1vw));
    line-height: calc(3.25 * (1vh + 1vw));
    text-align: right;

    ${media.between("laptopL", "desktop")`
      font-size: calc(5.25 * (1vh + 1vw));
      line-height: calc(3.75 * (1vh + 1vw));
    `}

    ${media.between("laptopS", "laptopL")`
      font-size: clamp(2.5rem, 5vw + 1rem, 5.5rem);
      line-height: clamp(1.5rem, 3vw + 1rem, 3.5rem);
    `}

    ${media.between("tabletL", "laptopS")`
      font-size: calc(3.75 * (1vh + 1vw));
      line-height: calc(3 * (1vh + 1vw));
    `}

    ${media.between("tabletM", "tabletL")`
      font-size: clamp(2.5rem, 5vw + 1rem, 5.5rem);
      line-height: clamp(1.5rem, 3vw + 1rem, 3.5rem);
    `}

    ${media.between("tabletS", "tabletM")`
      font-size: clamp(2.5rem, 10vw + 1rem, 10.5rem);
      line-height: clamp(3.25rem, 3vw + 1rem, 5.5rem);
    `}


    ${media.down("tabletS")`
      font-size: calc(4.25 * (1vh + 1vw));
      line-height: calc(3.75 * (1vh + 1vw));
      margin-bottom: 0;
    `}
  }
`;

const GridLayout = styled.div`
  display: grid;
  gap: 1rem;
  width: 100%;
  grid-column: 1/-1;
  grid-row: auto;

  ${media.down("mobileL")`
    grid-template-columns: 1fr;
    grid-column: 2 / -1;
    margin-top: 0;
  `}
`;

const Grid5x1 = styled(GridLayout)`
  grid-template-columns: repeat(5, 1fr);

  ${media.between("mobileL", "tabletM")`
    grid-template-columns: 1fr;
    grid-column: 2/ span 2;
  `}

  ${media.down("mobileL")`
    grid-template-columns: 1fr;
    grid-column: 2/ span 2;
  `}
`;

const Grid3x2 = styled(GridLayout)`
  grid-template-columns: repeat(3, 1fr);

  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
  `}

  ${media.between("tabletM", "laptopL")`
  `}

  ${media.between("mobileL", "tabletM")`
    grid-template-columns: 1fr;
    grid-column: 2/ span 2;
  `}

  ${media.down("mobileL")`
    grid-template-columns: 1fr;
    grid-column: 2/ span 2;
  `}
`;

const SplitLayout = styled.div`
  display: grid;
  gap: 1rem;
  grid-column: 1/-1;

  ${media.down("tabletM")`
    grid-template-columns: 1fr;
  `}
`;

const SplitMainAndSidebar = styled(SplitLayout)`
  grid-template-columns: ${(props) =>
    props.reverseOrder ? "1fr 1fr" : "1fr 1fr"};
  direction: ${(props) => (props.reverseOrder ? "rtl" : "ltr")};

  > * {
    direction: ltr; // Reset direction for children
  }

  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
  `}

  ${media.between("tabletM", "laptopL")`
    grid-column: 1 / -1;
  `}

  ${media.between("mobileL", "tabletM")`
    grid-template-columns: 1fr;
    grid-column: 2 / -1;
    direction: ltr;
  `}

  ${media.down("mobileL")`
    grid-template-columns: 1fr;
    grid-column: 2 / -1;
    direction: ltr;
  `}
`;

const Grid1x1 = styled(GridLayout)`
  grid-template-columns: 1fr;
  grid-column: 1;

  ${media.down("tabletS")`
    grid-template-columns: 1fr;
  `}
`;

const Grid2x2 = styled(GridLayout)`
  grid-template-columns: repeat(2, 1fr);
  grid-column: 1/-1;

  ${media.up("desktop")`
  `}

  ${media.between("laptopS", "desktop")`
  `}

  ${media.between("tabletM", "laptopS")`
    grid-template-columns: 1fr;
    grid-column: 1/-1;
    margin-right: 0.5rem;
  `}

  ${media.between("mobileL", "tabletM")`
    grid-template-columns: 1fr;
    grid-column: 2/-1;
    margin-right: 0.5rem;
  `}

  ${media.down("mobileL")`
    grid-column: 2 / span 2;
    grid-template-columns: 1fr;
  `}
`;

const Grid3x3 = styled(GridLayout)`
  grid-template-columns: repeat(3, 1fr);
  grid-column: 1;

  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
  `}

  ${media.between("tabletM", "laptopL")`
  `}

  ${media.between("mobileL", "tabletM")`
    grid-template-columns: 1fr;
  `}

  ${media.down("mobileL")`
    grid-template-columns: 1fr;
  `}
`;

const FullWidthItem = styled(BentoItem)`
  grid-column: 1/-1;
  height: 100%;

  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
  `}

  ${media.between("tabletM", "laptopL")`
    grid-column: 1/-1;
    margin-right: 0.5rem;
  `}

  ${media.between("mobileL", "tabletM")`
    grid-column: 2/-1;
    margin-right: 0.5rem;
  `}

  ${media.down("mobileL")`
    grid-column: 2 / span 2;
  `}
`;

const GridItem = styled(BentoItem)`
  height: 100%;

  ${media.between("mobileL", "tabletL")`
    margin-right: 0.5rem;
  `}

  ${media.down("mobileL")`
    margin-right: 0.5rem;
  `}
`;

const MainContent = styled(BentoItem)`
  height: 100%;
  align-content: center;
  justify-self: center;
  width: 80%;

  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
  `}

  ${media.between("tabletM", "laptopL")`
  `}

  ${media.between("mobileL", "tabletM")`
    margin-right: 0.5rem;
    width: 95%;
  `}

  ${media.down("mobileL")`
    margin-right: 0.5rem;
    width: 95%;
  `}
`;

const ImageItem = styled(BentoItem)`
  height: 100%;

  ${media.down("tabletS")`
    margin-right: 0.5rem;
  `}
`;

function GameDev() {
  const [isLoading, setIsLoading] = useState(true);
  const viewport = useViewport();
  const { width, height } = viewport;
  const [orientationKey, setOrientationKey] = useState(0);
  const [shouldShowScrollbar, setShouldShowScrollbar] = useState(false);
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const [isLandscape, setIsLandscape] = useState(viewport.isLandscape);
  const { theme } = useStore();

  const { images, fetchImages } = useStore();

  const contentKey = useMemo(() => {
    return `portrait-${viewport.width}-${viewport.height}-${isLandscape}`;
  }, [viewport.width, viewport.height, isLandscape]);

  const updateDimensions = useCallback(() => {
    if (containerRef.current && contentRef.current) {
      const containerHeight = containerRef.current.clientHeight;
      const contentHeight = contentRef.current.scrollHeight;
      setShouldShowScrollbar(contentHeight > containerHeight);
    }
  }, []);

  useEffect(() => {
    console.log("Portraiture component mounted");
    const loadImagesAndPreload = async () => {
      try {
        await fetchImages("photography", "imprisoned");
        await Promise.all([
          preloadImages(".grid__img"),
          preloadBackgroundImages(".grid__img"),
        ]);
        console.log("Images preloaded");
        setIsLoading(false);
        window.scrollTo(0, 0);
        updateDimensions();
      } catch (error) {
        console.error("Error loading images:", error);
        setIsLoading(false);
      }
    };

    loadImagesAndPreload();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [fetchImages, updateDimensions]);

  useEffect(() => {
    const handleOrientationChange = () => {
      setOrientationKey((prev) => prev + 1);
      setIsLandscape(window.matchMedia("(orientation: landscape)").matches);
    };

    window.addEventListener("orientationchange", handleOrientationChange);
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    updateDimensions();
  }, [viewport, isLandscape, updateDimensions]);

  const leftMargin = useMemo(() => {
    if (!viewport) return "5.25rem";
    const { width } = viewport;

    if (width < breakpoints.mobileL) return "3rem";
    if (width < breakpoints.tabletL) return "3.5rem";
    if (width < breakpoints.laptopS) return "5.25rem";
    if (width < breakpoints.desktop) return "5.25rem";
    return "5.25rem";
  }, [viewport]);

  const gridImages = images.map((image) => image.file);

  return (
    <Wrapper>
      <Content>
        <Scrollbar
          scrollbarPosition="left"
          theme={theme}
          applyScrolling={true}
          forceScrollbar={true}
          trackMargin={{
            top: "0",
            bottom: "0",
            left: leftMargin,
            right: "0",
          }}
        >
          <ScrollContent>
            <ContentWrapper>
              <BentoBox>
                <Hero>
                  <Title>GameDev: Yr.2</Title>
                </Hero>
                <FullWidthItem>
                  <StyledImage src={gridImages[1]} />
                </FullWidthItem>
                <SplitContent headline="Prologue:" image={gridImages[0]}>
                  <p>
                    I never set out to be a developer. Hell, I actively avoided
                    it for years. When I stumbled into game development, I
                    didn't expect it to revolutionize my entire skill set,{" "}
                    <em>and</em> help me recover from two disasters.
                  </p>

                  <p>
                    But here we are, two years in, and that's exactly what's
                    happened...
                  </p>
                </SplitContent>
                <SplitContent
                  headline="Chapter 1: The Catalyst"
                  image={gridImages[0]}
                  imageOnLeft={false}
                >
                  <p>
                    Don't get me wrong, I've made it pretty far in life with
                    just enough coding skills to customize a Wordpress theme and
                    feel dangerous.
                  </p>
                </SplitContent>
                <SplitContent
                  headline="Chapter 2: The Challenge"
                  image={gridImages[0]}
                >
                  <p>
                    When I lost my development team to the war in Ukraine, I
                    decided I couldn't leave such a gaping weakness in my
                    skillset anymore. After years of trying to learn
                    programming, and failing, enough was finally enough. I
                    needed to learn programming well enough to build my own
                    solutions, removing forced dependencies on 3rd parties.
                  </p>
                </SplitContent>
                <SplitContent
                  headline="Chapter 3: The Odyssey"
                  image={gridImages[0]}
                  imageOnLeft={false}
                >
                  <p>
                    Historically, for one reason or another, I just couldn't
                    stick with learning to code. That changed when I found game
                    development, specifically the fantastic courses by Stephen
                    Ulibarri.
                  </p>
                  <p>
                    Apparently, I needed the attachment to games (something I
                    love deeply) to keep my interest long enough to push past
                    the initial boring (aka fundamental) shit.
                  </p>
                  <p>
                    My efforts really picked up after I started learning
                    computer science fundamentals thanks to Harvard's CS50. I
                    didn't understand a lot of it at first but, persistence pays
                    off, and this year I'm finally able to put the pieces
                    together, and finally say "I (almost) understand."
                  </p>
                </SplitContent>
              </BentoBox>
            </ContentWrapper>
          </ScrollContent>
        </Scrollbar>
      </Content>
    </Wrapper>
  );
}

export default GameDev;
