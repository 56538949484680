// components/Menu/Menu.jsx

import React, { useState, useEffect, useRef } from "react";
import { AnimatePresence } from "framer-motion";
import Button from "./Button/Button";
import Nav from "./Nav/Nav";
import { MenuWrapper, StyledMenu, menu } from "./Menu.styled";
import { useViewport } from "../../context/ViewportContext";
import useStore from "../../stores/store";

export default function Menu() {
  const { theme } = useStore();
  const viewport = useViewport();
  const [isActive, setIsActive] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !event.target.closest(".pagination")
      ) {
        setIsActive(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  const handleLinkClick = () => {
    setIsActive(false);
  };

  return (
    <MenuWrapper ref={menuRef} $viewport={viewport}>
      <StyledMenu
        theme={theme}
        variants={menu}
        custom={viewport}
        animate={isActive ? "open" : "closed"}
        initial="closed"
        $viewport={viewport}
      >
        <AnimatePresence>
          {isActive && (
            <Nav
              onLinkClick={handleLinkClick}
              $viewport={viewport}
              theme={theme}
            />
          )}
        </AnimatePresence>
      </StyledMenu>
      <Button
        isActive={isActive}
        toggleMenu={() => {
          setIsActive(!isActive);
        }}
        $viewport={viewport}
        theme={theme}
      />
    </MenuWrapper>
  );
}
