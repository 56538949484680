// components/Resume/SlideOutResumeTabs.jsx

import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/breakpoints";
import { typography } from "../../styles/abstracts/typography";
import useStore from "../../stores/store";

const StyledSlideOutTabs = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: space-between;
  z-index: 955;

  ${({ $viewport }) => {
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
        gap: 0.5rem;
      `;
    }
    if (width <= breakpoints.mobileM) {
      return `
        gap: 0.375rem;
      `;
    }
    // ... (add other breakpoint-specific styles as needed)
  }}
`;

const Tab = styled.div`
  background-color: ${({ theme }) => theme.secondaryColor};
  border-radius: 5px;
  color: ${({ theme }) => theme.textColorLight};
  padding: 0.25rem 0.75rem;
  cursor: pointer;
  transition:
    background-color 0.1s ease,
    transform 0.1s ease,
    color 0.1s ease;
  flex-grow: 1;
  text-align: center;

  &:hover {
    background-color: ${({ theme }) => theme.accentColorSecondary};
    transform: scale(1.02);
    color: ${({ theme }) => theme.accentColorPrimary};
  }

  & p {
    font-size: ${typography.fontSizeXxs};
    white-space: nowrap;
    margin: 0;
  }

  ${({ $viewport }) => {
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
        padding: 0.25rem 0.75rem;
        & p {
          font-size: ${typography.fontSizeXxxs};
        }
      `;
    }
    if (width <= breakpoints.mobileM) {
      return `
        padding: 0.25rem 0.75rem;
        & p {
          font-size: ${typography.fontSizeXxs};
        }
      `;
    }
    // ... (add other breakpoint-specific styles as needed)
  }}
`;

function ResumeSlideOutTabs({ setSelectedTab, viewport }) {
  const { theme } = useStore();

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const tabs = ["Highlights", "Skills", "Technologies", "Testimonials"];

  return (
    <StyledSlideOutTabs $viewport={viewport}>
      {tabs.map((tab) => (
        <Tab
          key={tab}
          theme={theme}
          onClick={() => handleTabClick(tab)}
          $viewport={viewport}
        >
          <p>{tab}</p>
        </Tab>
      ))}
    </StyledSlideOutTabs>
  );
}

export default ResumeSlideOutTabs;
