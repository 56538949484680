/**
 * @file createSkillSlice.js
 * @description This file defines a slice for managing skill-related state in a Zustand store.
 * It provides actions for fetching, setting, and refetching skills using React Query.
 */

import { queryClient } from "../store";
import * as SkillService from "../../services/SkillService";

/**
 * Creates a skill slice for the Zustand store
 *
 * @param {Function} set - Function to update the store's state
 * @param {Function} get - Function to retrieve the current state
 * @returns {Object} An object containing the skill state and actions
 */
const createSkillSlice = (set, get) => ({
  /**
   * Array of skill objects
   * @type {Array<Object>}
   */
  skills: [],

  /**
   * Sets the skills in the store
   * @param {Array<Object>} skills - The skills to set
   */
  setSkills: (skills) => set({ skills }),

  /**
   * Fetches skills from the API and updates the store
   * @returns {Promise<Array<Object>>} A promise that resolves to the fetched skills
   */
  fetchSkills: async () => {
    const skills = await queryClient.fetchQuery(
      "skills",
      SkillService.fetchSkills
    );
    set({ skills });
    return skills;
  },

  /**
   * Invalidates the skills query, forcing a refetch on the next request
   */
  refetchSkills: () => queryClient.invalidateQueries("skills"),
});

export default createSkillSlice;

// Usage example:
// import useStore from '../store';
//
// function SkillList() {
//   const { skills, fetchSkills } = useStore();
//
//   useEffect(() => {
//     fetchSkills();
//   }, []);
//
//   return (
//     <ul>
//       {skills.map(skill => (
//         <li key={skill.id}>{skill.name} - Proficiency: {skill.proficiency}</li>
//       ))}
//     </ul>
//   );
// }
