// components/CaseStudy/Goal/Goal.jsx

import React from "react";
import styled from "styled-components";
import Markdown from "react-markdown";
import { StyledImage } from "../StyledComponents";
import { BentoItem, ImageBentoItem } from "../../BentoBox/BentoBox";
import { media } from "../../../styles/breakpoints";

const GoalContent = styled(BentoItem)`
  border-radius: 10px;
  padding: 1.5rem 2rem 1rem 2rem;
  transition: all 0.3s ease;
  position: relative;

  &:hover {
    background-color: rgba(255, 255, 255, 0.75);
    transform: translateY(-5px);
  }

  ${media.up("desktop")`
    padding: 1.5rem 2rem 1rem 2rem;
  `}

  ${media.between("laptopS", "desktop")`
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  `}

  ${media.between("tabletM", "laptopS")`
    padding: 1rem 1.25rem 0.5rem 1.25rem;
  `}

  ${media.down("tabletM")`
    padding: 1.25rem 1.5rem 0.5rem 1.5rem;
  `}
`;

export const GoalContentItem = styled(BentoItem)`
  grid-column: 1 / -1;
  grid-row: auto;
  height: fit-content;
  grid-row: 7;

  ${media.up("desktop")`
    grid-column: 4 / span 4;
  `}

  ${media.between("laptopS", "desktop")`
    grid-column: 1 / span 4;
    margin-top: 2.5rem;
    align-self: start;
  `}

  ${media.between("tabletM", "laptopS")`
    grid-column: 1 / span 4;
    align-self: start;
  `}

  ${media.between("mobileL", "tabletM")`
    grid-column: 2 / span 2;
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    grid-row: 10;
  `}

  ${media.down("mobileL")`
    grid-column: 2 / span 2;
    margin-top: 0;
    margin-right: 0.5rem;
    grid-row: 10;
  `}

  h2 {
    position: relative;
    z-index: 10;
  }
`;

export const GoalImageItem = styled(ImageBentoItem)`
  grid-column: 1 / -1;
  grid-row: auto;
  grid-row: 7;

  ${media.up("desktop")`
    grid-column: 2 / span 2;
  `}

  ${media.between("laptopS", "desktop")`
    grid-column: 5 / span 2;
    margin-top: 2.5rem;
    align-self: start;
  `}

  ${media.between("tabletM", "laptopS")`
    grid-column: span 2;
  `}

  ${media.between("mobileL", "tabletM")`
    grid-column: 2 / span 2;
    margin-top: 2.5rem;
    max-height: 15rem;
    max-width: 20rem;
    grid-row: 9;
  `}

  ${media.down("mobileL")`
    grid-column: 2 / span 2;
    margin: 2.5rem 0.5rem 2rem 0;
    max-height: 15rem;
    max-width: 15rem;
    grid-row: 9;
  `}
`;

const Goal = ({ goal, disableBackground }) => {
  if (!goal) return null;

  const hasImage = !!goal.img;

  return (
    <>
      {hasImage && (
        <GoalImageItem disableBackground={disableBackground}>
          <StyledImage src={goal.img} alt="Goal" />
        </GoalImageItem>
      )}
      <GoalContentItem disableBackground={true}>
        <h2>Goal</h2>
        <GoalContent>
          <Markdown>{goal.content}</Markdown>
        </GoalContent>
      </GoalContentItem>
    </>
  );
};

export default Goal;
