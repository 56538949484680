import React, { useCallback } from "react";
import styled from "styled-components";
import { useViewport } from "../../context/ViewportContext";
import { breakpoints } from "../../styles/breakpoints";
import Loader from "../Loader/Loader";
import TestimonialItem from "./TestimonialItem";
import useStore from "../../stores/store";

const TestimonialHeader = styled.h1`
  font-size: 2rem;
  color: ${({ theme }) => theme.textColorPrimary || "#333"};
  margin: 0;
  padding: 1rem;
  flex-shrink: 0;
  grid-row: 1;

  ${({ $viewport }) => {
    if (!$viewport) return "";
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
        font-size: 0.9rem;
        padding: 0 0 0 0.75rem;
      `;
    } else if (width <= breakpoints.mobileM) {
      return `
        font-size: 1.05rem;
        padding: 0 0 0 0.75rem;
      `;
    } else if (width <= breakpoints.mobileL) {
      return `
        font-size: 1.125rem;
        padding: 0 0 0 1rem;
      `;
    } else if (width <= breakpoints.tabletS) {
      return `
        font-size: 1.25rem;
        padding: 0 0 0 0.75rem;
      `;
    } else if (width <= breakpoints.tabletM) {
      return `
        font-size: ${isLandscape ? `1.25rem` : `1.5rem`};
        padding: ${isLandscape ? `0.25rem 0.75rem` : `0.75rem`};
      `;
    } else if (width <= breakpoints.tabletL) {
      return `
        font-size: 1.25rem;
        padding: 0.5rem 0 0 1rem;
      `;
    } else {
      return `
        padding: 0 0 0 2rem;
      `;
    }
  }}
`;

const TestimonialContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  grid-row: 2;
  grid-column: 2;
  max-width: 100%;
  align-self: center;
  justify-self: center;
  height: 100%;

  ${({ $viewport }) => {
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
        max-width: 90%;
        grid-column: 1;
      `;
    }
    if (width <= breakpoints.mobileM) {
      return `
        max-width: 90%;
        grid-column: 1;
      `;
    }
    if (width <= breakpoints.mobileL) {
      return `
        max-width: 90%;
        grid-column: 1;
      `;
    }
    if (width <= breakpoints.tabletS) {
      return `
        max-width: 90%;
        grid-column: 1;
      `;
    }
    if (width <= breakpoints.tabletM) {
      return `
          max-width: 425px;
          justify-content: center;
          grid-column: 1;

          ${
            isLandscape
              ? `
                max-width: 90%;
                grid-column: 1;
                justify-content: center;
              `
              : ""
          }
      `;
    }
    if (width <= breakpoints.tabletL) {
      return `
          max-width: 425px;
          justify-content: center;
          grid-column: 1;

          ${
            isLandscape
              ? `
                max-width: 90%;
                grid-column: 1;
                justify-content: center;
              `
              : ""
          }
      `;
    }
    if (width <= breakpoints.laptopS) {
      return `
          max-width: 425px;
          justify-content: center;
          grid-column: 1;

          ${
            isLandscape
              ? `
                max-width: 90%;
                grid-column: 1;
                justify-content: center;
              `
              : ""
          }
      `;
    }
    if (width <= breakpoints.laptopM) {
      return `
          max-width: 425px;
          justify-content: center;
          grid-column: 1;

          ${
            isLandscape
              ? `
                max-width: 75%;
                grid-column: 1;
                justify-content: center;
              `
              : ""
          }
      `;
    }
    if (width <= breakpoints.laptopL) {
      return `
          max-width: 425px;
          justify-content: center;
          grid-column: 1;

          ${
            isLandscape
              ? `
                max-width: 75%;
                grid-column: 1;
                justify-content: center;
              `
              : ""
          }
      `;
    }
    if (width <= breakpoints.desktop) {
      return `
        max-width: 95%;
        grid-column: 1;
        margin-top: -0rem;
        flex-wrap: nowrap;
      `;
    }
    if (width <= breakpoints.desktopL) {
      return `
        max-width: 550px;
        grid-column: 1;
        margin-top: -2.5rem;
      `;
    }
    return `

    `;
  }}
`;

const Testimonial = ({ data, isLoading, error, currentPage }) => {
  const { theme } = useStore();
  const viewport = useViewport();

  const renderTestimonial = useCallback(
    (testimonial, index) => (
      <TestimonialItem
        key={index}
        testimonial={testimonial}
        theme={theme}
        viewport={viewport}
        index={index}
      />
    ),
    [theme, viewport]
  );

  if (error) {
    return <div>Error loading testimonials: {error}</div>;
  }

  if (isLoading) {
    return <Loader isLoading={isLoading} text="Loading Testimonials..." />;
  }

  if (!data || data.length === 0) {
    return <div>No testimonials available.</div>;
  }

  return (
    <>
      <TestimonialHeader $viewport={viewport} theme={theme}>
        [ And the People Said... ]
      </TestimonialHeader>
      <TestimonialContainer $viewport={viewport} theme={theme}>
        {data.map(renderTestimonial)}
      </TestimonialContainer>
    </>
  );
};

export default Testimonial;
