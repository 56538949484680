import React from "react";
import styled from "styled-components";
import {
  BentoItem,
  ImageBentoItem,
} from "../../../components/BentoBox/BentoBox";
import { media } from "../../../styles/breakpoints";
import { StyledImage } from "../../../components/BentoBox/BentoBox";

const SplitContentWrapper = styled.div`
  display: grid;
  grid-column: 1 / -1;
  grid-row: auto;
  gap: 1rem;

  ${media.up("desktop")`
    grid-template-columns: repeat(9, 1fr);
  `}

  ${media.between("laptopL", "desktop")`
    grid-template-columns: repeat(6, 1fr);
  `}

  ${media.between("tabletM", "laptopL")`
    grid-template-columns: repeat(6, 1fr);
    grid-column: 1 / -1;
  `}

  ${media.between("mobileL", "tabletM")`
    grid-template-columns: 1fr;
    grid-column: 2/ span 2;
    margin-top: 0;
    gap: 0;
  `}

  ${media.down("mobileL")`
    grid-template-columns: 1fr;
    grid-column: 2/ span 2;
    margin-top: 0;
  `}
`;

const SplitContentItem = styled(BentoItem)`
  grid-column: ${(props) => (props.isLeft ? "1 / span 5" : "6 / -1")};
  height: fit-content;
  align-self: center;
  justify-self: center;
  width: 80%;

  h2 {
    position: relative;
    padding-left: 1.75rem;
    z-index: 10;
  }

  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
    grid-column: ${(props) => (props.isLeft ? "1 / span 3" : "4 / -1")};
    margin-top: 2.5rem;
  `}

  ${media.between("tabletM", "laptopL")`
    grid-column: ${(props) => (props.isLeft ? "1 / span 3" : "4 / -1")};
    margin-top: 2.5rem;
  `}

  ${media.between("mobileL", "tabletM")`
    grid-column: ${(props) => (props.isLeft ? "2 / span 2" : "2 / span 2")};
    grid-row: ${(props) => (props.isLeft ? 1 : 2)};
    width: 100%;
    margin-top: 0.5rem;
    align-self: end;

    h2 {
      padding-left: 0.5rem;
    }
  `}

  ${media.down("mobileL")`
    grid-column: 1 / -1;
    grid-row: ${(props) => (props.isLeft ? 1 : 2)};
    width: 100%;

    h2 {
      padding-left: 0;
    }
  `}
`;

const SplitContentImageItem = styled(ImageBentoItem)`
  grid-column: ${(props) => (props.isLeft ? "1 / span 5" : "6 / -1")};
  max-height: 30rem;

  ${media.up("desktop")`
    max-height: 80vh;
  `}

  ${media.between("laptopL", "desktop")`
    grid-column: ${(props) => (props.isLeft ? "1 / span 3" : "4 / -1")};
    max-height: 90vh;
    align-self: end;
  `}

  ${media.between("tabletM", "laptopL")`
    max-height: 90vh;
    grid-column: ${(props) => (props.isLeft ? "1 / span 3" : "4 / -1")};
    margin-top: 0;
  `}

  ${media.between("mobileL", "tabletM")`
    grid-column: ${(props) => (props.isLeft ? "2 / span 2" : "2 / span 2")};
    grid-row: ${(props) => (props.isLeft ? 1 : 2)};
    margin-right: 0.5rem;
  `}

  ${media.down("mobileL")`
    grid-column: ${(props) => (props.isLeft ? "1" : "1")};
  `}
`;

const SplitContentCopy = styled(BentoItem)`
  border-radius: 8px;
  padding: 1.5rem 2rem 1rem 2rem;
  transition: all 0.3s ease;
  position: relative;

  &:hover {
    transform: translateY(-5px);
  }

  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  `}

  ${media.between("tabletM", "laptopL")`
    padding: 1rem 1.25rem 0.5rem 1.25rem;
  `}

  ${media.between("mobileL", "tabletM")`
    padding: 0.5rem;
  `}

  ${media.down("mobileL")`
    padding: 1.25rem 0.5rem 0.5rem 0;
  `}
`;

const SplitContent = ({
  image,
  children,
  headline,
  disableBackground,
  imageOnLeft = true,
}) => {
  if (!image) return null;

  const ImageComponent = (
    <SplitContentImageItem
      isLeft={imageOnLeft}
      disableBackground={disableBackground}
    >
      <StyledImage src={image} alt="SplitContent" />
    </SplitContentImageItem>
  );

  const ContentComponent = (
    <SplitContentItem isLeft={!imageOnLeft} disableBackground={true}>
      {headline ? <h2>{headline}</h2> : ""}
      <SplitContentCopy disableBackground={true}>{children}</SplitContentCopy>
    </SplitContentItem>
  );

  return (
    <SplitContentWrapper>
      {imageOnLeft ? ImageComponent : ContentComponent}
      {imageOnLeft ? ContentComponent : ImageComponent}
    </SplitContentWrapper>
  );
};

export default SplitContent;
