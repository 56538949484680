import React from "react";
import styled from "styled-components";
import { media, breakpoints } from "../../styles/breakpoints";

const SectionWrapper = styled.section`
  position: relative;
  align-content: center;
  display: flex;
  height: ${(props) => props.height || "50vh"};
  min-height: ${(props) => props.minHeight || "200px"};
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.textColor};
  overflow: hidden;
`;

const SectionContent = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 2;
  ${(props) => props.gap && `gap: ${props.gap};`}

  ${(props) =>
    Object.entries(breakpoints).map(
      ([breakpoint, size]) => media.up(breakpoint)`
        flex-direction: ${props.responsiveConfig[breakpoint]?.flexDirection || "column"};
        align-items: ${props.responsiveConfig[breakpoint]?.alignItems || "center"};
        justify-content: ${props.responsiveConfig[breakpoint]?.justifyContent || "center"};
        ${
          props.responsiveConfig[breakpoint]?.columnRatio
            ? `
          > * {
            flex: ${props.responsiveConfig[breakpoint].columnRatio.split(" ")[0]};
          }
          > *:last-child {
            flex: ${props.responsiveConfig[breakpoint].columnRatio.split(" ")[1] || props.responsiveConfig[breakpoint].columnRatio.split(" ")[0]};
          }
          `
            : ""
        }
      `
    )}
`;

const ImageWrapper = styled.div`
  ${(props) =>
    props.isBackground
      ? `
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  `
      : `
    position: relative;
    flex: 1;
    height: 100%;
    display: flex;
  `}
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  flex: 1;
`;

const Section = ({
  children,
  height,
  minHeight,
  bgColor,
  textColor,
  imageSrc,
  isBackgroundImage,
  gap,
  responsiveConfig = {},
}) => {
  const imageElement = imageSrc && (
    <ImageWrapper isBackground={isBackgroundImage}>
      <Image
        src={imageSrc}
        alt={isBackgroundImage ? "Section background" : "Section image"}
      />
    </ImageWrapper>
  );

  return (
    <SectionWrapper
      height={height}
      minHeight={minHeight}
      bgColor={bgColor}
      textColor={textColor}
    >
      {isBackgroundImage && imageElement}
      <SectionContent gap={gap} responsiveConfig={responsiveConfig}>
        {children}
        {!isBackgroundImage && imageElement}
      </SectionContent>
    </SectionWrapper>
  );
};

export default Section;
