import React from "react";
import styled from "styled-components";
import Markdown from "react-markdown";
import { StyledImage } from "../StyledComponents";
import { BentoItem, ImageBentoItem } from "../../BentoBox/BentoBox";
import { media } from "../../../styles/breakpoints";

const PhaseWrapper = styled.div`
  ${media.up("tabletM")`
    display: contents;
  `}

  ${media.down("tabletM")`
    display: flex;
    flex-direction: column;
    grid-column: 2 / span 2;
    margin-bottom: 2rem;
    margin-right: 0.5rem;
  `}
`;

const PhaseContentItem = styled(BentoItem)`
  grid-row: ${(props) => props.row};
  ${media.up("desktop")`
    grid-column: ${(props) => (props.index % 2 === 0 ? "2 / span 4" : "5 / span 4")};
  `}

  ${media.between("laptopS", "desktop")`
    grid-column: ${(props) => (props.index % 2 === 0 ? "4 / span 3" : "1 / span 3")};
  `}

  ${media.between("tabletM", "laptopS")`
    grid-column: ${(props) => (props.index % 2 === 0 ? "4 / span 3" : "1 / span 3")};
    margin: 2.5rem 0;
  `}

  ${media.between("mobileL", "tabletM")`
    grid-column: 2 / span 2;
    margin-right: 0.5rem;
    order: 2;
`}

  ${media.down("mobileL")`
    grid-column: 2 / span 2;
    margin-right: 0.5rem;
    order: 2;
`}
`;

const PhaseContent = styled.div`
  border-radius: 8px;
  padding: 1rem;
  transition: all 0.3s ease;
  background-color: ${(props) => props.theme.bodyBgPrimary};

  &:hover {
    background-color: ${(props) => props.theme.bodyBgSecondary};
    transform: translateY(-5px);
  }

  h3 {
    margin-bottom: 1rem;
    color: ${(props) => props.theme.primaryColor};
  }

  ${media.up("desktop")`
    padding: 1.5rem 2rem 1rem 2rem;
  `}

  ${media.between("laptopS", "desktop")`
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  `}

  ${media.between("tabletM", "laptopS")`
    padding: 1rem 1.25rem 0.5rem 1.25rem;
  `}

  ${media.down("tabletM")`
    padding: 1.25rem 1.5rem 0.5rem 1.5rem;
  `}
`;

const PhaseImageItem = styled(ImageBentoItem)`
  grid-row: ${(props) => props.row};
  ${media.up("desktop")`
    grid-column: ${(props) => (props.index % 2 === 0 ? "6 / span 3" : "1 / span 4")};
  `}

  ${media.between("laptopS", "desktop")`
    grid-column: ${(props) => (props.index % 2 === 0 ? "1 / span 3" : "4 / span 3")};
  `}

  ${media.between("tabletM", "laptopS")`
    grid-column: ${(props) => (props.index % 2 === 0 ? "1 / span 3" : "4 / span 3")};
  `}

  ${media.between("mobileL", "tabletM")`
    grid-column: 2 / span 2;
    order: 1;
    max-width: 50vw;
    margin-top: 2.5rem;
    margin-bottom: 1rem;
  `}

  ${media.down("mobileL")`
    grid-column: 2 / span 2;
    order: 1;
    margin: 2rem 0 0.5rem 0;
  `}

  background-color: transparent; // Ensuring the background is transparent
`;

const Phase = ({ phase, index, row, isLastPhase }) => (
  <PhaseWrapper>
    <PhaseContentItem index={index} row={row} disableBackground={true}>
      <h3>{isLastPhase ? "In the end..." : `Phase ${phase.order}`}</h3>
      <PhaseContent>
        <Markdown>{phase.description}</Markdown>
      </PhaseContent>
    </PhaseContentItem>
    {phase.img && (
      <PhaseImageItem index={index} row={row}>
        <StyledImage
          src={phase.img}
          alt={isLastPhase ? "Final Phase" : `Phase ${phase.order}`}
        />
      </PhaseImageItem>
    )}
  </PhaseWrapper>
);

const Phases = ({ phases, startRow }) => {
  if (!phases || phases.length === 0) return null;

  const sortedPhases = [...phases].sort((a, b) => a.order - b.order);

  return (
    <>
      {sortedPhases.map((phase, index) => (
        <Phase
          key={phase.id}
          phase={phase}
          index={index}
          row={startRow + index}
          isLastPhase={index === sortedPhases.length - 1}
        />
      ))}
    </>
  );
};

export default Phases;
