import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { useViewport } from "../../../context/ViewportContext";
import useStore from "../../../stores/store";
import {
  Wrapper,
  Content,
  ContentWrapper,
  ScrollContent,
  StyledImage,
} from "../../../components/BentoBox/StyledComponents";

import {
  BentoBox,
  BentoItem,
  ImageBentoItem,
} from "../../../components/BentoBox/BentoBox";
import { breakpoints, media } from "../../../styles/breakpoints";
import Scrollbar from "../../../components/Scrollbar/Scrollbar";
import Hero from "../../../components/BentoBox/Hero";
import { preloadBackgroundImages, preloadImages } from "../utils";
import styled from "styled-components";
import Disclaimer from "./Disclaimer/Disclaimer";
import Section from "../../../components/_shared/Section";

const Title = styled.h1`
  && {
    font-size: 12rem;
    line-height: 9rem;
    text-align: right;

    ${media.between("laptopL", "desktop")`
      font-size: calc(7.25 * (1vh + 1vw));
      line-height: calc(5.25 * (1vh + 1vw));
    `}

    ${media.between("laptopS", "laptopL")`
      font-size: calc(6 * (1vh + 1vw));
      line-height: calc(4.75 * (1vh + 1vw));
    `}

    ${media.between("tabletM", "laptopS")`
      font-size: calc(5 * (1vh + 1vw));
      line-height: calc(4 * (1vh + 1vw));
    `}

    ${media.between("tabletS", "tabletM")`
      font-size: calc(8.25 * (1vh + 1vw));
      line-height: calc(5.75 * (1vh + 1vw));
    `}


    ${media.down("tabletS")`
      font-size: calc(4.25 * (1vh + 1vw));
      line-height: calc(3.75 * (1vh + 1vw));
      margin-bottom: 0;
    `}
  }
`;

const SixUp = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  width: 100%;
  margin-top: 1rem;
  grid-column: 1/-1;
  grid-row: auto;

  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
  `}

  ${media.between("tabletM", "laptopL")`
    grid-column: 1 / -1;
  `}

  ${media.between("mobileL", "tabletM")`
    grid-template-columns: repeat(2, 1fr);
    grid-column: 2/ span 2;
    margin-top: 0;
  `}

  ${media.down("mobileL")`
    grid-template-columns: repeat(1, 1fr);
    grid-column: 2/ span 2;
    margin-top: 0;
  `}
`;

const SpanThree = styled(BentoItem)`
  height: 100%;

  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
  `}

  ${media.between("tabletL", "laptopL")`
  `}

  ${media.between("tabletS", "tabletL")`
    margin-right: 0.5rem;
  `}

  ${media.down("tabletS")`
    margin-right: 0.5rem;
  `}
`;

const FourUp = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  grid-column: 1/-1;
`;

const FourUpAndMain = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  grid-column: 1/-1;

  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
  `}

  ${media.between("tabletM", "laptopL")`
    grid-column: 1 / -1;
  `}

  ${media.between("mobileL", "tabletM")`
    grid-template-columns: repeat(2, 1fr);
    grid-column: 2/-1;
    margin-top: 0;
  `}



  ${media.down("mobileL")`
    grid-template-columns: 1fr;
    grid-column: 2 / -1;
  `}
`;

const FourUpAndMainGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  width: 100%;
  grid-column: 1;
  grid-row: auto;

  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
  `}

  ${media.between("tabletL", "laptopL")`
  `}

  ${media.between("tabletS", "tabletL")`
  `}

  ${media.down("tabletS")`
    grid-template-columns: 1fr;
  `}
`;

const FourUpAndMainImage = styled(BentoItem)`
  height: 100%;
  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
  `}

  ${media.between("tabletL", "laptopL")`
  `}

  ${media.between("tabletS", "tabletL")`
  `}

  ${media.down("tabletS")`
    margin-right: 0.5rem;
  `}
`;

const FullWidthItem = styled(BentoItem)`
  grid-column: 1/-1;
  height: 100%;

  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
  `}

  ${media.between("tabletL", "laptopL")`
  `}

  ${media.between("tabletS", "tabletL")`
  `}

  ${media.down("tabletS")`
    grid-column: 2/-1;
    margin-right: 0.5rem;
  `}
`;

const Fitness = () => {
  const [isLoading, setIsLoading] = useState(true);
  const viewport = useViewport();
  const { width, height } = viewport;
  const [orientationKey, setOrientationKey] = useState(0);
  const [shouldShowScrollbar, setShouldShowScrollbar] = useState(false);
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const [isLandscape, setIsLandscape] = useState(viewport.isLandscape);
  const { theme } = useStore();

  // Use the image slice from the store
  const { images, fetchImages } = useStore();

  /**
   * Memoized content key for Scrollbar component
   * @type {string}
   */
  const contentKey = useMemo(() => {
    return `portrait-${viewport.width}-${viewport.height}-${isLandscape}`;
  }, [viewport.width, viewport.height, isLandscape]);

  /**
   * Updates dimensions and scrollbar visibility
   */
  const updateDimensions = useCallback(() => {
    if (containerRef.current && contentRef.current) {
      const containerHeight = containerRef.current.clientHeight;
      const contentHeight = contentRef.current.scrollHeight;
      setShouldShowScrollbar(contentHeight > containerHeight);
    }
  }, []);

  /**
   * Effect for initial image loading and preloading
   */
  useEffect(() => {
    console.log("Fitness component mounted");
    const loadImagesAndPreload = async () => {
      try {
        await fetchImages("photography", "fitness");
        await Promise.all([
          preloadImages(".grid__img"),
          preloadBackgroundImages(".grid__img"),
        ]);
        console.log("Images preloaded");
        setIsLoading(false);
        window.scrollTo(0, 0);
        updateDimensions();
      } catch (error) {
        console.error("Error loading images:", error);
        setIsLoading(false);
      }
    };

    loadImagesAndPreload();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [fetchImages, updateDimensions]);

  /**
   * Effect for handling orientation changes
   */
  useEffect(() => {
    const handleOrientationChange = () => {
      setOrientationKey((prev) => prev + 1);
      setIsLandscape(window.matchMedia("(orientation: landscape)").matches);
    };

    window.addEventListener("orientationchange", handleOrientationChange);
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  /**
   * Effect for updating dimensions on viewport or orientation changes
   */
  useEffect(() => {
    updateDimensions();
  }, [viewport, isLandscape, updateDimensions]);

  const leftMargin = useMemo(() => {
    if (!viewport) return "5.25rem";
    const { width } = viewport;

    if (width < breakpoints.mobileL) return "3rem";
    if (width < breakpoints.tabletL) return "3.5rem";
    if (width < breakpoints.laptopS) return "5.25rem";
    if (width < breakpoints.desktop) return "5.25rem";
    return "5.25rem";
  }, [viewport]);

  // Use the fetched images
  const gridImages = images.map((image) => image.file);

  return (
    <Wrapper theme={theme}>
      <Content>
        <Scrollbar
          scrollbarPosition="left"
          theme={theme}
          applyScrolling={true}
          forceScrollbar={true}
          trackMargin={{
            top: "0",
            bottom: "0",
            left: leftMargin,
            right: "0",
          }}
        >
          <ScrollContent>
            <ContentWrapper>
              <BentoBox>
                <Hero image={gridImages[0]}>
                  <Title>Bodies of Work</Title>
                </Hero>
                <Disclaimer image={gridImages[1]}>
                  <p>Before you continue… </p>
                  <p>
                    As with the editorial portrait gallery, this body of work
                    suffered catastrophic data loss affecting hundreds of
                    thousands of images spanning this period of my career.
                  </p>
                  <p>As of today, these are the survivors.</p>
                  <p>
                    Fitness photography was a massive part of my life for over a
                    decade, and it’s a bit devastating to have lost so much of
                    that work — but I’ll still tell you the story, soon…
                  </p>
                  <p>
                    For now, I’m adding images to this gallery as I recover
                    them, which is quite a long process.
                  </p>
                </Disclaimer>
                <SixUp>
                  {gridImages.slice(2, 8).map((image, index) => (
                    <SpanThree key={index}>
                      <StyledImage src={image} />
                    </SpanThree>
                  ))}
                </SixUp>
                <FullWidthItem>
                  <StyledImage src={gridImages[9]} />
                </FullWidthItem>
                <FourUpAndMain>
                  <FourUpAndMainGrid>
                    {gridImages.slice(10, 14).map((image, index) => (
                      <SpanThree key={index}>
                        <StyledImage src={image} />
                      </SpanThree>
                    ))}
                  </FourUpAndMainGrid>

                  <FourUpAndMainImage>
                    <StyledImage src={gridImages[24]} />
                  </FourUpAndMainImage>
                </FourUpAndMain>
                <FullWidthItem>
                  <StyledImage src={gridImages[14]} />
                </FullWidthItem>
                <SixUp>
                  {gridImages.slice(18, 24).map((image, index) => (
                    <SpanThree key={index}>
                      <StyledImage src={image} />
                    </SpanThree>
                  ))}
                </SixUp>
                <FullWidthItem>
                  <StyledImage src={gridImages[15]} />
                </FullWidthItem>
              </BentoBox>
            </ContentWrapper>
          </ScrollContent>
        </Scrollbar>
      </Content>
    </Wrapper>
  );
};

const ErrorMessage = ({ error, slug }) => (
  <div className="error-container">
    <h2>Error Loading Gallery</h2>
    <p>{error}</p>
    <p>Slug: {slug}</p>
  </div>
);

export default Fitness;
