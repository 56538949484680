// components/CaseStudy/Credits/Credits.jsx

import React from "react";
import styled from "styled-components";
import { BentoItem } from "../../BentoBox/BentoBox";
import { media } from "../../../styles/breakpoints";

const CreditsItem = styled(BentoItem)`
  grid-column: 1 / -1;
  grid-row: auto;

  ${media.up("desktop")`
    grid-column: 8 / span 2;
    grid-row: 2;
  `}

  ${media.between("tabletM", "laptopS")`
    grid-column: span 2;
    grid-row:auto;
  `}
  
  ${media.down("tabletM")`
    grid-column: 2 /span 1;
  `}
`;

const CreditsContent = styled(BentoItem)`
  border-radius: 10px;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.75);
    transform: translateY(-5px);
  }

  ${media.up("desktop")`
    padding: 0.5rem 1.5rem 1.25rem 1.5rem;

  `}

  ${media.between("tabletM", "laptopS")`
    padding: 0.5rem 0.5rem 1.25rem 0.5rem;
  `}
  
  ${media.down("tabletM")`
    padding: 0.5rem 1.5rem 1.25rem 1.5rem;
  `}
`;

const CreditsList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const CreditEntry = styled.li`
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 0.5rem;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    transform: translateX(5px);
  }
`;

const CreditRole = styled.span`
  font-size: 0.75rem;
  color: ${(props) => props.theme.secondaryColor || "#888"};
  text-transform: uppercase;
  letter-spacing: 0.5px;
`;

const CreditName = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: ${(props) => props.theme.primaryColor || "#fff"};
`;

const Credits = ({ credits }) => {
  if (!credits || credits.length === 0) return null;

  return (
    <CreditsItem disableBackground={true}>
      <h2>Credits</h2>
      <CreditsContent>
        <CreditsList>
          {credits.map((credit) => (
            <CreditEntry key={credit.id}>
              <CreditRole>{credit.role}</CreditRole>
              <CreditName>{credit.name}</CreditName>
            </CreditEntry>
          ))}
        </CreditsList>
      </CreditsContent>
    </CreditsItem>
  );
};

export default Credits;
