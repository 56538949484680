import styled, { css } from "styled-components";
import { typography } from "../../styles/abstracts/typography";
import { hexToRgba } from "../../utils/hexToRGBA";
import { media } from "../../styles/breakpoints";

const baseWrapperStyles = css`
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-weight: 450;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  pointer-events: auto;
  grid-column: 1/-1;
  grid-row: 1/-1;
  display: grid;
  font-family: ${typography.fontBody};
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  background-color: ${(props) =>
    props.disableBackground
      ? "transparent"
      : hexToRgba(props.theme.bodyBgPrimary, 0.5)};
`;

export const Wrapper = styled.div`
  ${baseWrapperStyles}
  grid-template-columns: 5.25rem 1fr;
  grid-template-rows: 5.25rem 1fr 5.25rem;

  ${media.down("tabletM")`
    grid-template-columns: 3rem 1fr;
    grid-template-rows: 3rem 1fr 5.25rem;
  `}

  ${media.between("tabletM", "desktop")`
    grid-template-columns: 3.5rem 1fr;
    grid-template-rows: 3rem 1fr 5.25rem;
  `}
`;

export const Content = styled.div`
  grid-column: 1/-1;
  grid-row: 1/-1;
  height: 100%;
  overflow: hidden;
`;

export const ContentWrapper = styled.div`
  padding: 0 0 10rem 0;
  width: 100%;

  ${media.up("desktop")`
    padding: 0 0 10rem 0;
  `}

  ${media.between("laptopS", "desktop")`
    padding: 0 0.5rem 10rem 2.5rem;
  `}

  ${media.between("tabletM", "laptopS")`
    padding: 1rem 0.25rem 5rem 0.25rem;
  `}

  ${media.down("tabletM")`
    padding: 0 0 5rem 0;
  `}
`;

export const ScrollContent = styled.div`
  height: 100%;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

export const ResponsiveText = styled.p`
  font-size: ${(props) => props.fontSize || "1rem"};
  line-height: 1.5;

  ${media.down("tabletM")`
    font-size: ${(props) => props.mobileFontSize || "0.9rem"};
  `}
`;

export const ResponsiveHeading = styled.h2`
  font-size: ${(props) => props.fontSize || "2rem"};
  font-weight: bold;
  margin-bottom: 1rem;

  ${media.down("tabletM")`
    font-size: ${(props) => props.mobileFontSize || "1.5rem"};
    margin-bottom: 0.75rem;
  `}
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  justify-content: ${(props) => props.justify || "flex-start"};
  align-items: ${(props) => props.align || "stretch"};
  flex-wrap: ${(props) => props.wrap || "nowrap"};
  gap: ${(props) => props.gap || "0"};

  ${media.down("tabletM")`
    flex-direction: ${(props) => props.mobileDirection || props.direction || "column"};
  `}
`;
