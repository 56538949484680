/**
 * @file createExperienceSlice.js
 * @description This file defines a slice for managing experience-related state in a Zustand store.
 * It provides actions for fetching, setting, and refetching experiences using React Query.
 */

import { queryClient } from "../store";
import * as ExperienceService from "../../services/ExperienceService";

/**
 * Creates an experience slice for the Zustand store
 *
 * @param {Function} set - Function to update the store's state
 * @param {Function} get - Function to retrieve the current state
 * @returns {Object} An object containing the experience state and actions
 */
const createExperienceSlice = (set, get) => ({
  /**
   * Array of experience objects
   * @type {Array<Object>}
   */
  experiences: [],

  /**
   * Sets the experiences in the store
   * @param {Array<Object>} experiences - The experiences to set
   */
  setExperiences: (experiences) => set({ experiences }),

  /**
   * Fetches experiences from the API and updates the store
   * @returns {Promise<Array<Object>>} A promise that resolves to the fetched experiences
   */
  fetchExperiences: async () => {
    const experiences = await queryClient.fetchQuery(
      "experiences",
      ExperienceService.fetchExperiences
    );
    set({ experiences });
    return experiences;
  },

  /**
   * Invalidates the experiences query, forcing a refetch on the next request
   */
  refetchExperiences: () => queryClient.invalidateQueries("experiences"),
});

export default createExperienceSlice;

// Usage example:
// import useStore from '../store';
//
// function ExperienceList() {
//   const { experiences, fetchExperiences } = useStore();
//
//   useEffect(() => {
//     fetchExperiences();
//   }, []);
//
//   return (
//     <ul>
//       {experiences.map(experience => (
//         <li key={experience.id}>{experience.title}</li>
//       ))}
//     </ul>
//   );
// }
