import React from "react";
import { BentoItem } from "../../BentoBox/BentoBox";
import styled from "styled-components";
import { media } from "../../../styles/breakpoints";
import { typography } from "../../../styles/abstracts/typography";
import Markdown from "react-markdown";

const TestimonialItem = styled(BentoItem)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-row: ${(props) => props.row};

  ${media.up("desktop")`
    grid-column: ${(props) => props.column};
  `}

  ${media.between("laptopS", "desktop")`
    grid-column: ${(props) => props.column};
    grid-row: span 1;
  `}

  ${media.between("tabletM", "laptopS")`
    grid-column: 2/-2;
    grid-row: span 1;
    margin: 2.5rem 0;
  `}

  ${media.between("mobileL", "tabletM")`
    grid-column: 2 / span 2;
    grid-row: span 1;
    margin: 1.5rem 0;
    margin-right: 0.5rem;
  `}

  ${media.down("mobileL")`
    grid-column: 2 / span 2;
    grid-row: span 1;
    margin: 1.5rem 0;
    margin-right: 0.5rem;
  `}
`;

const TestimonialContent = styled(BentoItem)`
  border-radius: 10px;
  padding: 0.25rem 2rem 0.5rem 1.75rem;
  margin-left: 2rem;
  transition: all 0.3s ease;
  font-size: ${typography.fontSizeXs};
  line-height: 2rem;
  font-style: italic;
  z-index: 0;

  &:hover {
    background-color: rgba(255, 255, 255, 0.75);
    transform: translateY(-5px);
  }

  ${media.up("desktop")`
    font-size: ${typography.fontSizeXs};
    line-height: 2rem;
    margin-left: 1.5rem;
  `}

  ${media.between("laptopS", "desktop")`
    font-size: ${typography.fontSizeXs};
    line-height: 2rem;
  `}

  ${media.between("tabletM", "laptopS")`
    font-size: ${typography.fontSizeXxs};
    line-height: 1rem;
    padding: 0.25rem 0.25rem 0.5rem 0.75rem;
    margin-left: 0rem;
  `}

  ${media.down("tabletM")`
    font-size: 0.75rem;
    line-height: 1.125rem;
    padding: 0 0 0 1rem;
    margin: 0;
  `}
`;

const TestimonialAuthorContainer = styled(BentoItem)`
  margin-top: -1.8rem;
  align-items: flex-end;
  transition: all 0.3s ease;
  display: flex;
  gap: 0;
  z-index: 10;

  ${media.up("desktop")`
    margin-top: -1.8rem;
  `}

  ${media.between("laptopS", "desktop")`
    margin-top: -1.8rem;
  `}

  ${media.between("tabletM", "laptopS")`
    margin-top: -1rem;

  `}

  ${media.down("tabletM")`
    margin-top: -0.125rem;
  `}
`;

const TestimonialAuthorDetails = styled(BentoItem)`
  border-radius: 10px;
  padding: 0.75rem 1.25rem;
  transition: all 0.3s ease;
  margin-left: 1rem;
  margin-bottom: 1rem;
  z-index: 10;

  p {
    margin-bottom: 0;
    line-height: 1.25rem;
  }

  &:hover {
    transform: translateY(-5px);
  }

  ${media.up("desktop")`
    margin-bottom: 0;
    
    p {
      font-size: 1.25rem;
      line-height: 1rem;
      padding: 0;
      margin: 0;

      &:last-child {
        font-size: 0.75rem;
        font-style: italic;
      }
    }
  `}

  ${media.between("laptopS", "desktop")`
    padding: 0.5rem 0.75rem 0.5rem 0.5rem;
    margin-left: 0.5rem;
    margin-bottom: 0;

    p {
      font-size: 0.75rem;
      line-height: 0.75rem;
      padding: 0;
      margin: 0;

      &:last-child {
        font-size: 0.5rem;
        font-style: italic;
      }
    }

  `}

  ${media.between("tabletM", "laptopS")`
    padding: 0.5rem 0.75rem 0.5rem 0.5rem;
    margin-left: 0.5rem;
    margin-bottom: 0;

    p {
      font-size: 0.75rem;
      line-height: 0.75rem;
      padding: 0;
      margin: 0;

      &:last-child {
        font-size: 0.5rem;
        font-style: italic;
      }
    }

  `}

  ${media.down("tabletM")`
    padding: 0.5rem 0.75rem 0.5rem 0.5rem;
    margin-left: 0.5rem;
    margin-bottom: 0;

    p {
      font-size: 0.75rem;
      line-height: 0.75rem;
      padding: 0;
      margin: 0;

      &:last-child {
        font-size: 0.5rem;
        font-style: italic;
      }
    }
  `}
`;

const TestimonialAuthorAvatar = styled.img`
  width: 125px;
  height: 125px;
  border-radius: 50%;
  object-fit: cover;
  z-index: 0;

  ${media.up("desktop")`
    width: 100px;
    height: 100px;
  `}

  ${media.between("laptopS", "desktop")`
    width: 50px;
    height: 50px;
  `}

  ${media.between("tabletM", "laptopS")`
    width: 65px;
    height: 65px;
  `}

  ${media.down("tabletM")`
    width: 50px;
    height: 50px;
  `}
`;

const Testimonials = ({ testimonial, row, column }) => {
  if (!testimonial) return null;

  const testimonials = Array.isArray(testimonial) ? testimonial : [testimonial];

  return testimonials.map((item) => (
    <TestimonialItem
      disableBackground={true}
      key={item.id}
      row={row || `span 1`}
      column={column || `span 3`}
    >
      <TestimonialContent>
        <blockquote>"{item.quote}"</blockquote>
      </TestimonialContent>
      <TestimonialAuthorContainer disableBackground={true}>
        <TestimonialAuthorAvatar
          src={item.avatar}
          alt={`${item.author_name}'s avatar`}
        />
        <TestimonialAuthorDetails>
          <p>
            <strong>{item.author_name}</strong>
          </p>
          <p>
            <span>
              <Markdown>{`${item.role} at ${item.company}`}</Markdown>
            </span>
          </p>
        </TestimonialAuthorDetails>
      </TestimonialAuthorContainer>
    </TestimonialItem>
  ));
};

export default Testimonials;
