// components/About/About.jsx

import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import SlideOutMenu from "../SlideOut/SlideOutMenu";
import { hexToRgba } from "../../utils/hexToRGBA";
import Bio from "./Bio";
import Skills from "../Skills/Skills";
import Pagination from "../Project/Pagination";
import { fetchSkills } from "../../services/SkillService";
import Technologies from "../Technologies/Technologies";
import { fetchTechnologies } from "../../services/TechnologyService";
import Facts from "../Facts/Facts";
import { fetchFacts } from "../../services/FactService";
import WIP from "../WIP/WIP";
import { fetchProjectsByStatus } from "../../services/ProjectService";
import { breakpoints, heightBreakpoints } from "../../styles/breakpoints";
import { useViewport } from "../../context/ViewportContext";
import itemConfigs from "../../constants/itemConfigs";
import Loader from "../Loader/Loader";
import useStore from "../../stores/store";
import { Container, ContentArea, GlassOverlay } from "../_shared/Layout";

function About() {
  const navigate = useNavigate();
  const location = useLocation();
  const viewport = useViewport();
  const { tab } = useParams();
  const {
    theme,
    isCollapsed,
    isPresent,
    shouldCollapseMenu,
    toggleCollapse,
    setShouldCollapseMenu,
  } = useStore();
  const [skills, setSkills] = useState([]);
  const [technologies, setTechnologies] = useState([]);
  const [facts, setFacts] = useState([]);
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isDataReady, setIsDataReady] = useState(false);

  const [isMenuCollapsed, setIsMenuCollapsed] = useState(true);

  const aboutTabs = useMemo(
    () => [
      {
        id: "bio",
        title: "About",
        logo: {
          logo_id: "about",
          sprite_sheet: { filename: "sprite-nav" },
        },
      },
      {
        id: "skills",
        title: "Skills",
        logo: {
          logo_id: "skills",
          sprite_sheet: { filename: "sprite-nav" },
        },
      },
      {
        id: "technologies",
        title: "Technologies",
        logo: {
          logo_id: "technologies",
          sprite_sheet: { filename: "sprite-nav" },
        },
      },
      {
        id: "facts",
        title: "Random Facts",
        logo: {
          logo_id: "facts",
          sprite_sheet: { filename: "sprite-nav" },
        },
      },
    ],
    []
  );

  const [selectedTab, setSelectedTab] = useState(() => tab || "bio");

  useEffect(() => {
    const currentTab = tab || "bio";
    setSelectedTab(currentTab);
  }, [tab]);

  const itemsPerPage = useMemo(() => {
    const { width, height, isLandscape } = viewport;

    // Determine width category
    const widthCategory =
      Object.entries(breakpoints)
        .sort(([, a], [, b]) => a - b)
        .find(([, value]) => width <= value)?.[1] || breakpoints.desktopL;

    // Determine height category
    const heightCategory =
      Object.entries(heightBreakpoints)
        .sort(([, a], [, b]) => a - b)
        .find(([, value]) => height <= value)?.[1] ||
      heightBreakpoints.heightXL;

    const orientation = isLandscape ? "landscape" : "portrait";

    // Get the configuration for the current viewport
    const config = itemConfigs[widthCategory]?.[heightCategory]?.[
      orientation
    ] || {
      Highlights: 6,
      Skills: 6,
      Technologies: 6,
      Testimonials: 2,
    };

    // Map the config to the About component's tabs
    return {
      bio: 1, // Bio is always 1
      facts: config.Highlights || 6,
      skills: config.Skills || 6,
      technologies: config.Technologies || 6,
      wip: config.Testimonials || 3, // Using Testimonials for WIP as it's similar in nature
    };
  }, [viewport]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedTab]);

  useEffect(() => {
    if (aboutTabs.some((t) => t.id === tab)) {
      setSelectedTab(tab);
    } else {
      navigate("/about/bio", { replace: true });
    }
  }, [tab, navigate, aboutTabs]);

  useEffect(() => {
    setIsLoading(true);
    setIsDataReady(false);

    const fetchData = async () => {
      try {
        const [skillsData, technologiesData, factsData, projectsData] =
          await Promise.all([
            fetchSkills(),
            fetchTechnologies(),
            fetchFacts(),
            fetchProjectsByStatus("current"),
          ]);
        setSkills(skillsData);
        setTechnologies(technologiesData);
        setFacts(factsData);
        setProjects(projectsData);
        setIsLoading(false);
        setIsDataReady(true);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to load data. Please try again later.");
        setIsLoading(false);
        setIsDataReady(true);
      }
    };

    fetchData();
  }, []);

  const checkShouldCollapseMenu = useCallback(() => {
    const { width, height, isLandscape } = viewport;

    // Define breakpoints where menu should be collapsed
    const collapseBreakpoints = {
      portrait: [
        breakpoints.mobileS,
        breakpoints.mobileM,
        breakpoints.mobileL,
        breakpoints.tabletS,
        breakpoints.tabletM,
        breakpoints.tabletL,
      ],
      landscape: [
        breakpoints.mobileS,
        breakpoints.mobileM,
        breakpoints.mobileL,
        breakpoints.tabletS,
        breakpoints.tabletM,
      ],
    };

    const currentBreakpoints = isLandscape
      ? collapseBreakpoints.landscape
      : collapseBreakpoints.portrait;
    return currentBreakpoints.some((breakpoint) => width <= breakpoint);
  }, [viewport]);

  useEffect(() => {
    const shouldCollapse = checkShouldCollapseMenu();
    setShouldCollapseMenu(shouldCollapse);
  }, [checkShouldCollapseMenu, setShouldCollapseMenu]);

  const handleMenuCollapse = useCallback(() => {
    toggleCollapse();
  }, [toggleCollapse]);

  useEffect(() => {
    // Update selectedTab when the URL changes
    const currentTab = tab || "bio";
    setSelectedTab(currentTab);
  }, [tab]);

  const handleTabChange = useCallback(
    (newTab) => {
      setSelectedTab(newTab);
      navigate(`/about/${newTab}`);

      // Collapse menu when changing to bio tab, unless in landscape
      if (
        newTab === "bio" &&
        !viewport.isLandscape &&
        viewport.width > breakpoints.tabletS
      ) {
        setIsMenuCollapsed(true);
      }
    },
    [navigate, viewport]
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderSelectedComponent = () => {
    if (!isDataReady) return null;

    const componentKey = `${selectedTab}-${currentPage}`;
    const commonProps = {
      key: componentKey,
      isLoading,
      error,
      itemsPerPage: itemsPerPage[selectedTab] || 0,
    };

    const getCurrentItems = (items) => {
      const start = (currentPage - 1) * itemsPerPage[selectedTab];
      const end = start + itemsPerPage[selectedTab];
      return items.slice(start, end);
    };

    switch (selectedTab) {
      case "bio":
        return <Bio {...commonProps} viewport={viewport} theme={theme} />;
      case "skills":
        return <Skills {...commonProps} data={getCurrentItems(skills)} />;
      case "technologies":
        return (
          <Technologies {...commonProps} data={getCurrentItems(technologies)} />
        );
      case "facts":
        return <Facts {...commonProps} data={getCurrentItems(facts)} />;
      case "wip":
        return <WIP {...commonProps} projects={getCurrentItems(projects)} />;
      default:
        return <Bio {...commonProps} />;
    }
  };

  return (
    <Container $viewport={viewport}>
      <SlideOutMenu
        items={aboutTabs}
        isLoading={isLoading}
        isCollapsed={isCollapsed}
        onCollapse={handleMenuCollapse}
        selectedItem={selectedTab}
        onItemSelect={handleTabChange}
        useRouterLinks={true}
        basePath="/about"
        theme={theme}
        viewport={viewport}
      />
      <GlassOverlay theme={theme} $viewport={viewport} className="glass">
        <ContentArea $viewport={viewport} className="content-area">
          {renderSelectedComponent()}
          {selectedTab !== "bio" && !isLoading && !error && isDataReady && (
            <Pagination
              currentPage={currentPage}
              totalItems={
                selectedTab === "skills"
                  ? skills?.length || 0
                  : selectedTab === "technologies"
                    ? technologies?.length || 0
                    : selectedTab === "facts"
                      ? facts?.length || 0
                      : projects?.length || 0
              }
              itemsPerPage={itemsPerPage[selectedTab] || 0}
              onPageChange={handlePageChange}
              viewport={viewport}
            />
          )}
          <Loader isLoading={isLoading} text="Loading content..." />
        </ContentArea>
      </GlassOverlay>
    </Container>
  );
}

export default About;
