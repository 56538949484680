/**
 * hexToRgba.js
 *
 *
 * Converts a hexadecimal color code to RGBA format with a specified alpha value.
 *
 * @param {string} hex - The hexadecimal color code to convert (with or without '#')
 * @param {number} alpha - The alpha value for the resulting RGBA color (0 to 1)
 * @returns {string|null} The RGBA color string or null if conversion fails
 * @throws {TypeError} If hex is not a string or alpha is not a number
 */

export function hexToRgba(hex, alpha) {
  // Check for valid input types
  if (typeof hex !== "string" || typeof alpha !== "number") {
    throw new TypeError(
      "Invalid input types: hex must be a string and alpha must be a number"
    );
  }

  // Return null if hex is falsy (e.g., empty string, null, undefined)
  if (!hex) {
    return null;
  }

  // Convert shorthand hex format to full format
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  // Parse the hex color code
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result) {
    // Convert hex values to decimal
    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);

    // Return the RGBA string
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  // Return null if parsing fails
  return null;
}

// Usage example:
//
// import { hexToRgba } from './colorUtils.js';
//
// const hexColor = '#3498db';
// const alpha = 0.7;
// const rgbaColor = hexToRgba(hexColor, alpha);
//
// // The function also handles shorthand hex codes:
// const shortHex = '#f00';
// const redWithAlpha = hexToRgba(shortHex, 0.5);
