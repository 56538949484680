import React from "react";
import styled from "styled-components";
import { StyledImage } from "./StyledComponents";
import { BentoItem, ImageBentoItem } from "./BentoBox";
import { media } from "../../styles/breakpoints";

const HeroWrapper = styled.div`
  grid-column: 1 / -1;
  grid-row: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  height: 90vh;
  margin-top: 0;

  ${media.up("desktop")`
    grid-column: 1 / -1;
    align-content: center;
  `}

  ${media.between("laptopS", "desktop")`
    margin-top: -12.5vh;
    margin-bottom: 3.5rem;
  `}

  ${media.between("tabletM", "laptopS")`
    grid-column: 1 / -1;
    align-content: baseline;
    height: auto;
  `}

 
  ${media.between("mobileL", "tabletM")`
    height: fit-content;
    margin-top: -0.5vh;
    margin-bottom: 1.75rem;
    grid-template-columns: 1fr;
    grid-column: 2 / span 2;
    gap: 0;
  `}

  ${media.down("mobileL")`
    height: fit-content;
    margin-top: -0.5vh;
    margin-bottom: 3.5rem;
    grid-template-columns: 1fr;
    grid-column: 2 / span 2;
    gap: 0;
  `}
`;

const HeroContentItem = styled(BentoItem)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 2rem;
  margin-bottom: -2.25rem;
  order: 2;

  ${media.up("desktop")`
    order: 0;
    padding: 2rem;
    justify-content: flex-end;

  `}

  ${media.between("tabletL", "desktop")`
    justify-content: flex-end;
    padding: 0 0 2rem 0;

  `}

  ${media.between("tabletS", "tabletL")`
    justify-content: flex-end;
    padding: 0 0 2rem 0;
    order: 2;

  `}

  ${media.between("mobileL", "tabletS")`
    order: 2;
    justify-content: flex-start;
    padding: 0.5rem 0 2rem 0;

  `}

  ${media.down("mobileL")`
    order: 2;
    padding: 1rem 0.5rem 2rem 0 ;

  `}
`;

const HeroImageItem = styled(ImageBentoItem)`
  overflow: hidden;
  height: 100%;
  max-height: 75vh;
  min-height: 60vh;
  order: 2;

  ${media.between("laptopS", "desktop")`
    max-height: 100%;
    min-height: 80vh;
  `}

  ${media.down("tabletM")`
    order: 1;
  `}
`;

const StyledHeroImage = styled(StyledImage)`
  &:hover {
    transform: scale(1) translateY(0);
  }
`;

const Hero = ({ children, image }) => (
  <HeroWrapper>
    <HeroContentItem disableBackground={true}>{children}</HeroContentItem>
    <HeroImageItem>
      <StyledHeroImage src={image} alt={image} />
    </HeroImageItem>
  </HeroWrapper>
);

export default Hero;
