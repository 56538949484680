// components/CaseStudy/Approach/Approach.jsx

import React from "react";
import styled from "styled-components";
import Markdown from "react-markdown";
import { BentoItem } from "../../BentoBox/BentoBox";
import { media } from "../../../styles/breakpoints";

export const ApproachItem = styled(BentoItem)`
  grid-column: 1 / -1;
  grid-row: auto;
  height: fit-content;

  ${media.up("desktop")`
    grid-column: 3 / span 5;
  `}

  ${media.between("laptopS", "desktop")`
    grid-column: 1 / -1;
  `}

  ${media.between("tabletM", "laptopS")`
    grid-column: 1/-1;
    margin: 2.5rem 0;
  `}

  ${media.between("mobileL", "tabletM")`
    grid-column: 2 / span 2;
    margin: 2.5rem 0.5rem 2.5rem 0;
  `}

  ${media.down("mobileL")`
    grid-column: 2 / span 2;
    margin: 2.5rem 0.5rem 2.5rem 0;
  `}

  h2 {
    position: relative;
    z-index: 10;
  }
`;

const ApproachContent = styled(BentoItem)`
  border-radius: 10px;
  padding: 0.5rem 2rem 0.75rem 2rem;
  transition: all 0.3s ease;
  position: relative;

  &:hover {
    background-color: rgba(255, 255, 255, 0.75);
    transform: translateY(-5px);
  }

  ${media.up("desktop")`
    padding: 1.5rem 2rem 1rem 2rem;
  `}

  ${media.between("laptopS", "desktop")`
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  `}

  ${media.between("tabletM", "laptopS")`
    padding: 1rem 1.25rem 0.5rem 1.25rem;
  `}

  ${media.between("mobileL", "tabletM")`
    padding: 1rem 1.5rem 0.5rem 1.5rem;
  `}

  ${media.down("mobileL")`
    padding: 1.25rem 1.5rem 0.5rem 1.5rem;
  `}
`;

const Approach = ({ approach }) => {
  if (!approach || approach.length === 0) return null;

  return (
    <ApproachItem disableBackground={true}>
      <h2>My Approach</h2>
      <ApproachContent>
        <Markdown>{approach}</Markdown>
      </ApproachContent>
    </ApproachItem>
  );
};

export default Approach;
