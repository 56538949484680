// Components/Menu/Nav/data.js

export const links = [
  {
    title: "Home",
    href: "/",
  },
  {
    title: "About",
    href: "/about",
  },
  {
    title: "Projects",
    href: "/projects",
  },
  {
    title: "Experience",
    href: "/experience",
  },
  {
    title: "Contact",
    href: "/contact",
  },
];

export const footerLinks = [
  {
    title: "Facebook",
    href: "https://www.facebook.com/davidbickley/",
  },
  {
    title: "LinkedIn",
    href: "https://www.linkedin.com/in/davidbickley/",
  },
  {
    title: "Instagram",
    href: "https://www.instagram.com/thedavidbickley/",
  },
  {
    title: "GitHub",
    href: "https://github.com/davidbickley/",
  },
];
