/**
 * createProjectSlice.js
 *
 * This file manages the project-related state in our application.
 * It provides functionality for fetching, storing, and updating project data.
 */

import { queryClient } from "../store";
import * as ProjectService from "../../services/ProjectService";

/**
 * Creates and returns the project slice for our Zustand store.
 */
const createProjectSlice = (set, get) => ({
  // Stores the list of projects
  projects: [],

  // Updates the projects in the store
  setProjects: (projects) => set({ projects }),

  /**
   * Fetches all projects from the API and updates the store
   */
  fetchProjects: async () => {
    const projects = await queryClient.fetchQuery(
      "projects",
      ProjectService.fetchProjects
    );
    set({ projects });
    return projects;
  },

  // Invalidates the projects query, triggering a refetch on next request
  refetchProjects: () => queryClient.invalidateQueries("projects"),

  /**
   * Fetches projects by status
   */
  fetchProjectsByStatus: async (status) => {
    return queryClient.fetchQuery(["projects", status], () =>
      ProjectService.fetchProjectsByStatus(status)
    );
  },

  /**
   * Fetches projects by category
   */
  fetchProjectsByCategory: async (category) => {
    return queryClient.fetchQuery(["projects", category], () =>
      ProjectService.fetchProjectsByCategory(category)
    );
  },

  /**
   * Fetches featured projects
   */
  fetchFeaturedProjects: async () => {
    return queryClient.fetchQuery(
      "featuredProjects",
      ProjectService.fetchFeaturedProjects
    );
  },
});

export default createProjectSlice;

/**
 * Example usage in a component:
 *
 * import useStore from '../store';
 *
 * function ProjectList() {
 *   const { projects, fetchProjects, fetchProjectsByCategory } = useStore();
 *
 *   useEffect(() => {
 *     fetchProjects();
 *   }, []);
 *
 *   const handleCategoryChange = async (category) => {
 *     const categoryProjects = await fetchProjectsByCategory(category);
 *     console.log(`Projects in ${category}:`, categoryProjects);
 *   };
 *
 *   return (
 *     <div>
 *       <ul>
 *         {projects.map(project => (
 *           <li key={project.id}>{project.title}</li>
 *         ))}
 *       </ul>
 *       <button onClick={() => handleCategoryChange('web')}>
 *         Fetch Web Projects
 *       </button>
 *     </div>
 *   );
 * }
 *
 * This example demonstrates how to fetch all projects and how to fetch projects by category.
 */
