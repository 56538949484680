/**
 * createFactSlice.js
 *
 * This file is all about managing fun facts in the app.
 *
 */

import { queryClient } from "../store";
import * as FactService from "../../services/FactService";

/**
 * This function creates our fact slice.
 */
const createFactSlice = (set, get) => ({
  // This is where we store our facts
  facts: [],

  // This function updates our facts
  setFacts: (facts) => set({ facts }),

  /**
   * This function goes and fetches facts from our server.
   * It's using React Query to help with caching and keeping data fresh.
   */
  fetchFacts: async () => {
    const facts = await queryClient.fetchQuery("facts", FactService.fetchFacts);
    set({ facts });
    return facts;
  },

  // This function tells React Query, "Hey, our facts might be out of date!"
  refetchFacts: () => queryClient.invalidateQueries("facts"),
});

export default createFactSlice;

/**
 * Here's how you could use this in a component:
 *
 * import useStore from '../store';
 *
 * function FactOfTheDay() {
 *   const { facts, fetchFacts } = useStore();
 *
 *   useEffect(() => {
 *     fetchFacts();
 *   }, []);
 *
 *   const randomFact = facts[Math.floor(Math.random() * facts.length)];
 *
 *   return (
 *     <div>
 *       <h2>Fact of the Day:</h2>
 *       <p>{randomFact ? randomFact.content : "Loading..."}</p>
 *     </div>
 *   );
 * }
 *
 * You can easily grab a random fact to display each time the component renders!
 */
