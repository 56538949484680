// stores/slices/createImageSlice.js

import { queryClient } from "../store";
import * as ImageService from "../../services/ImageService";

/**
 * Image Slice
 *
 * This slice manages the state and actions related to images in the application.
 * It integrates with React Query for efficient data fetching and caching.
 *
 * @param {Function} set - Function to update the store's state
 * @param {Function} get - Function to get the current state
 * @returns {Object} The image slice object with state and actions
 */
const createImageSlice = (set, get) => ({
  /**
   * Array of images
   * @type {Array<Object>}
   */
  images: [],

  /**
   * Sets the images in the store
   * @param {Array<Object>} images - The images to set
   */
  setImages: (images) => set({ images }),

  /**
   * Fetches images from the API and updates the store
   * @param {string} category - Category to filter images
   * @param {string} subcategory - Subcategory to filter images
   * @returns {Promise<Array<Object>>} The fetched images
   */
  fetchImages: async (category, subcategory) => {
    const images = await queryClient.fetchQuery(
      ["images", category, subcategory],
      () => ImageService.fetchImages(category, subcategory)
    );
    set({ images });
    return images;
  },

  /**
   * Invalidates the images query, forcing a refetch on the next request
   */
  refetchImages: () => queryClient.invalidateQueries("images"),

  /**
   * Fetches a single image by slug
   * @param {string} slug - The slug of the image to fetch
   * @returns {Promise<Object>} The fetched image
   */
  fetchImageBySlug: async (slug) => {
    return queryClient.fetchQuery(["image", slug], () =>
      ImageService.fetchImageBySlug(slug)
    );
  },
});

export default createImageSlice;

// Usage example:
//
// import useStore from '../store';
//
// function ImageGallery() {
//   const { images, fetchImages } = useStore();
//
//   useEffect(() => {
//     fetchImages('photography', 'portraiture');
//   }, []);
//
//   return (
//     <div>
//       {images.map(image => (
//         <img key={image.id} src={image.file} alt={image.alt_text} />
//       ))}
//     </div>
//   );
// }
