// components/CaseStudy/Outcomes/Outcomes.jsx

import React from "react";
import styled from "styled-components";
import Markdown from "react-markdown";
import { BentoItem } from "../../BentoBox/BentoBox";
import { media } from "../../../styles/breakpoints";

const OutcomesItem = styled(BentoItem)`
  grid-column: 1 / -1;
  grid-row: auto;
  grid-row: 4;

  ${media.up("desktop")`
    grid-column: 6 / span 3;
  `}

  ${media.between("laptopS", "desktop")`
    grid-column: span 3;
  `}

  ${media.between("tabletM", "laptopS")`
    grid-column: span 3;
  `}

  ${media.between("mobileL", "tabletM")`
    grid-row: 5;
    grid-column: 2 / span 2;
    margin-right: 0.5rem;
  `}

  ${media.down("mobileL")`
    grid-row: 5;
    grid-column: 2 / span 2;
    margin-right: 0.5rem;
    margin-top: 2.5rem;
  `}
`;

const OutcomesContent = styled(BentoItem)`
  border-radius: 10px;
  padding: 0.5rem 2rem 0.75rem 2rem;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.75);
    transform: translateY(-5px);
  }

  ${media.up("desktop")`
    padding: 1.5rem 2rem 1rem 2rem;
  `}

  ${media.between("laptopS", "desktop")`
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  `}

  ${media.between("tabletM", "laptopS")`
    padding: 0.25rem 0.75rem 0 0.5rem;
  `}

  ${media.down("tabletM")`
    padding: 0.25rem 0.25rem 0rem 0.25rem;
  `}
`;

const OutcomeList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const OutcomeEntry = styled.div`
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 1rem;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    transform: translateY(-5px);
  }
`;

const Outcomes = ({ outcomes }) => {
  if (!outcomes || outcomes.length === 0) return null;

  return (
    <OutcomesItem disableBackground={true}>
      <h2>Outcomes</h2>
      <OutcomesContent>
        <OutcomeList>
          {outcomes.map((outcome) => (
            <OutcomeEntry key={outcome.id}>
              <Markdown>{outcome.description}</Markdown>
            </OutcomeEntry>
          ))}
        </OutcomeList>
      </OutcomesContent>
    </OutcomesItem>
  );
};

export default Outcomes;
