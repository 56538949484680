// sceneTransitionSlice.js

// import { SceneNames } from "../constants/sceneNames";
let SceneNames = "";

const createSceneTransitionSlice = (set, get) => ({
  currentScene: SceneNames.DEFAULT,
  nextScene: null,
  transitionProgress: 0,
  isTransitioning: false,

  startTransition: (nextScene) => {
    if (nextScene !== get().currentScene && !get().isTransitioning) {
      set({
        nextScene,
        transitionProgress: 0,
        isTransitioning: true,
      });
    }
  },

  updateTransitionProgress: (progress) => {
    set((state) => ({
      transitionProgress: progress,
      isTransitioning: progress < 1,
      currentScene: progress >= 1 ? state.nextScene : state.currentScene,
      nextScene: progress >= 1 ? null : state.nextScene,
    }));
  },

  resetTransition: () => {
    set({
      nextScene: null,
      transitionProgress: 0,
      isTransitioning: false,
    });
  },
});

export default createSceneTransitionSlice;
