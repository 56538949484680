// components/CaseStudy/Disclaimer/Disclaimer.jsx

import React from "react";
import styled from "styled-components";
import Markdown from "react-markdown";
import {
  BentoItem,
  ImageBentoItem,
} from "../../../../components/BentoBox/BentoBox";
import { media } from "../../../../styles/breakpoints";
import { StyledImage } from "../../../../components/BentoBox/BentoBox";

const DisclaimerContentItem = styled(BentoItem)`
  grid-column: 1 / -1;
  grid-row: auto;
  height: fit-content;
  grid-row: auto;

  ${media.up("desktop")`
    grid-column: 5 / -1;
  `}

  ${media.between("laptopS", "desktop")`
    grid-column: span 4;
    margin-top: 2.5rem;
    align-self: end;
  `}

  ${media.between("tabletM", "laptopS")`
    grid-column: 1 / -1;
    grid-row: 2;
  `}

  ${media.between("mobileL", "tabletM")`
    grid-column: 2 / span 2;
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    grid-row: 2;
  `}

  ${media.down("mobileL")`
    grid-column: 2 / span 2;
    margin-top: 0rem;
    margin-right: 0.5rem;
    grid-row: 2;
  `}

  h2 {
    position: relative;
    z-index: 10;
  }
`;

const DisclaimerImageItem = styled(ImageBentoItem)`
  grid-column: 1 / -1;
  grid-row: auto;
  max-height: 30rem;
  grid-row: auto;

  ${media.up("desktop")`
    grid-column: 1 / span 4;
  `}

  ${media.between("laptopS", "desktop")`
    grid-column: span 2;
    align-self: end;
  `}

  ${media.between("tabletM", "laptopS")`
    grid-column: 1 / -1;
    margin-top: 0;
    grid-row: 3;
  `}

  ${media.between("mobileL", "tabletM")`
    grid-column: 2 / span 2;
    margin-right: 0.5rem;
    margin-top: 0;
    grid-row: 3;
  `}

  ${media.down("mobileL")`
    grid-column: 2 / span 2;
    margin-right: 0.5rem;
    grid-row: 3;
  `}
`;

const DisclaimerContent = styled(BentoItem)`
  border-radius: 8px;
  padding: 1.5rem 2rem 1rem 2rem;
  transition: all 0.3s ease;
  position: relative;

  &:hover {
    background-color: rgba(255, 255, 255, 0.75);
    transform: translateY(-5px);
  }

  ${media.up("desktop")`
    padding: 1.5rem 2rem 1rem 2rem;
  `}

  ${media.between("laptopS", "desktop")`
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  `}

  ${media.between("tabletM", "laptopS")`
    padding: 1rem 1.25rem 0.5rem 1.25rem;
  `}

  ${media.down("tabletM")`
    padding: 1.25rem 1.5rem 0.5rem 1.5rem;
  `}
`;

const Disclaimer = ({ image, children, disableBackground }) => {
  if (!image) return null;

  return (
    <>
      {image && (
        <DisclaimerImageItem disableBackground={disableBackground}>
          <StyledImage src={image} alt="Disclaimer" />
        </DisclaimerImageItem>
      )}
      <DisclaimerContentItem disableBackground={true}>
        <h2>Disclaimer</h2>
        <DisclaimerContent>{children}</DisclaimerContent>
      </DisclaimerContentItem>
    </>
  );
};

export default Disclaimer;
