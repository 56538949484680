// components/UI/UI.jsx

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useViewport } from "../../context/ViewportContext";
import Menu from "../Menu/Menu";
import AvatarDialogue from "../AvatarDialogue/AvatarDialogue";
import Logo from "../Logo/Logo";
import ThemeToggle from "../ThemeToggle/ThemeToggle";
import { breakpoints } from "../../styles/breakpoints";
import { useLocation } from "react-router-dom";
import useStore from "../../stores/store";

const UIOverlay = styled.div`
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 980;
`;

const UIWrapper = styled.div`
  display: grid;
  z-index: 990;
  border: 2px solid ${({ theme }) => theme.primaryColor};
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  max-height: calc(var(--vh, 1vh) * 100);

  ${({ $viewport }) => {
    if ($viewport.width <= breakpoints.mobileS) {
      return `
        grid-template-columns: 3rem 1fr;
        grid-template-rows: 3rem 1fr 3rem;
        width: 95%;
        height: calc(var(--vh, 1vh) * ${$viewport.isLandscape ? "90" : "90"});
        ${$viewport.isLandscape ? "grid-template-rows: 2rem 1fr 2rem;" : ""}
      `;
    }
    if ($viewport.width <= breakpoints.mobileM) {
      return `
        grid-template-columns: 3rem 1fr;
        grid-template-rows: 3rem 1fr 3rem;
        width: 95%;
        height: calc(var(--vh, 1vh) * ${$viewport.isLandscape ? "92" : "96"});
        ${$viewport.isLandscape ? "grid-template-rows: 2.5rem 1fr 2.5rem;" : ""}
      `;
    }
    if ($viewport.width <= breakpoints.mobileL) {
      return `
        grid-template-columns: 3rem 1fr;
        grid-template-rows: 3rem 1fr 3rem;
        width: 96%;
        height: calc(var(--vh, 1vh) * ${$viewport.isLandscape ? "94" : "97"});
        ${$viewport.isLandscape ? "grid-template-rows: 2.75rem 1fr 2.75rem;" : ""}
      `;
    }
    if ($viewport.width <= breakpoints.tabletS) {
      return `
        grid-template-columns: ${$viewport.isLandscape ? "3.5rem 1fr" : "3.5rem 1fr"};
        grid-template-rows: ${$viewport.isLandscape ? "2.75rem 1fr 2.75rem" : "3.5rem 1fr 3.5rem"};
        width: 96%;
        height: calc(var(--vh, 1vh) * ${$viewport.isLandscape ? "95" : "97"});
      `;
    }
    if ($viewport.width <= breakpoints.tabletM) {
      return `
        grid-template-columns: ${$viewport.isLandscape ? "3.5rem 1fr" : "3.5rem 1fr"};
        grid-template-rows: ${$viewport.isLandscape ? "2.75rem 1fr 2.75rem" : "3.5rem 1fr 3.5rem"};
        width: 96%;
        height: calc(var(--vh, 1vh) * ${$viewport.isLandscape ? "95" : "97"});
      `;
    }
    if ($viewport.width <= breakpoints.tabletL) {
      return `
        grid-template-columns: ${$viewport.isLandscape ? "3.5rem 1fr" : "3.5rem 1fr"};
        grid-template-rows: ${$viewport.isLandscape ? "2.75rem 1fr 2.75rem" : "3.5rem 1fr 3.5rem"};
        width: 96%;
        height: calc(var(--vh, 1vh) * ${$viewport.isLandscape ? "95" : "97"});
      `;
    }
    if ($viewport.width <= breakpoints.laptopS) {
      return `
        grid-template-columns: ${$viewport.isLandscape ? "5.25rem 1fr" : "5.25rem 1fr"};
        grid-template-rows: ${$viewport.isLandscape ? "5.25rem 1fr 5.25rem" : "5.25rem 1fr 5.25rem"};
        width: 95%;
        height: calc(var(--vh, 1vh) * ${$viewport.isLandscape ? "95" : "95"});
      `;
    }
    if ($viewport.width <= breakpoints.laptopM) {
      return `
        grid-template-columns: ${$viewport.isLandscape ? "5.25rem 1fr" : "5.25rem 1fr"};
        grid-template-rows: ${$viewport.isLandscape ? "5.25rem 1fr 5.25rem" : "5.25rem 1fr 5.25rem"};
        width: 95%;
        height: calc(var(--vh, 1vh) * ${$viewport.isLandscape ? "95" : "95"});
      `;
    }
    return `
      /* Styles for Laptop and above */
      grid-template-columns: 5.25rem 1fr;
      grid-template-rows: 5.25rem 1fr 5.25rem;
      width: 95%;
      max-width: 1920px;
      height: calc(var(--vh, 1vh) * 90);
    `;
  }}
`;

const DialogueLayer = styled.div`
  grid-column: 1/-1;
  grid-row: 2/-1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  z-index: 995;
`;

const UIBorderInsetLeft = styled.div`
  grid-column: 1;
  grid-row: 1/-1;
  height: 100%;
  top: 0;
  left: 0;
  border-right: 1px solid ${({ theme }) => theme.primaryColor};
  z-index: 994;

  ${({ $viewport }) => {
    if ($viewport.width <= breakpoints.mobileS) {
      return `
        /* Styles for mobileS */
        width: 3rem;
      `;
    }
    if ($viewport.width <= breakpoints.mobileL) {
      return `
        /* Styles for mobileM and mobileL */
        width: 3rem;
      `;
    }
    if ($viewport.width <= breakpoints.tabletS) {
      return `
        /* Styles for mobileS */
        width: 3.5rem;
      `;
    }
    if ($viewport.width <= breakpoints.tabletM) {
      return `
        width: calc(var(--vw, 1vw) * ${$viewport.isLandscape ? "2.75rem" : "3.5rem"});
      `;
    }
    if ($viewport.width <= breakpoints.tabletL) {
      return `
        width: calc(var(--vw, 1vw) * ${$viewport.isLandscape ? "2.75rem" : "3.5rem"});
      `;
    }

    return `
      /* Styles for Laptop and above */
      width: 5.25rem;
    `;
  }}
`;

const UIBorderInsetTop = styled.div`
  grid-column: 2;
  grid-row: 1;
  position: relative;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.primaryColor};
  z-index: 994;

  ${({ $viewport }) => {
    if ($viewport.width <= breakpoints.mobileS) {
      return `
        /* Styles for mobileS */
      `;
    }
    if ($viewport.width <= breakpoints.mobileM) {
      return `
        /* Styles for mobileS */
      `;
    }
    if ($viewport.width <= breakpoints.mobileL) {
      return `
        height: 3rem;
      `;
    }
    if ($viewport.width <= breakpoints.tabletS) {
      return `
        height: calc(var(--vh, 1vh) * ${$viewport.isLandscape ? "2.75rem" : "3.5rem"});
      `;
    }
    if ($viewport.width <= breakpoints.tabletM) {
      return `
        height: calc(var(--vh, 1vh) * ${$viewport.isLandscape ? "2.75rem" : "3.5rem"});
      `;
    }
    if ($viewport.width <= breakpoints.tabletL) {
      return `
        height: calc(var(--vh, 1vh) * ${$viewport.isLandscape ? "2.75rem" : "3.5rem"});
      `;
    }
    return `
      /* Styles for Laptop and above */
      height: 5.25rem;
    `;
  }}
`;

const UIContentArea = styled.div`
  display: grid;
  grid-column: 1/-1;
  grid-row: 1/-1;
`;

function UI({ children }) {
  const { theme, initTheme } = useStore();
  const viewport = useViewport();
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const [showDialogue, setShowDialogue] = useState(true);

  useEffect(() => {
    initTheme();
    const setVh = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    setVh(); // Set the value initially
    window.addEventListener("resize", setVh);
    window.addEventListener("orientationchange", setVh);

    return () => {
      window.removeEventListener("resize", setVh);
      window.removeEventListener("orientationchange", setVh);
    };
  }, [initTheme]);

  const handleClose = () => {
    setShowDialogue(false);
  };

  return (
    <UIOverlay>
      <UIWrapper theme={theme} className="uiWrapper" $viewport={viewport}>
        <Logo />
        <Menu />
        {/* {isHomePage && showDialogue && (
          <DialogueLayer>
            <AvatarDialogue
              theme={theme}
              onClose={handleClose}
              $viewport={viewport}
            />
          </DialogueLayer>
        )} */}
        <UIBorderInsetLeft theme={theme} $viewport={viewport} />
        <UIBorderInsetTop theme={theme} $viewport={viewport} />
        <UIContentArea
          theme={theme}
          $viewport={viewport}
          className="contentArea"
        >
          {children}
        </UIContentArea>
        <ThemeToggle />
      </UIWrapper>
    </UIOverlay>
  );
}

export default UI;
