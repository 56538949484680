// components/Menu/Button/Button.styled.jsx

import styled from "styled-components";
import { motion } from "framer-motion";
import { breakpoints } from "../../../styles/breakpoints";
import { typography } from "../../../styles/abstracts/typography";

const getButtonStyles = ($viewport) => {
  const { width, height, isLandscape } = $viewport;

  if (width <= breakpoints.mobileS) {
    return {
      width: "70px",
      height: "35px",
      borderRadius: "10px",
      fontSize: typography.fontSizeXxs,
      lineHeight: typography.fontHeightXs,
    };
  }
  if (width <= breakpoints.mobileM) {
    return {
      width: isLandscape ? "65px" : "65px",
      height: isLandscape ? "30px" : "35px",
      borderRadius: "10px",
      fontSize: typography.fontSizeXs,
      lineHeight: typography.fontHeightXs,
    };
  }
  if (width <= breakpoints.mobileL) {
    return {
      width: "70px",
      height: "35px",
      borderRadius: "10px",
      fontSize: typography.fontSizeXs,
      lineHeight: typography.fontHeightSm,
    };
  }
  if (width <= breakpoints.tabletS) {
    return {
      width: isLandscape ? "65px" : "80px",
      height: isLandscape ? "30px" : "40px",
      borderRadius: "10px",
      fontSize: isLandscape ? typography.fontSizeXs : typography.fontSizeSmall,
      lineHeight: typography.fontHeightSm,
    };
  }
  if (width <= breakpoints.tabletM) {
    return {
      width: isLandscape ? "65px" : "80px",
      height: isLandscape ? "35px" : "40px",
      borderRadius: "10px",
      fontSize: typography.fontSizeXs,
      lineHeight: typography.fontHeightSm,
    };
  }
  if (width <= breakpoints.tabletL) {
    return {
      width: "70px",
      height: "35px",
      borderRadius: "10px",
      fontSize: typography.fontSizeSmall,
      lineHeight: typography.fontHeightSm,
    };
  }
  return {
    width: "100px",
    height: "60px",
    borderRadius: "15px",
    fontSize: typography.fontSizeMedium,
    lineHeight: typography.fontHeightMd,
  };
};

export const ButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  overflow: hidden;
  ${({ $viewport }) => {
    const styles = getButtonStyles($viewport);
    return `
      width: ${styles.width};
      height: ${styles.height};
      border-radius: ${styles.borderRadius};
    `;
  }}
`;

export const Slider = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const SliderEl = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme, isSecond }) =>
    isSecond ? theme.bodyBgPrimary : theme.primaryColor};

  p {
    color: ${({ theme, isSecond }) =>
      isSecond ? theme.textColorPrimary : theme.textColorLight};
    font-family: ${typography.fontBody};
    ${({ $viewport }) => {
      const styles = getButtonStyles($viewport);
      return `
        font-size: ${styles.fontSize};
        line-height: ${styles.lineHeight};
      `;
    }}
  }

  &:hover {
    .perspectiveText {
      transform: rotateX(90deg);

      p {
        &:nth-of-type(1) {
          transform: translateY(-100%);
          opacity: 0;
        }

        &:nth-of-type(2) {
          opacity: 1;
        }
      }
    }
  }
`;

export const PerspectiveTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  transform-style: preserve-3d;
  transition: transform 0.75s cubic-bezier(0.76, 0, 0.24, 1);

  p {
    transition: all 0.75s cubic-bezier(0.76, 0, 0.24, 1);
    pointer-events: none;
    text-transform: uppercase;
    font-family: ${typography.fontBody};
    ${({ $viewport }) => {
      const styles = getButtonStyles($viewport);
      return `
        font-size: ${styles.fontSize};
        line-height: ${styles.lineHeight};
      `;
    }}

    &:nth-of-type(2) {
      position: absolute;
      transform-origin: bottom center;
      transform: rotateX(-90deg) translateY(9px);
      opacity: 0;
    }
  }
`;
