import React, { Suspense, lazy, useEffect, useState } from "react";
import { HashRouter as Router } from "react-router-dom";
import { ViewportProvider } from "./context/ViewportContext";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import AppContent from "./AppContent";
import useStore from "./stores/store";
import Loader from "./components/Loader/Loader"; // Import the Loader component

function App() {
  const { theme, initTheme } = useStore();
  const [isLoading, setIsLoading] = useState(true);

  // Initialize theme
  useEffect(() => {
    const initialize = async () => {
      await initTheme();
      setIsLoading(false);
    };
    initialize();
  }, [initTheme]);

  // Matomo Analytics
  useEffect(() => {
    var _mtm = (window._mtm = window._mtm || []);
    _mtm.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start" });
    var d = document,
      g = d.createElement("script"),
      s = d.getElementsByTagName("script")[0];
    g.async = true;
    g.src =
      "https://cdn.matomo.cloud/wabbit.matomo.cloud/container_Fu0kdlSO.js";
    s.parentNode.insertBefore(g, s);
  }, []);

  if (isLoading) {
    return <Loader isLoading={isLoading} />;
  }

  return (
    <StyledThemeProvider theme={theme}>
      <ViewportProvider>
        <Router>
          <AppContent />
        </Router>
      </ViewportProvider>
    </StyledThemeProvider>
  );
}

export default App;
