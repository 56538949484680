// components/Project/Projects.jsx

import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import SlideOutMenu from "../SlideOut/SlideOutMenu";
import { fetchArticles } from "../../services/ArticleService";
import { fetchCaseStudies } from "../../services/CaseStudyService";
import { fetchProjects } from "../../services/ProjectService";
import { breakpoints, heightBreakpoints } from "../../styles/breakpoints";
import { useViewport } from "../../context/ViewportContext";
import itemConfigs from "../../constants/itemConfigs";
import ProjectsDetails from "./ProjectDetails";
import {
  CATEGORY_NAMES,
  CATEGORY_DISPLAY_NAMES,
} from "../../constants/categories";
import useStore from "../../stores/store";

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  grid-column: 1/-1;
  grid-row: 1/-1;
`;

function Projects() {
  const { tab } = useParams();
  const navigate = useNavigate();
  const {
    theme,
    isCollapsed,
    isPresent,
    shouldCollapseMenu,
    toggleCollapse,
    setShouldCollapseMenu,
  } = useStore();
  const { category } = useParams();
  const viewport = useViewport();
  const [selectedTab, setSelectedTab] = useState(tab || "all");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isDataReady, setIsDataReady] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const [projects, setProjects] = useState([]);
  const [articles, setArticles] = useState([]);
  const [caseStudies, setCaseStudies] = useState([]);
  const [filteredItemsCount, setFilteredItemsCount] = useState(0);

  const [isMenuCollapsed, setIsMenuCollapsed] = useState(true);

  const checkShouldCollapseMenu = useCallback(() => {
    const { width, isLandscape } = viewport;

    const collapseBreakpoints = {
      portrait: [
        breakpoints.mobileS,
        breakpoints.mobileM,
        breakpoints.mobileL,
        breakpoints.tabletS,
        breakpoints.tabletM,
        breakpoints.tabletL,
      ],
      landscape: [
        breakpoints.mobileS,
        breakpoints.mobileM,
        breakpoints.mobileL,
        breakpoints.tabletS,
        breakpoints.tabletM,
      ],
    };

    const currentBreakpoints = isLandscape
      ? collapseBreakpoints.landscape
      : collapseBreakpoints.portrait;
    return currentBreakpoints.some((breakpoint) => width <= breakpoint);
  }, [viewport]);

  useEffect(() => {
    const shouldCollapse = checkShouldCollapseMenu();
    setShouldCollapseMenu(shouldCollapse);
  }, [checkShouldCollapseMenu, setShouldCollapseMenu]);

  const handleMenuCollapse = useCallback(() => {
    toggleCollapse();
  }, [toggleCollapse]);

  const aboutTabs = useMemo(
    () => [
      {
        id: "all",
        title: "All",
        logo: {
          logo_id: "all",
          sprite_sheet: { filename: "sprite-nav" },
        },
      },
      {
        id: "articles",
        title: "Articles",
        logo: {
          logo_id: "articles",
          sprite_sheet: { filename: "sprite-nav" },
        },
      },
      {
        id: "caseStudies",
        title: "Case Studies",
        logo: {
          logo_id: "caseStudies",
          sprite_sheet: { filename: "sprite-nav" },
        },
      },
      {
        id: "photography",
        title: "Photography",
        logo: {
          logo_id: "photo",
          sprite_sheet: { filename: "sprite-nav" },
        },
      },
      {
        id: "featured",
        title: "Works in Progress",
        logo: {
          logo_id: "wip",
          sprite_sheet: { filename: "sprite-nav" },
        },
      },
    ],
    []
  );

  const getBreakpointValue = (breakpointName) => {
    return breakpoints[breakpointName];
  };

  const itemsPerPage = useMemo(() => {
    const { width, height, isLandscape } = viewport;

    const widthCategory =
      Object.entries(breakpoints)
        .sort(([, a], [, b]) => a - b)
        .find(([, value]) => width <= value)?.[0] || "desktopL";

    const heightCategory =
      Object.entries(heightBreakpoints)
        .sort(([, a], [, b]) => a - b)
        .find(([, value]) => height <= value)?.[0] || "heightXL";

    const orientation = isLandscape ? "landscape" : "portrait";

    const widthValue = getBreakpointValue(widthCategory);
    const heightValue = heightBreakpoints[heightCategory];

    // Get the configuration for the current viewport
    let config = itemConfigs[widthValue]?.[heightValue]?.[orientation];

    if (!config) {
      const nearestWidth = Object.keys(itemConfigs)
        .map(Number)
        .sort((a, b) => b - a)
        .find((w) => w <= widthValue);

      const nearestHeight = Object.keys(itemConfigs[nearestWidth] || {})
        .map(Number)
        .sort((a, b) => b - a)
        .find((h) => h <= heightValue);

      config = itemConfigs[nearestWidth]?.[nearestHeight]?.[orientation] || {
        All: 6,
        Featured: 6,
        Projects: 6,
        Photography: 6,
        Articles: 6,
        CaseStudies: 6,
      };
    }

    const finalConfig = {
      all: config?.All || 6,
      featured: config?.Featured || 6,
      projects: config?.Projects || 6,
      articles: config?.Articles || 6,
      caseStudies: config?.CaseStudies || 6,
      photography: config?.Photography || 6,
    };

    return finalConfig;
  }, [viewport]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedTab]);

  useEffect(() => {
    const currentTab = category || "all";
    if (aboutTabs.some((t) => t.id === currentTab)) {
      setSelectedTab(currentTab);
    } else {
      navigate("/projects/all", { replace: true });
    }
    // Set isInitialLoad to false after the initial navigation
    setIsInitialLoad(false);
  }, [category, navigate, aboutTabs]);

  useEffect(() => {
    setIsLoading(true);
    setIsDataReady(false);

    const fetchData = async () => {
      try {
        const [projectsData, articlesData, caseStudiesData] = await Promise.all(
          [fetchProjects(), fetchArticles(), fetchCaseStudies()]
        );
        setProjects(projectsData);
        setArticles(articlesData);
        setCaseStudies(caseStudiesData);
        setIsLoading(false);
        setIsDataReady(true);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to load data. Please try again later.");
        setIsLoading(false);
        setIsDataReady(true);
      }
    };

    fetchData();
  }, []);

  const filteredItems = useMemo(() => {
    const projectItems = projects.map((p) => ({ ...p, itemType: "project" }));
    const articleItems = articles.map((a) => ({ ...a, itemType: "article" }));
    const caseStudyItems = caseStudies.map((cs) => ({
      ...cs,
      itemType: "caseStudy",
    }));

    const allItems = [...projectItems, ...articleItems, ...caseStudyItems];

    const filterItems = (items) => {
      const isPublishedOrFeatured = (item) =>
        item.is_published || item.is_featured;

      switch (selectedTab) {
        case "featured":
          return items.filter((item) => item.is_featured);
        case "all":
          // Include all items that are either published, featured, or a case study
          return items.filter(
            (item) =>
              isPublishedOrFeatured(item) || item.itemType === "caseStudy"
          );
        case "projects":
          // Only include items of type "project" that are published or featured
          return items.filter(
            (item) => item.itemType === "project" && isPublishedOrFeatured(item)
          );
        case "articles":
          // Only include items of type "article" that are published or featured
          return items.filter(
            (item) => item.itemType === "article" && isPublishedOrFeatured(item)
          );
        case "caseStudies":
          // Include all case studies
          return caseStudyItems;
        case "photography":
          // Only include projects with category slug "photography" that are published or featured
          return items.filter(
            (item) =>
              item.itemType === "project" &&
              item.category?.slug === "photography" &&
              isPublishedOrFeatured(item)
          );
        default:
          // Default case: return all published or featured items
          return items.filter(isPublishedOrFeatured);
      }
    };

    let result = filterItems(allItems);
    setFilteredItemsCount(result.length);
    return result;
  }, [selectedTab, projects, articles, caseStudies]);

  useEffect(() => {
    setFilteredItemsCount(filteredItems.length);
  }, [filteredItems]);

  const getCurrentItems = useCallback(
    (items) => {
      const start = (currentPage - 1) * itemsPerPage[selectedTab];
      const end = start + itemsPerPage[selectedTab];
      const currentItems = items.slice(start, end);
      return currentItems;
    },
    [currentPage, itemsPerPage, selectedTab]
  );

  const handleTabChange = useCallback(
    (newTab) => {
      if (!isInitialLoad) {
        setSelectedTab(newTab);
        navigate(`/projects/${newTab}`);
        setCurrentPage(1);

        if (shouldCollapseMenu) {
          toggleCollapse();
        }
      }
    },
    [navigate, shouldCollapseMenu, isInitialLoad, toggleCollapse]
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const itemsPerPageForTab = itemsPerPage[selectedTab] || 6;

  const itemsToRender = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPageForTab;
    const end = start + itemsPerPageForTab;
    return filteredItems.slice(start, end);
  }, [currentPage, itemsPerPageForTab, filteredItems]);

  return (
    <Container viewport={viewport}>
      <SlideOutMenu
        items={aboutTabs}
        isLoading={isLoading}
        isCollapsed={isCollapsed}
        onCollapse={handleMenuCollapse}
        selectedItem={selectedTab}
        onItemSelect={handleTabChange}
        useRouterLinks={false}
        basePath="/projects"
        theme={theme}
        viewport={viewport}
      />
      <ProjectsDetails
        selectedTab={selectedTab}
        filteredItems={itemsToRender}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isLoading={isLoading}
        error={error}
        isDataReady={isDataReady}
        itemsPerPage={itemsPerPage[selectedTab] || 6}
        totalItems={filteredItemsCount}
        viewport={viewport}
      />
    </Container>
  );
}

export default Projects;
