// experiences/Photography/utils.js

/**
 * Preloads images specified by the CSS selector.
 * @function
 * @param {string} [selector='img'] - CSS selector for target images.
 * @returns {Promise} - Resolves when all specified images are loaded.
 */
export const preloadImages = (selector = "img") => {
  return new Promise((resolve) => {
    const images = document.querySelectorAll(selector);
    const totalImages = images.length;
    let loadedImages = 0;

    // If there are no images, resolve immediately
    if (totalImages === 0) {
      resolve();
    }

    const imageLoaded = () => {
      loadedImages++;
      if (loadedImages === totalImages) {
        resolve();
      }
    };

    images.forEach((img) => {
      if (img.complete) {
        imageLoaded();
      } else {
        img.addEventListener("load", imageLoaded);
        img.addEventListener("error", imageLoaded); // Also count errors as "loaded"
      }
    });
  });
};

/**
 * Preloads background images for elements specified by the CSS selector.
 * @function
 * @param {string} selector - CSS selector for target elements with background images.
 * @returns {Promise} - Resolves when all specified background images are loaded.
 */
export const preloadBackgroundImages = (selector) => {
  return new Promise((resolve) => {
    const elements = document.querySelectorAll(selector);
    const totalElements = elements.length;
    let loadedImages = 0;

    // If there are no elements, resolve immediately
    if (totalElements === 0) {
      resolve();
    }

    const imageLoaded = () => {
      loadedImages++;
      if (loadedImages === totalElements) {
        resolve();
      }
    };

    elements.forEach((el) => {
      const backgroundImage = window
        .getComputedStyle(el)
        .getPropertyValue("background-image");
      const imageUrl = backgroundImage.match(/url\(['"]?(.+?)['"]?\)/i);

      if (imageUrl) {
        const img = new Image();
        img.onload = imageLoaded;
        img.onerror = imageLoaded; // Also count errors as "loaded"
        img.src = imageUrl[1];
      } else {
        imageLoaded(); // If no background image, consider it "loaded"
      }
    });
  });
};
