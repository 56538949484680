import React from "react";
import styled from "styled-components";
import { hexToRgba } from "../../utils/hexToRGBA";
import { typography } from "../../styles/abstracts/typography";
import useStore from "../../stores/store";
import { breakpoints, media } from "../../styles/breakpoints";

const BentoWrapper = styled.div`
  display: grid;
  align-items: center;
  column-gap: ${(props) => props.columnGap || "0.5rem"};
  row-gap: ${(props) => props.rowGap || "0.5rem"};

  ${media.up("desktop")`
    grid-template-columns: repeat(9, 1fr);
    padding: 2rem 10rem 0 10rem;
    column-gap: ${(props) => props.columnGap || "1rem"};
    row-gap: ${(props) => props.rowGap || "1rem"};
  `}

  ${media.between("laptopL", "desktop")`
    grid-template-columns: repeat(6, 1fr);
    padding: 0 5.25rem;
  `}
  ${media.between("tabletL", "laptopL")`
    grid-template-columns: repeat(6, 1fr);
    padding: 0 5.25rem 0 7.5rem;
  `}

  ${media.between("tabletM", "tabletL")`
    grid-template-columns: repeat(6, 1fr);
    padding: 2rem 4.25em;
    column-gap: ${(props) => props.columnGap || "1rem"};
    row-gap: ${(props) => props.rowGap || "1rem"};
  `}

  ${media.between("tabletS", "tabletM")`
    grid-template-columns: 3.5rem 1fr 3rem;
    padding: 0 0 1rem 0;
    column-gap: ${(props) => props.columnGap || "1rem"};
    row-gap: ${(props) => props.rowGap || "1rem"};
  `}

  ${media.down("tabletS")`
    grid-template-columns: 3.5rem 1fr 3rem;
    padding: 0 0 1.5rem 0;
    column-gap: ${(props) => props.columnGap || "0.5rem"};
    row-gap: ${(props) => props.rowGap || "0.5rem"};
  `}
`;

const BentoItem = styled.div`
  background-color: ${(props) =>
    props.disableBackground ? "transparent" : props.theme.bodyBgPrimary};
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease;

  ${(props) => props.animation && animations[props.animation]}

  &:hover {
    transform: translateY(-5px);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: bold;
    color: ${(props) => props.theme.primaryColor || "#000"};
  }

  h1 {
    font-size: 1.25em;
  }
  h2 {
    font-size: ${typography.fontSizeLarge};
  }
  h3 {
    font-size: ${typography.fontSizeMedium};
  }
  h4 {
    font-size: 1em;
  }
  h5 {
    font-size: 0.9em;
  }
  h6 {
    font-size: 0.8em;
  }

  p {
    margin-bottom: 1em;
    font-size: ${typography.fontSizeMedium};
    line-height: 1.4;
    color: ${(props) => props.theme.primaryColor || "#000"};
  }

  ul,
  ol {
    margin-top: 0.5em;
    padding-left: 0.5em;
    font-size: ${typography.fontSizeMedium};
  }

  li {
    margin-bottom: 0.25rem;
    font-size: ${typography.fontSizeMedium};
    color: ${(props) => props.theme.primaryColor || "#000"};
  }

  a {
    color: ${(props) => props.theme.linkColor || "#4a90e2"};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  blockquote {
    font-size: ${typography.fontSizeMedium};
    border-left: 4px solid ${(props) => props.theme.primaryColor || "#000"};
    padding-left: 1em;
    margin-left: 0;
    font-style: italic;
    color: ${(props) => props.theme.primaryColor || "#000"};
  }

  code {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 0.2em 0.4em;
    border-radius: 3px;
    font-family: monospace;
  }

  pre {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 3px;
    overflow-x: auto;

    code {
      background-color: transparent;
      padding: 0;
    }
  }

  hr {
    border: none;
    border-top: 1px solid ${(props) => props.theme.hrColor || "#ccc"};
    margin: 2em 0;
  }

  // Desktop styles
  ${media.up("desktop")`
    h2 { font-size: ${typography.fontSizeXxl}; }
    h3 { font-size: ${typography.fontSizeXxl}; }
    blockquote { font-size: ${typography.fontSizeMedium}; line-height: ${typography.fontHeightSm} }    
  `}

  // Styles for screens between laptopL and desktop
  ${media.between("laptopL", "desktop")`
    h2 { font-size: ${typography.fontSizeXxl}; }
    h3 { font-size: ${typography.fontSizeXl}; }
  `}

  // Laptop styles
  ${media.between("laptopS", "laptopL")`
    h2 { font-size: ${typography.fontSizeXl}; }
    h3 { font-size: ${typography.fontSizeXl}; }
    p { font-size: ${typography.fontSizeXs}; }
    li { font-size: ${typography.fontSizeXs}; }
    blockquote { font-size: ${typography.fontSizeXs}; }
  `}

  // Tablet styles
  ${media.between("tabletM", "laptopS")`
    h2 { font-size: ${typography.fontSizeLarge}; }
    h3 { font-size: ${typography.fontSizeLarge}; }
    p { font-size: ${typography.fontSizeXs}; }
    li { font-size: ${typography.fontSizeXs}; }
    blockquote { font-size: ${typography.fontSizeXs}; }
  `}

  // Mobile styles
  ${media.down("tabletM")`
    h2 { font-size: ${typography.fontSizeXl}; }
    h3 { font-size: ${typography.fontSizeXl}; }
    p { font-size: ${typography.fontSizeXs}; }
    li { font-size: ${typography.fontSizeXs}; }
    blockquote { font-size: ${typography.fontSizeXs}; }
  `}
`;

const UnformattedBentoItem = styled.div`
  background-color: ${(props) =>
    props.disableBackground ? "transparent" : props.theme.bodyBgPrimary};
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease;

  ${(props) => props.animation && animations[props.animation]}

  &:hover {
    transform: translateY(-5px);
  }
`;

const ImageBentoItem = styled(BentoItem)`
  padding: 0;
  overflow: hidden;
  display: flex;
  align-items: stretch;
  justify-content: center;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const animations = {};

const BentoBox = ({ children, columnGap, rowGap, disableBackground }) => {
  const { theme } = useStore();

  return (
    <BentoWrapper columnGap={columnGap} rowGap={rowGap}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { theme, disableBackground });
        }
        return child;
      })}
    </BentoWrapper>
  );
};

export {
  BentoBox,
  BentoWrapper,
  BentoItem,
  UnformattedBentoItem,
  ImageBentoItem,
  StyledImage,
};
