// services/SkillService.js
import axios from "axios";

const BASE_URL = "/api/skills/"; // Adjusted to point to the skills endpoint

export const fetchSkills = async () => {
  try {
    const { data } = await axios.get(BASE_URL);
    return data;
  } catch (error) {
    console.error("Error fetching skills:", error);
    throw error;
  }
};
