import React, { useState, useEffect } from "react";
import SVGIcon from "../SVGIcon";
import styled from "styled-components";
import { Grid } from "mauerwerk";
import { useViewport } from "../../context/ViewportContext";
import { breakpoints } from "../../styles/breakpoints";
import { typography } from "../../styles/abstracts/typography";
import Loader from "../Loader/Loader";
import useStore from "../../stores/store";

const FactsHeader = styled.h1`
  font-size: 2rem;
  color: ${({ theme }) => theme.textColorPrimary || "#333"};
  margin: 0;
  padding: 1rem;
  flex-shrink: 0;
  grid-row: 1;

  ${({ $viewport }) => {
    if (!$viewport) return "";
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
        font-size: 0.9rem;
        padding: 0 0 0 0.75rem;
      `;
    } else if (width <= breakpoints.mobileM) {
      return `
        font-size: 1.05rem;
        padding: 0 0 0 0.75rem;
      `;
    } else if (width <= breakpoints.mobileL) {
      return `
        font-size: 1.125rem;
        padding: 0 0 0 1rem;
      `;
    } else if (width <= breakpoints.tabletS) {
      return `
        font-size: 1.25rem;
        padding: 0 0 0 0.75rem;
      `;
    } else if (width <= breakpoints.tabletM) {
      return `
        font-size: ${isLandscape ? `1.25rem` : `1.5rem`};
        padding: ${isLandscape ? `0.25rem 0.75rem` : `0.75rem`};
      `;
    } else if (width <= breakpoints.tabletL) {
      return `
        font-size: 1.25rem;
        padding: 0.5rem 0 0 1rem;
      `;
    } else {
      return `
        padding: 0 0 0 2rem;
      `;
    }
  }}
`;

const FactsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-row: 2;
  margin-top: 2.5rem;
  height: 100%;

  ${({ $viewport }) => {
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
          justify-content: center;
          margin-left: 2rem;
          margin-top: 0;
          margin-right: 1rem
      `;
    }
    if (width <= breakpoints.mobileL) {
      return `
          justify-content: center;
          margin-left: 2rem;
          margin-top: 0;
          margin-right: 1rem
      `;
    }
    if (width <= breakpoints.tabletS) {
      return `
          justify-content: center;
          margin-top: 0;
          margin-left: 2rem;
          margin-right: 0.5rem;
          
          ${
            isLandscape
              ? `
                grid-column: 1;
                justify-content: center;
                margin-top:0;
              `
              : ""
          }
      `;
    }
    if (width <= breakpoints.tabletM) {
      return `
          justify-content: center;
          margin-top: 0;
          margin-left: 2rem;
          margin-right: 0.5rem;

          ${
            isLandscape
              ? `
                grid-column: 1;
                justify-content: center;
                margin-top:0;
                margin-left: 0;
              `
              : ""
          }
      `;
    }
    if (width <= breakpoints.tabletL) {
      return `
          justify-content: center;
          margin-top: 0;
          padding-left: 0.25rem;
          grid-column: 1;

          ${
            isLandscape
              ? `
                grid-column: 1;
                justify-content: center;
                margin-top: 0;
              `
              : ""
          }
      `;
    }
    if (width <= breakpoints.laptopS) {
      return `
          justify-content: start;
          margin-top: 0
          padding-left: 0.25rem;
          grid-column: 1;

          ${
            isLandscape
              ? `
                grid-column: 1;
                justify-content: center;
                margin-top:0;
              `
              : ""
          }
      `;
    }
    if (width <= breakpoints.laptopM) {
      return `
          justify-content: start;
          margin-top: 0
          padding-left: 0.25rem;
          grid-column: 1;

          ${
            isLandscape
              ? `
                grid-column: 1;
                justify-content: center;
                margin-top:0;
                margin-right: 1rem;
              `
              : ""
          }
      `;
    }
    if (width <= breakpoints.laptopL) {
      return `
          justify-content: start;
          padding-left: 0.25rem;
          grid-column: 1;
        margin-top: 2.5rem;

      `;
    }
    if (width <= breakpoints.desktop) {
      return `
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        grid-row: 2;
        margin-top: 2.5rem;

      `;
    }
    if (width <= breakpoints.desktopL) {
      return `
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        grid-row: 2;
        margin-top: 2.5rem;
      `;
    }
  }}
`;

const GridContainer = styled.div`
  width: 100%;
  height: ${(props) => (props.maximized ? `${props.gridHeight}%` : "auto")};
`;

const Fact = styled.div`
  position: relative;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  align-items: center;
  text-align: center;

  & svg path.sticker__paper {
    fill: ${({ theme }) => theme.iconColorSticker};
  }
  & svg path.sticker__stroke {
    fill: ${({ theme }) => theme.iconStrokeColor};
  }
  & svg path.sticker__accent {
    fill: ${({ theme }) => theme.accentColorPrimary};
  }
  & svg path.sticker__accent-secondary {
    fill: ${({ theme }) => theme.accentColorPrimary};
  }
  & svg path.sticker__fill-primary {
    fill: ${({ theme }) => theme.iconColorPrimary};
  }
  & svg path.sticker__fill-secondary {
    fill: ${({ theme }) => theme.iconColorSecondary};
  }
  & svg path.sticker__fill-tertiary {
    fill: ${({ theme }) => theme.iconColorTertiary};
  }
  & svg path.sticker__fill-extra {
    fill: ${({ theme }) => theme.iconColorTertiary};
  }

  & svg.fact__icon path.dc__fill-primary {
    fill: #e9554a;
  }

  & svg.fact__icon path.sushi__fill-primary,
  svg.fact__icon polygon.sushi__fill-primary {
    fill: #ed8389;
  }
  & svg.fact__icon path.sushi__fill-secondary {
    fill: #cc565c;
  }

  & svg.fact__icon path.rain__fill-primary {
    fill: #f7e9f3;
  }
  & svg.fact__icon path.rain__fill-secondary {
    fill: #dceef1;
  }
  & svg.fact__icon path.rain__fill-tertiary {
    fill: #ee7d7b;
  }

  & svg.fact__icon path.card__fill-primary {
    fill: #cc565c;
  }

  & svg.fact__icon path.outside__fill-primary {
    fill: #afc68c;
  }
  & svg.fact__icon path.outside__fill-secondary {
    fill: #c4dca3;
  }
  & svg.fact__icon path.outside__fill-tertiary {
    fill: #896e6e;
  }
  & svg.fact__icon path.outside__fill-extra {
    fill: #987eb7;
  }

  & svg.fact__icon path.song__fill-extra {
    fill: #987eb7;
  }
  & svg.fact__icon path.song__fill-primary {
    fill: #6e6069;
  }
  & svg.fact__icon path.song__fill-secondary {
    fill: #a7a0a6;
  }
  & svg.fact__icon path.song__fill-tertiary {
    fill: #84767e;
  }

  & svg.fact__icon path.kiddo__fill-primary {
    fill: #fbdec7;
  }
  & svg.fact__icon path.kiddo__fill-tertiary {
    fill: #f9f9f9;
  }
  & svg.fact__icon path.kiddo__fill-extra {
    fill: #f6c586;
  }

  & .fact__icon {
    height: 100px;
    width: 100px;

    ${({ $viewport }) => {
      const { width, isLandscape } = $viewport;

      if (width <= breakpoints.mobileS) {
        return `
          height: 60px;
          width: 60px;
        `;
      }
      if (width <= breakpoints.mobileM) {
        return `
          height: 75px;
          width: 75px;
        `;
      }
      if (width <= breakpoints.mobileL) {
        return `
          height: 75px;
          width: 75px;
        `;
      }
      if (width <= breakpoints.tabletS) {
        return `
          height: 100px;
          width: 100px;
        `;
      }
      if (width <= breakpoints.tabletM) {
        return `
          height: 125px;
          width: 125px;
          ${
            isLandscape
              ? `
                max-height: 65px;
                max-width: 65px;
              `
              : ""
          }
        `;
      }
      if (width <= breakpoints.tabletL) {
        return `
          height: 125px;
          width: 125px;

          ${
            isLandscape
              ? `
                max-height: 65px;
                max-width: 65px;
              `
              : ""
          }
        `;
      }
      if (width <= breakpoints.laptopS) {
        return `
          height: 125px;
          width: 125px;

          ${
            isLandscape
              ? `
                max-height: 100px;
                max-width: 100px;
              `
              : ""
          }
        `;
      }
      if (width <= breakpoints.laptopL) {
        return `
          height: 125px;
          width: 125px;

          ${
            isLandscape
              ? `
                max-height: 125px;
                max-width: 125px;
              `
              : ""
          }
        `;
      }
      if (width <= breakpoints.desktop) {
        return `
          height: 150px;
          width: 150px;
        `;
      }
      if (width <= breakpoints.desktopL) {
        return `
          height: 150px;
          width: 150px;
        `;
      }
    }}
  }

  & .fact__icon-expanded {
    height: 100px;
    width: 100px;
    position: relative;

    ${({ $viewport }) => {
      const { width, isLandscape } = $viewport;

      if (width <= breakpoints.mobileS) {
        return `
          margin-top: -3.5rem;
          height: 60px;
          width: 60px;

      `;
      }
      if (width <= breakpoints.mobileM) {
        return `
          margin-top: -4rem;
          height: 75px;
          width: 75px;
      `;
      }
      if (width <= breakpoints.mobileL) {
        return `
          margin-top: -4.5rem;
          height: 75px;
          width: 75px;
      `;
      }
      if (width <= breakpoints.tabletS) {
        return `
          margin-top: -2.5rem;
          height: 100px;
          width: 100px;

          ${
            isLandscape
              ? `
                margin-top: -3.75rem;
                max-height: 100px;
                max-width: 100px;
              `
              : ""
          }

      `;
      }
      if (width <= breakpoints.tabletM) {
        return `
          margin-top: -3.5rem;
          height: 100px;
          width: 100px;
          ${
            isLandscape
              ? `
                margin-top: -2.25rem;
                max-height: 75px;
                max-width: 75px;
              `
              : ""
          }
      `;
      }
      if (width <= breakpoints.tabletL) {
        return `
          margin-top: -5rem;
          height: 125px;
          width: 125px;

          ${
            isLandscape
              ? `
                margin-top: -2.5rem;
                max-height: 75px;
                max-width: 75px;
              `
              : ""
          }
      `;
      }

      if (width <= breakpoints.laptopS) {
        return `
          margin-top: -3.75rem;
          height: 125px;
          width: 125px;

          ${
            isLandscape
              ? `
                margin-top: -3.5rem;
                max-height: 100px;
                max-width: 100px;
              `
              : ""
          }
      `;
      }
      if (width <= breakpoints.laptopL) {
        return `
          margin-top: -4.25rem;
          height: 125px;
          width: 125px;

          ${
            isLandscape
              ? `
                margin-top: -7.25rem;
                height: 175px;
                width: 175px;
              `
              : ""
          }
      `;
      }
      if (width <= breakpoints.desktop) {
        return `
          margin-top: -4.25rem;
          height: 125px;
          width: 125px;

          ${
            isLandscape
              ? `
                margin-top: -7.25rem;
                height: 175px;
                width: 175px;
              `
              : ""
          }
      `;
      }
      if (width <= breakpoints.desktopL) {
        return `
          margin-top: -4.25rem;
          height: 125px;
          width: 125px;

          ${
            isLandscape
              ? `
                margin-top: -7.25rem;
                height: 175px;
                width: 175px;
              `
              : ""
          }
      `;
      }
    }}
  }
`;

const FactTitle = styled.h2`
  color: ${({ theme }) => theme.textColorPrimary};
  ${({ $viewport, $maximized }) => {
    if ($viewport.width <= breakpoints.mobileS) {
      return `
        font-size: ${typography.fontSizeXxs};
      `;
    } else if ($viewport.width <= breakpoints.mobileM) {
      return `
        font-size: ${$maximized ? typography.fontHeightSm : typography.fontSizeXxs};
        padding: 0 0.25rem;
        text-align: center;
      `;
    } else if ($viewport.width <= breakpoints.mobileL) {
      return `
        font-size: ${typography.fontSizeXxs};
        padding: 0 0.25rem;
      `;
    } else if ($viewport.width <= breakpoints.tabletS) {
      return `
        font-size: ${typography.fontSizeXxs};
      `;
    } else if ($viewport.width <= breakpoints.tabletM) {
      return `
        font-size: ${typography.fontSizeXxs};
        padding: 0 0.25rem;
        text-align: center;
      `;
    } else if ($viewport.width <= breakpoints.tabletL) {
      return `
        font-size: ${typography.fontSizeXxs};
        padding: 0 0.25rem;
      `;
    } else if ($viewport.width <= breakpoints.laptopS) {
      return `
        font-size: ${typography.fontSizeXs};
      `;
    }
  }};
`;

const FactContent = styled.pre`
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
  margin: 0;
  padding: 0;
  text-align: left;
  color: ${({ theme }) => theme.textColorPrimary};

  ${({ $viewport }) => {
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `         
        font-size: ${typography.fontSizeXxxs};
        line-height: ${typography.fontHeightXxs};
        text-align: center;
      `;
    }
    if (width <= breakpoints.mobileM) {
      return `          
        font-size: ${typography.fontSizeXxxs};
        line-height: ${typography.fontHeightXxs};
        text-align: center;
      `;
    }
    if (width <= breakpoints.mobileL) {
      return `
        font-size: ${typography.fontSizeXxxs};
        line-height: ${typography.fontHeightXxs};
        text-align: center;
      `;
    }
    if (width <= breakpoints.tabletS) {
      return `
        font-size: ${typography.fontSizeXs};
        line-height: ${typography.fontHeightXs};
        text-align: center;
          
        ${
          isLandscape
            ? `              
              font-size: ${typography.fontSizeXxs};
              line-height: ${typography.fontHeightXs};
            `
            : ""
        }
      `;
    }
    if (width <= breakpoints.tabletM) {
      return `
        font-size: ${typography.fontSizeXs};
        line-height: ${typography.fontHeightXs};

        ${
          isLandscape
            ? `
              font-size: ${typography.fontSizeXxxs};
              line-height: ${typography.fontHeightXxs};
            `
            : ""
        }
      `;
    }
    if (width <= breakpoints.tabletL) {
      return `
        font-size: ${typography.fontSizeSmall};
        line-height: ${typography.fontHeightSm};

        ${
          isLandscape
            ? `
                font-size: ${typography.fontSizeXxxs};
                line-height: ${typography.fontHeightXxs};
              `
            : ""
        }
      `;
    }

    if (width <= breakpoints.laptopS) {
      return `
        font-size: ${typography.fontSizeSmall};
        line-height: ${typography.fontHeightSm};

        ${
          isLandscape
            ? `
              font-size: ${typography.fontSizeXs};
              line-height: ${typography.fontHeightSm};
            `
            : ""
        }
      `;
    }
    if (width <= breakpoints.laptopL) {
      return `
        font-size: ${typography.fontSizeSmall};
        line-height: ${typography.fontHeightSm};

        ${
          isLandscape
            ? `
              font-size: ${typography.fontSizeMedium};
              line-height: ${typography.fontHeightLg};
            `
            : ""
        }
      `;
    }
    if (width <= breakpoints.desktop) {
      return `

        ${
          isLandscape
            ? `
                font-size: ${typography.fontSizeMedium};
                line-height: ${typography.fontHeightLg};
            `
            : ""
        }
      `;
    }
    if (width <= breakpoints.desktopL) {
      return `
        
        ${
          isLandscape
            ? `
                font-size: ${typography.fontSizeMedium};
                line-height: ${typography.fontHeightLg};
              `
            : ""
        }
      `;
    }
  }}
`;

const FactExpandedContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  align-content: center;

  ${({ $viewport }) => {
    if ($viewport.width <= breakpoints.mobileS) {
      return `

      `;
    }
    if ($viewport.width <= breakpoints.mobileM) {
      return `

      `;
    }
    if ($viewport.width <= breakpoints.mobileL) {
      return `

      `;
    }
    if ($viewport.width <= breakpoints.tabletS) {
      return `
        width: 80%;
        margin-top: 1.5rem;
      `;
    }
    if ($viewport.width <= breakpoints.tabletM) {
      return `
        width: 100%;
      `;
    }
    if ($viewport.width <= breakpoints.tabletL) {
      return `
        width: 90%;
      `;
    }

    if ($viewport.width <= breakpoints.laptopS) {
      return `
        width: 90%;
      `;
    }
    if ($viewport.width <= breakpoints.laptopL) {
      return `
        width: 90%;
      `;
    }
    if ($viewport.width <= breakpoints.desktop) {
      return `
        width: 75%;
        padding-top: 10%;
      `;
    }
    if ($viewport.width <= breakpoints.desktopL) {
      return `
        width: 50%;
        padding-top: 5%;
      `;
    }
  }}
`;

const FactExpanded = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.bodyBgPrimary};
  border-radius: 10px;
  padding: 2rem;
  z-index: -10;

  & h2 {
    margin: 0 0 1rem 0;
    text-align: center;
  }

  ${({ $viewport }) => {
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
          height: auto;
          width: auto;
          padding: 1.5rem 0.75rem;

          & p {
            font-size: ${typography.fontSizeMin};
            line-height: ${typography.fontHeightXxxs};
            text-align: center;
          }

          & h2 {
            font-size: ${typography.fontSizeXs};
            line-height: ${typography.fontHeightXxs};
            text-align: center;
          }
      `;
    }
    if (width <= breakpoints.mobileM) {
      return `
          height: auto;
          width: auto;
          padding: 1.5rem 0.75rem;

          & p {
            font-size: ${typography.fontSizeMin};
            line-height: ${typography.fontHeightXxxs};
            text-align: center;
          }

          & h2 {
            font-size: ${typography.fontSizeXs};
            line-height: ${typography.fontHeightXxs};
            text-align: center;
          }
      `;
    }
    if (width <= breakpoints.mobileL) {
      return `
          height: auto;
          width: auto;
          padding: 1.5rem 1rem;

          & p {
            font-size: ${typography.fontSizeXxs};
            line-height: ${typography.fontHeightXs};
            text-align: center;
          }

          & h2 {
            font-size: ${typography.fontSizeMedium};
            line-height: ${typography.fontHeightXxs};
            text-align: center;
          }
      `;
    }
    if (width <= breakpoints.tabletS) {
      return `
          height: auto;
          width: auto;
          padding: 1.5rem 1rem;

          & p {
            font-size: ${typography.fontSizeXs};
            line-height: ${typography.fontHeightXs};
            text-align: center;
          }

          & h2 {
            font-size: ${typography.fontSizeSm};
            line-height: ${typography.fontHeightXxs};
            text-align: center;
          }

          
        ${
          isLandscape
            ? `
              height: auto;
              width: auto;
              padding: 1rem;

              & p {
                font-size: ${typography.fontSizeXxs};
                line-height: ${typography.fontHeightXs};
              }

              & h2 {
                font-size: ${typography.fontSizeSm};
                line-height: ${typography.fontHeightXxs};          
              }
            `
            : ""
        }
      `;
    }
    if (width <= breakpoints.tabletM) {
      return `
        height: auto;
        width: auto;
        padding: 1rem 1.5rem;

        & p {
          font-size: ${typography.fontSizeXs};
          line-height: ${typography.fontHeightXs};
        }

        & h2 {
          font-size: ${typography.fontSizeSm};
          line-height: ${typography.fontHeightXs};  
        }

        ${
          isLandscape
            ? `
              height: auto;
              width: auto;
              padding: 1rem;

              & p {
                font-size: ${typography.fontSizeXxxs};
                line-height: ${typography.fontHeightXxs};
              }

              & h2 {
                font-size: ${typography.fontSizeXs};
                line-height: ${typography.fontHeightXxs};          
              }
            `
            : ""
        }
      `;
    }
    if (width <= breakpoints.tabletL) {
      return `
        height: auto;
        width: auto;
        padding: 1.5rem 2.5rem;

        & p {
          font-size: ${typography.fontSizeSmall};
          line-height: ${typography.fontHeightSm};
        }

        & h2 {
          font-size: ${typography.fontSizeLarge};
          line-height: ${typography.fontHeightXs};  
        }

          ${
            isLandscape
              ? `
                height: auto;
                width: auto;
                padding: 1rem;

                & p {
                  font-size: ${typography.fontSizeXxxs};
                  line-height: ${typography.fontHeightXxs};
                }

                & h2 {
                  font-size: ${typography.fontSizeXs};
                  line-height: ${typography.fontHeightXxs};          
                }
              `
              : ""
          }
      `;
    }

    if (width <= breakpoints.laptopS) {
      return `
        height: auto;
        width: auto;
        padding: 1.5rem 2.5rem;

        & p {
          font-size: ${typography.fontSizeSmall};
          line-height: ${typography.fontHeightSm};
        }

        & h2 {
          font-size: ${typography.fontSizeLarge};
          line-height: ${typography.fontHeightXs};  
        }

          ${
            isLandscape
              ? `
                height: auto;
                width: auto;
                padding: 1rem;

                & p {
                  font-size: ${typography.fontSizeXs};
                  line-height: ${typography.fontHeightSm};
                }

                & h2 {
                  font-size: ${typography.fontSizeSm};
                  line-height: ${typography.fontHeightXs};          
                }
              `
              : ""
          }
      `;
    }
    if (width <= breakpoints.laptopL) {
      return `
        height: auto;
        width: auto;
        padding: 1.5rem 2.5rem;

        & p {
          font-size: ${typography.fontSizeSmall};
          line-height: ${typography.fontHeightSm};
        }

        & h2 {
          font-size: ${typography.fontSizeLarge};
          line-height: ${typography.fontHeightXs};  
        }

        ${
          isLandscape
            ? `
              height: auto;
              width: auto;
              padding: 1rem 2rem 2rem 2rem;

              & p {
                font-size: ${typography.fontSizeMedium};
                line-height: ${typography.fontHeightLg};
              }

              & h2 {
                font-size: ${typography.fontSizeXl};
                line-height: ${typography.fontHeightXs};          
              }
            `
            : ""
        }
      `;
    }
    if (width <= breakpoints.desktop) {
      return `
        height: auto;
        width: auto;
        padding: 1.5rem 2.5rem;

        ${
          isLandscape
            ? `
              height: auto;
              width: auto;
              padding: 1rem 2rem 2rem 2rem;

              & p {
                font-size: ${typography.fontSizeMedium};
                line-height: ${typography.fontHeightLg};
              }

              & h2 {
                font-size: ${typography.fontSizeXl};
                line-height: ${typography.fontHeightXs};          
              }
            `
            : ""
        }
      `;
    }
    if (width <= breakpoints.desktopL) {
      return `
        height: auto;
        width: auto;
        padding: 1.5rem 2.5rem;
        
        ${
          isLandscape
            ? `
              height: auto;
              width: auto;
              padding: 1rem 2rem 2rem 2rem;

              & p {
                font-size: ${typography.fontSizeMedium};
                line-height: ${typography.fontHeightLg};
              }

              & h2 {
                font-size: ${typography.fontSizeXl};
                line-height: ${typography.fontHeightXs};          
              }
              `
            : ""
        }
      `;
    }
  }}
`;

const Facts = ({ data, isLoading, error, itemsPerPage }) => {
  const { theme } = useStore();
  const viewport = useViewport();
  const [columns, setColumns] = useState(4);
  const [cellHeight, setCellHeight] = useState(200);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [maximizedItems, setMaximizedItems] = useState(false);

  const itemsToRender =
    data && itemsPerPage > 0 ? data.slice(0, itemsPerPage) : data || [];

  useEffect(() => {
    const handleResize = () => {
      if (viewport.width <= breakpoints.mobileS) {
        setColumns(2);
        setCellHeight(viewport.isLandscape ? 80 : 125);
      } else if (viewport.width <= breakpoints.mobileM) {
        setColumns(2);
        setCellHeight(viewport.isLandscape ? 100 : 125);
      } else if (viewport.width <= breakpoints.mobileL) {
        setColumns(2);
        setCellHeight(viewport.isLandscape ? 100 : 125);
      } else if (viewport.width <= breakpoints.tabletS) {
        setColumns(2);
        setCellHeight(viewport.isLandscape ? 110 : 125);
      } else if (viewport.width <= breakpoints.tabletM) {
        setColumns(viewport.isLandscape ? 2 : 2);
        setCellHeight(viewport.isLandscape ? 100 : 125);
      } else if (viewport.width <= breakpoints.tabletL) {
        setColumns(viewport.isLandscape ? 2 : 2);
        setCellHeight(viewport.isLandscape ? 100 : 200);
      } else if (viewport.width <= breakpoints.laptopS) {
        setColumns(viewport.isLandscape ? 2 : 2);
        setCellHeight(viewport.isLandscape ? 150 : 225);
      } else if (viewport.width <= breakpoints.laptopM) {
        setColumns(viewport.isLandscape ? 2 : 2);
        setCellHeight(viewport.isLandscape ? 200 : 200);
      } else if (viewport.width <= breakpoints.laptopL) {
        setColumns(viewport.isLandscape ? 2 : 2);
        setCellHeight(viewport.isLandscape ? 200 : 200);
      } else if (viewport.width <= breakpoints.desktop) {
        setColumns(3);
        setCellHeight(viewport.isLandscape ? 225 : 250);
      } else {
        setColumns(3);
        setCellHeight(250);
      }
      setForceUpdate(true);
    };

    handleResize();
  }, [viewport]);

  if (error) {
    return <div>{error}</div>;
  }

  if (isLoading) {
    return <Loader isLoading={true} text="Loading Facts..." />;
  }

  if (!data || data.length === 0) {
    return <div>No facts available.</div>;
  }

  // Calculate total grid height based on number of facts and columns
  const gridHeight = Math.ceil(itemsToRender.length / columns) * cellHeight;

  const isAnyItemMaximized = Object.values(maximizedItems).some(
    (value) => value
  );

  return (
    <>
      <FactsHeader $viewport={viewport} theme={theme}>
        [ Some Facts ]
      </FactsHeader>
      <FactsContainer $viewport={viewport} theme={theme}>
        <GridContainer
          maximized={isAnyItemMaximized}
          gridHeight={gridHeight}
          theme={theme}
        >
          {forceUpdate && (
            <Grid
              className="grid"
              data={itemsToRender}
              keys={(fact) => fact.id}
              columns={columns}
              heights={() => cellHeight}
              lockScroll={true}
            >
              {(fact, maximized, toggle) => (
                <Fact
                  onClick={() => {
                    toggle();
                    setMaximizedItems((prev) => ({
                      ...prev,
                      [fact.id]: !prev[fact.id],
                    }));
                  }}
                  className="fact"
                  theme={theme}
                  $viewport={viewport}
                >
                  {maximized && (
                    <FactExpandedContainer
                      theme={theme}
                      $viewport={viewport}
                      className="expanded-container"
                    >
                      <FactExpanded
                        theme={theme}
                        $viewport={viewport}
                        className="expanded"
                      >
                        <SVGIcon
                          iconId={fact.icon.icon_id}
                          spriteSheetName={fact.icon.sprite_sheet.filename}
                          additionalClasses={["fact__icon fact__icon-expanded"]}
                          ariaLabel={fact.name || fact.title}
                          theme={theme}
                        />
                        <FactTitle
                          theme={theme}
                          $viewport={viewport}
                          $maximized={maximized}
                        >
                          {fact.name || fact.title}
                        </FactTitle>
                        <FactContent
                          $maximized={maximized}
                          $viewport={viewport}
                          theme={theme}
                        >
                          {fact.fact}
                        </FactContent>
                      </FactExpanded>
                    </FactExpandedContainer>
                  )}
                  {!maximized && (
                    <>
                      <SVGIcon
                        iconId={fact.icon.icon_id}
                        spriteSheetName={fact.icon.sprite_sheet.filename}
                        additionalClasses={["fact__icon"]}
                        ariaLabel={fact.name || fact.title}
                        theme={theme}
                      />
                      <FactTitle
                        $viewport={viewport}
                        $maximized={maximized}
                        theme={theme}
                      >
                        {fact.name || fact.title}
                      </FactTitle>
                    </>
                  )}
                </Fact>
              )}
            </Grid>
          )}
        </GridContainer>
      </FactsContainer>
    </>
  );
};

export default Facts;
