import React, { useMemo, useState, useCallback } from "react";
import styled from "styled-components";
import { hexToRgba } from "../../utils/hexToRGBA";
import Pagination from "../Project/Pagination";
import { breakpoints } from "../../styles/breakpoints";
import ProjectList from "./ProjectList";
import Loader from "../Loader/Loader";
import useStore from "../../stores/store";

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 40rem 1fr 5rem;
  height: 100%;
  width: 100%;
  grid-column: 1/-1;
  grid-row: 1/-1;

  ${({ viewport }) => {
    // Helper function to check orientation
    const isLandscape = viewport.width > viewport.height;

    if (viewport.width <= breakpoints.mobileS) {
      return `
    grid-template-columns: ${isLandscape ? "1fr 1fr" : "1fr"};
    grid-template-rows: ${isLandscape ? "1fr" : "auto 1fr"};
    ${isLandscape ? "grid-auto-flow: column;" : ""}
  `;
    }
    if (viewport.width <= breakpoints.mobileM) {
      return `
    grid-template-columns: ${isLandscape ? "1fr 1fr" : "1fr"};
    grid-template-rows: ${isLandscape ? "1fr" : "auto 1fr"};
    ${isLandscape ? "grid-auto-flow: column;" : ""}
  `;
    }
    if (viewport.width <= breakpoints.mobileL) {
      return `
    grid-template-columns: ${isLandscape ? "1fr 1fr 1fr" : "1fr"};
    grid-template-rows: ${isLandscape ? "1fr" : "auto 1fr auto"};
    ${isLandscape ? "grid-auto-flow: column;" : ""}
  `;
    }
    if (viewport.width <= breakpoints.tabletS) {
      return `
    grid-template-columns: ${isLandscape ? "1fr 1fr 1fr" : "1fr 1fr"};
    grid-template-rows: ${isLandscape ? "1fr 1fr" : "auto 1fr auto"};
    ${isLandscape ? "grid-auto-flow: column;" : ""}
  `;
    }
    if (viewport.width <= breakpoints.tabletM) {
      return `
    grid-template-columns: ${isLandscape ? "1fr 1fr 1fr 1fr" : "1fr 1fr"};
    grid-template-rows: ${isLandscape ? "1fr 1fr" : "auto 1fr auto"};
    ${isLandscape ? "grid-auto-flow: column;" : ""}
  `;
    }
    if (viewport.width <= breakpoints.tabletL) {
      return `
    grid-template-columns: ${isLandscape ? "1fr 1fr 1fr 1fr" : "1fr 1fr 1fr"};
    grid-template-rows: ${isLandscape ? "1fr 1fr 1fr" : "auto 1fr auto"};
    ${isLandscape ? "grid-auto-flow: column;" : ""}
  `;
    }
    if (viewport.width <= breakpoints.laptopS) {
      return `
        grid-template-columns: 5.25rem 1fr;
      `;
    }
    if (viewport.width <= breakpoints.laptopL) {
      return `
        grid-template-columns: 5.25rem 1fr;
      `;
    }
    if (viewport.width <= breakpoints.desktop) {
      return `
        grid-template-columns: 30rem 1fr 5rem;
      `;
    }
    if (viewport.width <= breakpoints.desktopL) {
      return `
        grid-template-columns: 40rem 1fr 5rem;
      `;
    }
  }}
`;

const ContentArea = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: 5.25rem 1fr 5.25rem;
  grid-column: 2/-1;
  grid-row: 1/-1;
  z-index: 1900;
  pointer-events: auto;

  ${({ $viewport }) => {
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
        grid-template-rows: 3rem 1fr;
        grid-template-columns: 1fr 2.5rem;
        padding: 0;
      `;
    }
    if (width <= breakpoints.mobileM) {
      return `
        grid-template-rows: 3rem 1fr;
        grid-template-columns: 1fr 2.5rem;
        padding: 0;
      `;
    }
    if (width <= breakpoints.mobileL) {
      return `
        grid-template-rows: 3rem 1fr;
        grid-template-columns: 1fr 2.5rem;
        padding: 0;
      `;
    }
    if (width <= breakpoints.tabletS) {
      return `
        grid-template-rows: 3.5rem 1fr;
        grid-template-columns: 1fr 2.5rem;
        padding: 0;

        align-items: center;

        ${
          isLandscape
            ? `
              grid-template-rows: 2.75rem 1fr;
              grid-template-columns: 1fr 2.5rem;
            `
            : ""
        }
      `;
    }
    if (width <= breakpoints.tabletM) {
      return `
        grid-template-rows: 3.5rem 1fr;
        grid-template-columns: 1fr 2.5rem;
        padding: 0;

        align-items: center;
        justify-items: center;
        
        ${
          isLandscape
            ? `
              grid-template-rows: 2.75rem 1fr;
              grid-template-columns: 15rem 1fr 2.5rem;
            `
            : ""
        }
      `;
    }
    if (width <= breakpoints.tabletL) {
      return `
        grid-template-rows: 3.5rem 1fr 3.5rem;
        grid-template-columns: 1fr 2.5rem;
        padding: 0;

        align-items: center;
        justify-items: center;

        ${
          isLandscape
            ? `
              grid-template-rows: 2.75rem 1fr;
              grid-template-columns: 17.5rem 1fr 2.5rem;
            `
            : ""
        }
      `;
    }

    if (width <= breakpoints.laptopS) {
      return `
        grid-template-rows: 5.25rem 1fr;
        grid-template-columns: 1fr 2.5rem;
        grid-column: 2/-1;

        align-items: center;
        justify-items: center;
      `;
    }
    if (width <= breakpoints.laptopM) {
      return `
        grid-template-rows: 5.25rem 1fr;
        grid-template-columns: 1fr 2.5rem;
        grid-column: 2/-1;

        align-items: center;
        justify-items: center;
      `;
    }
    if (width <= breakpoints.laptopL) {
      return `
        grid-template-rows: 5.25rem 1fr;
        grid-template-columns: 1fr 3.5rem;
        padding: 0 0 0 2.5rem;
        grid-column: 2/-1;

        align-items: center;
        justify-items: center;
      `;
    }
    if (width <= breakpoints.desktop) {
      return `
        grid-template-rows: 5.25rem 1fr;
        grid-template-columns: 1fr;

        align-items: center;
        justify-items: center;
      `;
    }
    if (width <= breakpoints.desktopL) {
      return `
        grid-template-rows: 5.25rem 1fr;
        grid-template-columns: 1fr 5.25rem;

        align-items: center;
        justify-items: center;
      `;
    }
  }}
`;

const GlassOverlay = styled.div`
  position: relative;
  display: grid;
  width: 100%;
  height: 100%;
  grid-column: 1/-1;
  grid-row: 1/-1;
  grid-template-columns: 35.5rem 1fr 5.25rem;
  grid-template-rows: 5.25rem 1fr 5.25rem;
  background: ${({ theme }) => hexToRgba(theme.bodyBgSecondary, 0.25)};
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  margin: 0 auto;
  z-index: 900;

  ${({ $viewport }) => {
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
        grid-template-columns: 3rem 1fr;
        grid-template-rows: 3em 1fr 3rem;
      `;
    }
    if (width <= breakpoints.mobileM) {
      return `
        grid-template-columns: 3rem 1fr;
        grid-template-rows: 3em 1fr 3rem;
      `;
    }
    if (width <= breakpoints.mobileL) {
      return `
        grid-template-columns: 3rem 1fr;
        grid-template-rows: 3em 1fr;

      `;
    }
    if (width <= breakpoints.tabletS) {
      return `
        grid-template-columns: 3.5rem 1fr;
        grid-template-rows: 3.5em 1fr;
      `;
    }
    if (width <= breakpoints.tabletM) {
      return `
        grid-template-columns: 3.5rem 1fr;
        grid-template-rows: 3.5em 1fr 3.5rem;

        ${
          isLandscape
            ? `
              grid-template-columns: 2.75rem 1fr;
              grid-template-rows: 2.75em 1fr 2.75rem;
            `
            : ""
        }
      `;
    }
    if (width <= breakpoints.tabletL) {
      return `
        grid-template-columns: 3.5rem 1fr;
        grid-template-rows: 3.5em 1fr 3.5rem;

        ${
          isLandscape
            ? `
              grid-template-columns: 2.75rem 1fr;
              grid-template-rows: 2.75em 1fr 2.75rem;
            `
            : ""
        }
      `;
    }

    if (width <= breakpoints.laptopS) {
      return `
        grid-template-columns: 25rem 1fr 5.25rem;

        ${
          isLandscape
            ? `
              grid-template-columns: 25.5rem 1fr 3.5rem;
              grid-template-rows: 2.75em 1fr 2.75rem;
            `
            : ""
        }
      `;
    }
    if (width <= breakpoints.laptopM) {
      return `
        grid-template-columns: 30rem 1fr 5.25rem;

      `;
    }
    if (width <= breakpoints.laptopL) {
      return `
        grid-template-columns: 30rem 1fr 5.25rem;

      `;
    }
    if (width <= breakpoints.desktop) {
      return `
        grid-template-columns: 32.5rem 1fr 3.5rem;
        grid-template-rows: 5.25rem 1fr;
      `;
    }
    if (width <= breakpoints.desktopL) {
      return `
        grid-template-columns: 32.5rem 1fr 5.25rem;

      `;
    }
  }}
`;

function ProjectsDetails({
  selectedTab,
  filteredItems,
  currentPage,
  setCurrentPage,
  isLoading,
  error,
  isDataReady,
  itemsPerPage,
  viewport,
  totalItems,
}) {
  const { theme } = useStore();

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderSelectedComponent = () => {
    if (!isDataReady) return null;

    const componentKey = `${selectedTab}-${currentPage}`;
    const commonProps = {
      key: componentKey,
      isLoading,
      error,
      itemsPerPage: itemsPerPage[selectedTab] || 0,
      selectedTab,
    };

    return (
      <ProjectList
        {...commonProps}
        projects={filteredItems}
        className="project-list"
        theme={theme}
      />
    );
  };

  return (
    <Container viewport={viewport}>
      <GlassOverlay theme={theme} $viewport={viewport}>
        <ContentArea $viewport={viewport} className="content-area">
          {renderSelectedComponent()}
          {selectedTab && !isLoading && !error && isDataReady && (
            <Pagination
              currentPage={currentPage}
              totalItems={totalItems}
              itemsPerPage={itemsPerPage}
              onPageChange={handlePageChange}
              viewport={viewport}
            />
          )}
          <Loader isLoading={isLoading} text="Loading projects..." />
        </ContentArea>
      </GlassOverlay>
    </Container>
  );
}

export default ProjectsDetails;
