export const CATEGORY_NAMES = {
  ALL: "all",
  FEATURED: "featured",
  PROJECTS: "projects",
  PHOTOGRAPHY: "photography",
  ARTICLES: "articles",
  CASE_STUDIES: "caseStudies",
  WORKS_IN_PROGRESS: "worksInProgress",
};

export const CATEGORY_DISPLAY_NAMES = {
  [CATEGORY_NAMES.ALL]: "All",
  [CATEGORY_NAMES.FEATURED]: "Featured",
  [CATEGORY_NAMES.PROJECTS]: "Projects",
  [CATEGORY_NAMES.PHOTOGRAPHY]: "Photography",
  [CATEGORY_NAMES.ARTICLES]: "Articles",
  [CATEGORY_NAMES.CASE_STUDIES]: "Case Studies",
  [CATEGORY_NAMES.WORKS_IN_PROGRESS]: "Works in Progress",
};
