/**
 * CaseStudyGrid.jsx
 *
 * This file is a draft component that defines a 3D grid of case study cards using React Three Fiber.
 * It includes functionality for card expansion, pagination, and responsive layout.
 */

import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { Canvas, useThree, useFrame } from "@react-three/fiber";
import { OrbitControls, Text, Html } from "@react-three/drei";
import { useSpring, animated, config } from "@react-spring/three";
import { useViewport } from "../../context/ViewportContext";
import { create } from "zustand";
import debounce from "lodash/debounce";
import styled from "styled-components";
import * as THREE from "three";

/**
 * Zustand store for managing the grid state
 *
 * This store manages the expanded card index and the list of items to be displayed in the grid.
 */
const useStore = create((set) => ({
  expandedIndex: null,
  setExpandedIndex: (index) => set({ expandedIndex: index }),
  items: [
    {
      title: "Project A",
      color: "#ff6b6b",
      description: "Description for Project A",
      link: "https://projecta.com",
    },
    {
      title: "Project B",
      color: "#4ecdc4",
      description: "Description for Project B",
      link: "https://projectb.com",
    },
    {
      title: "Project C",
      color: "#45b7d1",
      description: "Description for Project C",
      link: "https://projectc.com",
    },
    {
      title: "Project D",
      color: "#f9ca24",
      description: "Description for Project D",
      link: "https://projectd.com",
    },
    {
      title: "Project E",
      color: "#6ab04c",
      description: "Description for Project E",
      link: "https://projecte.com",
    },
    {
      title: "Project F",
      color: "#686de0",
      description: "Description for Project F",
      link: "https://projectf.com",
    },
    {
      title: "Project G",
      color: "#eb4d4b",
      description: "Description for Project G",
      link: "https://projectg.com",
    },
    {
      title: "Project H",
      color: "#22a6b3",
      description: "Description for Project H",
      link: "https://projecth.com",
    },
    {
      title: "Project I",
      color: "#be2edd",
      description: "Description for Project I",
      link: "https://projecti.com",
    },
    {
      title: "Project J",
      color: "#fdcb6e",
      description: "Description for Project J",
      link: "https://projectj.com",
    },
    {
      title: "Project K",
      color: "#00b894",
      description: "Description for Project K",
      link: "https://projectk.com",
    },
    {
      title: "Project L",
      color: "#0984e3",
      description: "Description for Project L",
      link: "https://projectl.com",
    },
  ],
}));

const StyledCanvas = styled.div`
  grid-row: 2;
  width: 100%;
  height: 100%;
`;

/**
 * ExpandedView component
 * Renders an expanded view of a card when clicked
 */
function ExpandedView({ title, description, link, width, height, onClose }) {
  const { gl, camera } = useThree();
  const meshRef = useRef();

  useEffect(() => {
    if (camera) {
      camera.position.set(0, 0, 15);
      camera.lookAt(0, 0, 0);
    }
  }, [camera]);

  useFrame(() => {
    if (meshRef.current && camera) {
      meshRef.current.lookAt(camera.position);
    }
  });
  const texture = useMemo(() => {
    const canvas = document.createElement("canvas");
    canvas.width = 1024;
    canvas.height = 1024;
    const context = canvas.getContext("2d");
    context.fillStyle = "white";
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.font = "bold 40px Arial";
    context.fillStyle = "black";
    context.textAlign = "center";
    context.fillText(title, canvas.width / 2, 100);
    context.font = "24px Arial";
    const words = description.split(" ");
    let line = "";
    let y = 200;
    for (let word of words) {
      const testLine = line + word + " ";
      const metrics = context.measureText(testLine);
      if (metrics.width > canvas.width - 100) {
        context.fillText(line, canvas.width / 2, y);
        line = word + " ";
        y += 30;
      } else {
        line = testLine;
      }
    }
    context.fillText(line, canvas.width / 2, y);
    context.fillStyle = "blue";
    context.fillText("Learn More", canvas.width / 2, y + 60);
    return new THREE.CanvasTexture(canvas);
  }, [title, description, link]);

  const { scale } = useSpring({
    scale: [width * 2, height * 2, 1],
    config: config.wobbly,
  });

  return (
    <animated.mesh ref={meshRef} scale={scale} position={[0, 0, 1]}>
      <planeGeometry args={[1, 1]} />
      <meshBasicMaterial map={texture} side={THREE.DoubleSide} />
      <Html position={[0.45, 0.45, 0.01]}>
        <button
          onClick={onClose}
          style={{ fontSize: "20px", padding: "5px 10px" }}
        >
          ×
        </button>
      </Html>
    </animated.mesh>
  );
}

/**
 * Card component
 * Renders an individual card in the grid
 */
function Card({ title, color, description, link, width, height, index, x, y }) {
  const [hovered, setHovered] = useState(false);
  const { expandedIndex, setExpandedIndex } = useStore();
  const isExpanded = index === expandedIndex;

  const { scale, position } = useSpring({
    scale: isExpanded ? [2, 2, 1] : [1, 1, 1],
    position: isExpanded ? [0, 0, 1] : [x, y, 0],
    config: config.wobbly,
  });

  const handleClick = (e) => {
    e.stopPropagation();
    setExpandedIndex(isExpanded ? null : index);
  };

  const handleCloseClick = (e) => {
    e.stopPropagation();
    setExpandedIndex(null);
  };

  return (
    <>
      <animated.group scale={scale} position={position}>
        <mesh
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
          onClick={handleClick}
        >
          <boxGeometry args={[width, height, 0.05]} />
          <meshStandardMaterial color={hovered ? "#ffffff" : color} />
        </mesh>
        <Text
          position={[0, 0, 0.026]}
          fontSize={width * 0.1}
          color="#000000"
          anchorX="center"
          anchorY="middle"
        >
          {title}
        </Text>
      </animated.group>
      {isExpanded && (
        <ExpandedView
          title={title}
          description={description}
          link={link}
          width={width}
          height={height}
          onClose={handleCloseClick}
        />
      )}
    </>
  );
}

/**
 * ClickOutsideHandler component
 * Handles clicks outside of expanded cards to close them
 */
function ClickOutsideHandler({ children }) {
  const { setExpandedIndex } = useStore();
  const { camera, raycaster, scene } = useThree();
  const ref = useRef();

  useEffect(() => {
    const handleClick = (event) => {
      if (ref.current) {
        raycaster.setFromCamera(
          {
            x: (event.clientX / window.innerWidth) * 2 - 1,
            y: -(event.clientY / window.innerHeight) * 2 + 1,
          },
          camera
        );
        const intersects = raycaster.intersectObjects(scene.children, true);
        if (intersects.length > 0) {
          const clickedMesh = intersects[0].object;
          if (!ref.current.getObjectById(clickedMesh.id)) {
            setExpandedIndex(null);
          }
        } else {
          setExpandedIndex(null);
        }
      }
    };

    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, [camera, raycaster, scene, setExpandedIndex]);

  return <group ref={ref}>{children}</group>;
}

/**
 * Pagination component
 * Renders pagination controls for the grid
 */
function Pagination({ currentPage, totalPages, onPageChange }) {
  return (
    <Html position={[0, -5, 0]}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          style={{ margin: "0 10px", padding: "5px 10px", cursor: "pointer" }}
        >
          Previous
        </button>
        <span>{`Page ${currentPage} of ${totalPages}`}</span>
        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          style={{ margin: "0 10px", padding: "5px 10px", cursor: "pointer" }}
        >
          Next
        </button>
      </div>
    </Html>
  );
}

/**
 * CaseStudyGrid component
 * Main component that renders the 3D grid of case study cards
 */
function CaseStudyGrid() {
  const {
    width: viewportWidth,
    height: viewportHeight,
    deviceType,
  } = useViewport();
  const { items, expandedIndex } = useStore();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  /**
   * Calculates the layout of the grid based on viewport size and device type
   */
  const calculateLayout = useCallback(() => {
    const aspectRatio = viewportWidth / viewportHeight;
    const gridHeight = 10;
    const gridWidth = gridHeight * aspectRatio;

    let cardsPerRow, spacing;
    if (deviceType === "mobile") {
      cardsPerRow = 1;
      spacing = gridWidth * 0.05;
    } else if (deviceType === "tablet") {
      cardsPerRow = 2;
      spacing = gridWidth * 0.03;
    } else {
      cardsPerRow = 3;
      spacing = gridWidth * 0.02;
    }

    const cardWidth = (gridWidth - spacing * (cardsPerRow + 1)) / cardsPerRow;
    const numRows = Math.ceil(itemsPerPage / cardsPerRow);
    const maxAvailableHeight = (gridHeight - spacing * (numRows + 1)) / numRows;
    const cardHeight = Math.min(cardWidth * 1.4, maxAvailableHeight);

    return {
      gridWidth,
      gridHeight,
      cardsPerRow,
      cardWidth,
      cardHeight,
      spacing,
    };
  }, [viewportWidth, viewportHeight, deviceType]);

  const [layout, setLayout] = useState(calculateLayout);

  useEffect(() => {
    setLayout(calculateLayout());
  }, [calculateLayout]);

  const totalPages = Math.ceil(items.length / itemsPerPage);
  const currentItems = useMemo(() => {
    return items.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
  }, [items, currentPage, itemsPerPage]);

  const handlePageChange = useCallback((newPage) => {
    setCurrentPage(newPage);
  }, []);

  return (
    <StyledCanvas>
      <Canvas camera={{ position: [0, 0, 15], fov: 50 }}>
        <ambientLight intensity={0.5} />
        <pointLight position={[10, 10, 10]} />
        <ClickOutsideHandler>
          <group>
            {currentItems.map((item, index) => {
              const row = Math.floor(index / layout.cardsPerRow);
              const col = index % layout.cardsPerRow;
              const x =
                -layout.gridWidth / 2 +
                layout.spacing +
                (layout.cardWidth + layout.spacing) * col +
                layout.cardWidth / 2;
              const y =
                layout.gridHeight / 2 -
                layout.spacing -
                (layout.cardHeight + layout.spacing) * row -
                layout.cardHeight / 2;

              return (
                <Card
                  key={index}
                  title={item.title}
                  color={item.color}
                  description={item.description}
                  link={item.link}
                  width={layout.cardWidth}
                  height={layout.cardHeight}
                  index={index + (currentPage - 1) * itemsPerPage}
                  x={x}
                  y={y}
                />
              );
            })}
          </group>
        </ClickOutsideHandler>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
        <OrbitControls />
      </Canvas>
    </StyledCanvas>
  );
}
export default CaseStudyGrid;

/**
 * Usage example:
 *
 * import CaseStudyGrid from './components/CaseStudyGrid';
 *
 * function App() {
 *   return (
 *     <div className="App">
 *       <CaseStudyGrid />
 *     </div>
 *   );
 * }
 *
 */
