// ImageService.js

import axios from "axios";

const API_URL = "/api/images/";

export const fetchImages = async (category = null, subcategory = null) => {
  try {
    let url = API_URL;
    const params = new URLSearchParams();
    if (category) params.append("category", category);
    if (subcategory) params.append("subcategory", subcategory);
    params.append("ordering", "order"); // Add this line to sort by the 'order' field
    if (params.toString()) url += `?${params.toString()}`;

    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error("Error fetching images:", error);
    throw error;
  }
};

export const fetchImageBySlug = async (slug) => {
  try {
    const response = await axios.get(`${API_URL}${slug}/`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching image with slug ${slug}:`, error);
    throw error;
  }
};
