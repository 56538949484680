// components/CaseStudy/Challenges/Challenges.jsx

import React from "react";
import styled from "styled-components";
import Markdown from "react-markdown";
import { BentoItem } from "../../BentoBox/BentoBox";
import { media } from "../../../styles/breakpoints";

const ChallengesItem = styled(BentoItem)`
  grid-column: 1 / -1;
  grid-row: auto;
  align-self: start;
  height: fit-content;
  grid-row: 3 / span 2;

  ${media.up("desktop")`
    grid-column: 2 / span 4;
  `}

  ${media.between("laptopS", "desktop")`
    grid-column: span 4;
  `}

  ${media.between("tabletM", "laptopS")`
    grid-column: span 3;
  `}

  ${media.between("mobileL", "tabletM")`
    grid-column: 2 / span 2;
    margin-right: 0.5rem;
    grid-row: 4;
  `}

  ${media.down("mobileL")`
    grid-column: 2 / span 2;
    margin-right: 0.5rem;
    margin-top: 2.5rem; 
    grid-row: 4;
  `}

  h2 {
    position: relative;
    z-index: 10;
  }
`;

const ChallengesContent = styled(BentoItem)`
  border-radius: 10px;
  padding: 0.5rem 2rem 0.75rem 1.5rem;
  transition: all 0.3s ease;
  position: relative;

  ${media.up("desktop")`
    padding: 0.5rem 1rem;
  `}

  ${media.between("laptopS", "desktop")`
    padding: 0.5rem 1rem;
  `}

  ${media.between("tabletM", "laptopS")`
    padding: 0 0.5rem ;
  `}

  ${media.down("tabletM")`
    padding: 0.5rem 1.25rem 0.5rem 1rem;
  `}

  &:hover {
    background-color: rgba(255, 255, 255, 0.75);
    transform: translateY(-5px);
  }
`;

const ChallengeList = styled.ol`
  list-style-type: none;
  padding-top: 0.5rem;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  counter-reset: challenge-counter;

  ${media.up("desktop")`
    gap: 0.25rem;
    padding-top: 0.5rem;
  `}

  ${media.between("laptopS", "desktop")`
    gap: 0.25rem;
    padding-top: 0.5rem;
  `}

  ${media.between("tabletM", "laptopS")`
    gap: 0rem;
    padding-top: 0.5rem;

    p {
      margin-bottom: 0.5rem;
    }
  `}
  
  ${media.down("tabletM")`
    gap: 0.25rem;
    padding-top: 0.5rem;
  `}
`;

const ChallengeEntry = styled.li`
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
  counter-increment: challenge-counter;

  ${media.up("desktop")`
    padding: 0.5rem 1rem;
  `}

  ${media.between("laptopS", "desktop")`
    padding: 0.5rem 1rem;
  `}

  ${media.between("tabletM", "laptopS")`
    padding: 0 0.25rem 0.5rem 0.25rem;
  `}
  
  ${media.down("tabletM")`
    padding: 0;
  `}

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    transform: translateX(5px);
  }

  &::before {
    content: counter(challenge-counter) ". ";
    font-weight: bold;
    color: ${(props) => props.theme.primaryColor || "#fff"};
  }
`;

const Challenges = ({ challenges }) => {
  if (!challenges || challenges.length === 0) return null;

  return (
    <ChallengesItem disableBackground={true}>
      <h2>Challenges</h2>
      <ChallengesContent>
        <ChallengeList>
          {challenges.map((challenge) => (
            <ChallengeEntry key={challenge.id}>
              <Markdown>{challenge.description}</Markdown>
            </ChallengeEntry>
          ))}
        </ChallengeList>
      </ChallengesContent>
    </ChallengesItem>
  );
};

export default Challenges;
