import React, { useState, useEffect, Suspense, useRef } from "react";
import styled from "styled-components";
import { fetchExperiences } from "../../services/ExperienceService";
import ResumeDetails from "./ResumeDetails";
import ResumeCanvas from "./ResumeCanvas";
import ResumeSlideOut from "./ResumeSlideOut";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import useStore from "../../stores/store";

const ResumeContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  grid-column: 1/-1;
  grid-row: 1/-1;
`;

function Resume() {
  const {
    theme,
    isCollapsed,
    toggleCollapse,
    selectedItem: selectedExperience,
    setSelectedItem: setSelectedExperience,
    selectedTab,
    setSelectedTab,
    resetSlideOutState,
    updateSlideOut,
  } = useStore();

  const [experiences, setExperiences] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEmptyState, setIsEmptyState] = useState(true);
  const navigate = useNavigate();

  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    aspectRatio: window.innerWidth / window.innerHeight,
  });
  const isMounted = useRef(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const startTime = Date.now();
      try {
        const data = await fetchExperiences();
        const sortedData = data.sort((a, b) => a.order - b.order);
        setExperiences(sortedData);

        // No initial selection
        setSelectedExperience(null);
        setIsEmptyState(true);

        const elapsedTime = Date.now() - startTime;
        const minimumLoadingTime = 500; // 500ms minimum loading time
        if (elapsedTime < minimumLoadingTime) {
          setTimeout(
            () => setIsLoading(false),
            minimumLoadingTime - elapsedTime
          );
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching experiences:", error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
        aspectRatio: window.innerWidth / window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, []);

  useEffect(() => {
    if (selectedExperience) {
      navigate(
        `/experience/${selectedExperience.slug}/${selectedTab.toLowerCase()}`
      );
    } else {
      navigate(`/experience/`);
    }
  }, [selectedExperience, selectedTab, navigate]);

  const handleExperienceSelect = (experienceId) => {
    const selected = experiences.find((e) => e.id === experienceId);
    if (selected) {
      if (selectedExperience && selected.id === selectedExperience.id) {
        toggleCollapse();
      } else {
        setSelectedExperience(selected);
        setIsEmptyState(false);
        setSelectedTab("Highlights");
        updateSlideOut(true, false); // Open the slide-out
      }
    }
  };

  const handleBackClick = () => {
    setIsEmptyState(true);
    resetSlideOutState();
  };

  const handleTabChange = (newTab) => {
    setSelectedTab(newTab);
  };

  useEffect(() => {
    const handleOrientationChange = () => {
      const isLandscape = window.innerWidth > window.innerHeight;
      if (isLandscape) {
        updateSlideOut(true, false); // Expand the slide-out in landscape mode
      }
      // We don't need to force a re-render of ResumeDetails anymore
      // as the state changes will trigger re-renders automatically
    };

    window.addEventListener("resize", handleOrientationChange);
    window.addEventListener("orientationchange", handleOrientationChange);

    // Initial check
    handleOrientationChange();

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, [updateSlideOut]);

  return (
    <ResumeContainer>
      <ResumeSlideOut
        experiences={experiences}
        onExperienceSelect={handleExperienceSelect}
        onBackClick={handleBackClick}
        isLoading={isLoading}
        onTabChange={handleTabChange}
      />
      <ResumeDetails
        key={selectedExperience ? selectedExperience.id : "empty"}
        experience={selectedExperience}
        isEmptyState={isEmptyState}
        isCollapsed={isCollapsed}
        selectedTab={selectedTab}
        onTabChange={handleTabChange}
        isLoading={isLoading}
      />
      <Suspense
        fallback={<Loader isLoading={true} text="Loading 3D Scene..." />}
      >
        <ResumeCanvas
          experienceId={selectedExperience ? selectedExperience.id : "default"}
          isEmptyState={isEmptyState}
          isLoading={isLoading}
        />
      </Suspense>
    </ResumeContainer>
  );
}

export default Resume;
