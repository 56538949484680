import React from "react";
import SVGIcon from "../SVGIcon";
import styled from "styled-components";
import { hexToRgba } from "../../utils/hexToRGBA";
import useStore from "../../stores/store";

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.color || "inherit"};
  width: 80%;
  height: 80%;

  g path.bg,
  path.bg,
  circle.bg,
  polygon.bg {
    fill: ${({ theme, isActive, isHovered }) =>
      isActive
        ? theme.navBg
        : isHovered
          ? theme.accentColorPrimary
          : theme.navBg};
  }

  g path.primary,
  path.primary,
  circle.primary,
  polygon.primary {
    fill: ${({ theme, isActive, isHovered }) =>
      isActive
        ? theme.primaryColor
        : isHovered
          ? theme.tertiaryColor
          : theme.primaryColor};
  }

  g path.primaryTint,
  polygon.primaryTint {
    fill: ${({ theme }) => hexToRgba(theme.iconColorTertiary, 0.3)};
  }

  g path.secondary,
  path.secondary,
  circle.secondary,
  polygon.secondary {
    fill: ${({ theme }) => hexToRgba(theme.iconColorTertiary, 0.15)};
  }

  g path.secondaryTint,
  polygon.secondaryTint {
    fill: ${({ theme }) => hexToRgba(theme.iconColorTertiary, 0.4)};
  }

  .logo {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }
`;

function ResumeLogo({
  logoId,
  spriteSheetName,
  isActive,
  isHovered,
  color,
  $viewport,
}) {
  const { theme } = useStore();

  return (
    <LogoWrapper
      color={color}
      theme={theme}
      isActive={isActive}
      isHovered={isHovered}
      $viewport={$viewport}
    >
      <SVGIcon
        iconId={logoId}
        spriteSheetName={spriteSheetName}
        additionalClasses={["logo"]}
      />
    </LogoWrapper>
  );
}

export default ResumeLogo;
