import React from "react";
import styled from "styled-components";
import { BentoItem } from "../../BentoBox/BentoBox";
import useStore from "../../../stores/store";
import { media } from "../../../styles/breakpoints";

const ScopeItem = styled(BentoItem)`
  grid-column: 1 / -1;
  grid-row: auto;
  align-self: start;
  height: fit-content;
  grid-row: 2 / span 2;

  ${media.up("desktop")`
    grid-column: 6 / span 2;
    grid-row: 2 / span 2;
  `}

  ${media.between("laptopS", "desktop")`
    grid-column: span 2;
    grid-row: 2 / span 2;
  `}

  ${media.between("tabletM", "laptopS")`
    grid-column: span 3;
  `}

  ${media.between("mobileL", "tabletM")`
    grid-column: 2 / span 2;
    margin-right: 0.5rem;
    grid-row: 3;
  `}

  ${media.down("mobileL")`
    grid-column: 2 / span 2;
    margin-right: 0.5rem;
    margin-top: 2.5rem;
    grid-row: 3;
  `}

  h2 {
    position: relative;
    z-index: 10;
  }
`;

const ScopeContent = styled(BentoItem)`
  border-radius: 10px;
  padding: 0.5rem 1.5rem 2rem 1.5rem;
  transition: all 0.3s ease;
  position: relative;

  ${media.up("desktop")`
    padding: 0.5rem 1.5rem 2rem 1.5rem;
  `}

  ${media.between("laptopS", "desktop")`
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  `}

  ${media.between("tabletM", "laptopS")`
    padding: 0 1rem 1rem 1rem;
  `}

  ${media.down("tabletM")`
    padding: 0.25rem 0.75rem 1rem 1rem;
  `}

  &:hover {
    background-color: rgba(255, 255, 255, 0.75);
    transform: translateY(-5px);
  }
`;

const ScopeList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  ${media.up("desktop")`
    gap: 0.25rem;
  `}

  ${media.between("laptopS", "desktop")`
    gap: 0.25rem;
    padding-top: 1rem;
  `}

  ${media.between("tabletM", "laptopS")`
    gap: 0rem;
    padding-top: 0rem;
  `}

  ${media.down("tabletM")`
    gap: 0.125rem;
    padding-top: 0;
  `}
`;

const ScopeEntry = styled.li`
  border-radius: 10px;
  padding-top: 1rem;
  transition: all 0.3s ease;
  color: ${({ theme }) => theme.textColorPrimary || "#000"};

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    transform: translateX(5px);
  }

  ${media.up("desktop")`
    padding-top: 1rem;
  `}

  ${media.between("laptopS", "desktop")`
    padding-top: 1rem;
  `}

  ${media.between("tabletM", "laptopS")`
    padding-top: 0.5rem;
  `}

  ${media.down("tabletM")`
    padding-top: 0.5rem;
  `}
`;

const Scope = ({ scope }) => {
  const { theme } = useStore();

  if (!scope || scope.length === 0) return null;

  // Sort the scope items based on the order field
  const sortedScope = [...scope].sort((a, b) => a.order - b.order);

  return (
    <ScopeItem disableBackground={true}>
      <h2>Scope</h2>
      <ScopeContent>
        <ScopeList>
          {sortedScope.map((item) => (
            <ScopeEntry key={item.id} theme={theme}>
              {item.item}
            </ScopeEntry>
          ))}
        </ScopeList>
      </ScopeContent>
    </ScopeItem>
  );
};

export default Scope;
