import React, { useState } from "react";
import styled from "styled-components";
import { useViewport } from "../../context/ViewportContext";
import { breakpoints } from "../../styles/breakpoints";
import { typography } from "../../styles/abstracts/typography";
import useStore from "../../stores/store";

const ContactContainer = styled.div`
  display: grid;
  grid-template-columns: 2.75rem 1fr;
  grid-template-rows: 2.75rem 1fr;

  ${({ $viewport }) => {
    if (!$viewport) return "";
    const { width, height } = $viewport;
    const isLandscape = width > height;

    if (width <= breakpoints.mobileS) {
      return `
        grid-template-rows: 3rem 1fr;
        grid-template-columns: 3rem 1fr;
        padding: 0;
      `;
    }
    if (width <= breakpoints.mobileM) {
      return `
        grid-template-rows: ${isLandscape ? "2.75rem 1fr" : "3rem 1fr"};
        grid-template-columns: 3rem 1fr;
        padding: 0;
      `;
    }
    if (width <= breakpoints.mobileL) {
      return `
        grid-template-rows: ${isLandscape ? "2.75rem 1fr" : "3rem 1fr"};
        grid-template-columns: 3rem 1fr;
        padding: 0;
      `;
    }
    if (width <= breakpoints.tabletS) {
      return `
        grid-template-rows: ${isLandscape ? `2.75rem 1fr` : `3.5rem 1fr`};
        grid-template-columns: 3.5rem 1fr;
        padding: 0;
      `;
    }
    if (width <= breakpoints.tabletM) {
      return `
        grid-template-rows: ${isLandscape ? `2.75rem 1fr` : `3.5rem 1fr`};
        grid-template-columns: ${isLandscape ? `2.75rem 1fr` : `3.5rem 1fr`};
        padding: 0;
      `;
    }
    if (width <= breakpoints.tabletL) {
      return `
        grid-template-rows: ${isLandscape ? `2.75rem 1fr` : `3.5rem 1fr`};
        grid-template-columns: ${isLandscape ? `2.75rem 1fr` : `3.5rem 1fr`};
        padding: 0;
      `;
    }

    if (width <= breakpoints.laptopS) {
      return `
        grid-template-rows: 5.25rem 1fr;
        grid-template-columns: 5.25rem 1fr 2.5rem;
        padding: 0;
        grid-column: 2/-1;
      `;
    }
    if (width <= breakpoints.laptopL) {
      return `
        grid-template-rows: 5.25rem 1fr;
        grid-template-columns: 5.25rem 1fr 5.25rem;
        padding: 0;
        grid-column: 2/-1;
      `;
    }
    if (width <= breakpoints.desktop) {
      return `
        grid-template-rows: 5.25rem 1fr 5.25rem;
        grid-template-columns: 5.25rem 1fr 6.75rem;
      `;
    }
    if (width <= breakpoints.desktopL) {
      return `
        grid-template-rows: 5.25rem 1fr 5.25rem;
        grid-template-columns: 5.25rem 1fr 6.75rem;
      `;
    }
  }}
`;

const FormContainer = styled.div`
  grid-column: 2;
  grid-row: 2;

  padding: 0.5rem;

  pointer-events: auto;
`;

const Form = styled.div`
  position: relative;
  height: 80%;
  width: 80%;
  margin: 2.5rem auto;

  ${({ $viewport }) => {
    if (!$viewport) return "";
    const { width, height, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
        margin: 0.25rem;
        width: 98%;
      `;
    }
    if (width <= breakpoints.mobileM) {
      return `
        margin: 0.25rem;
        width: 98%;
      `;
    }
    if (width <= breakpoints.mobileL) {
      return `
        margin: 0.25rem;
        width: 98%;    
      `;
    }
    if (width <= breakpoints.tabletS) {
      return `
        margin: 0.25rem;
        width: 98%;  
      `;
    }
    if (width <= breakpoints.tabletM) {
      return `
        margin: 0.25rem;
        width: 99%;  
      `;
    }
    if (width <= breakpoints.tabletL) {
      return `
        margin: 0.25rem;
        width: 99%; 
      `;
    }
    if (width <= breakpoints.laptopS) {
      return `
      `;
    }
    if (width <= breakpoints.laptopL) {
      return `
      `;
    }
    if (width <= breakpoints.desktop) {
      return `
      `;
    }
    if (width <= breakpoints.desktopL) {
      return `
      `;
    }
  }}
`;

const Heading = styled.h1`
  color: ${({ theme }) => theme.textColorPrimary};
  grid-row: 1;
  grid-column: 2;

  align-self: center;
  justify-self: center;

  padding: 0 0 0 0.5rem;
  margin: 0;

  ${({ $viewport }) => {
    if (!$viewport) return "";
    const { width, height } = $viewport;
    const isLandscape = width > height;

    if (width <= breakpoints.mobileS) {
      return `
        padding: 0 0 0 0.75rem;
        justify-self: start;
        font-size: ${typography.fontSizeMedium}
      `;
    }
    if (width <= breakpoints.mobileM) {
      return `
        padding: 0 0 0 0.75rem;
        justify-self: start;
        font-size: ${typography.fontSizeMedium}
      `;
    }
    if (width <= breakpoints.mobileL) {
      return `
        padding: 0 0 0 0.75rem;
        justify-self: start;
        font-size: ${typography.fontSizeMedium};    
      `;
    }
    if (width <= breakpoints.tabletS) {
      return `
        padding: 0 0 0 0.75rem;
        justify-self: start;
        font-size: ${typography.fontSizeLarge};
     `;
    }
    if (width <= breakpoints.tabletM) {
      return `
        padding: 0 0 0 0.75rem;
        justify-self: start;
        font-size: ${typography.fontSizeLarge};
      `;
    }
    if (width <= breakpoints.tabletL) {
      return `
        ${
          isLandscape
            ? `
          padding: 0 0 0 0.75rem;
          justify-self: start;
          font-size: ${typography.fontSizeLarge};
        `
            : `
          padding: 0 0 0 0.75rem;
          justify-self: start;
          font-size: ${typography.fontSizeLarge};
        `
        }

      `;
    }
    if (width <= breakpoints.laptopS) {
      return `
      `;
    }
    if (width <= breakpoints.laptopL) {
      return `
      `;
    }
    if (width <= breakpoints.desktop) {
      return `
      `;
    }
    if (width <= breakpoints.desktopL) {
      return `
      `;
    }
  }}
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ $viewport }) => {
    if (!$viewport) return "";
    const { width, height, isLandscape } = $viewport;

    if (isLandscape) {
      return `
        flex-direction: row;
        gap: 1rem;
      `;
    }
    return `
      flex-direstion: column;
      gap: 1rem;
      `;
  }}
`;

const Input = styled.input`
  padding: 0.75rem;
  border: none;
  border-radius: 12px;
  font-size: 1rem;
  border: 2px solid ${({ theme }) => theme.bodyBgPrimary};
  background-color: ${({ theme }) => theme.bodyBgPrimary};
  color: ${({ theme }) => theme.textColorPrimary};
  transition:
    border-color 0.3s ease,
    box-shadow 0.3s ease;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.primaryColor};
    box-shadow: 0 0 0 3px ${({ theme }) => `${theme.primaryColor}33`};
  }

  &::placeholder {
    color: ${({ theme }) => theme.placeholderColor};
  }

  ${({ $viewport }) => {
    const { width, height, isLandscape } = $viewport;
    if (!isLandscape) return "";
    if (width <= breakpoints.tabletL) return "width: 100%";
    if (width <= breakpoints.desktopL) {
      if (isLandscape) {
        return "width: 100%";
      }
    }
    return "";
  }}
`;

const Textarea = styled.textarea`
  padding: 0.75rem;
  border-radius: 12px;
  font-size: 1.2rem;
  border: 2px solid ${({ theme }) => theme.bodyBgPrimary};
  background-color: ${({ theme }) => theme.bodyBgPrimary};
  color: ${({ theme }) => theme.textColorPrimary};
  resize: vertical;
  min-height: 100px;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.primaryColor};
  }

  &::placeholder {
    color: ${({ theme }) => theme.placeholderColor};
  }
`;

const Button = styled.button`
  padding: 1.25rem 2.5rem;
  background-color: ${({ theme }) => theme.primaryColor};
  color: #ffffff;
  border: none;
  border-radius: 12px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.accentColorPrimary};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.disabledColor};
    cursor: not-allowed;
  }
`;

const MessageDiv = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 12px;
  background-color: ${({ theme, isSuccess, isError }) =>
    isSuccess
      ? theme.successBackground
      : isError
        ? theme.errorBackground
        : "transparent"};
  color: ${({ theme, isSuccess, isError }) =>
    isSuccess
      ? theme.successColor
      : isError
        ? theme.errorColor
        : theme.textColor};
  text-align: center;
`;

function Contact() {
  const { theme } = useStore();
  const viewport = useViewport();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [submissionStatus, setSubmissionStatus] = useState({
    isSubmitting: false,
    isSuccess: false,
    isError: false,
    message: "",
  });

  const [h2Content, setH2Content] = useState("[ Get in Touch ]");

  // Basic email validation
  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/
    );
  };

  const validateForm = () => {
    if (!formData.name || !formData.email || !formData.message) {
      setSubmissionStatus({
        ...submissionStatus,
        message: "Please fill in all fields.",
      });
      return false;
    }
    if (!validateEmail(formData.email)) {
      setSubmissionStatus({
        ...submissionStatus,
        message: "Please enter a valid email address.",
      });
      return false;
    }
    return true;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setSubmissionStatus({ ...submissionStatus, message: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setSubmissionStatus({ ...submissionStatus, isSubmitting: true });
    setH2Content("Submitting...");

    try {
      const response = await fetch("https://usebasin.com/f/2529bbac89fc", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSubmissionStatus({
          isSubmitting: false,
          isSuccess: true,
          isError: false,
          message:
            '<p class="history--testimonial--quote">Thank you for reaching out! 🚀 <br> I will be in touch within 48 hours.</p>',
        });
        setH2Content("Thank You!");
        setFormData({ name: "", email: "", message: "" });
      } else {
        throw new Error("Form submission failed");
      }
    } catch (error) {
      setSubmissionStatus({
        isSubmitting: false,
        isSuccess: false,
        isError: true,
        message: "Oops, something went wrong 🚨 <br> Please try again later.",
      });
      setH2Content("🤖 Error!!! 🤖");
    }
  };

  return (
    <ContactContainer $viewport={viewport}>
      <Heading theme={theme} $viewport={viewport}>
        {h2Content}
      </Heading>
      <FormContainer $viewport={viewport}>
        <Form
          theme={theme}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          $viewport={viewport}
        >
          {submissionStatus.message && (
            <MessageDiv
              theme={theme}
              isSuccess={submissionStatus.isSuccess}
              isError={submissionStatus.isError}
              dangerouslySetInnerHTML={{ __html: submissionStatus.message }}
            />
          )}
          {!submissionStatus.isSuccess && (
            <StyledForm id="contact-form" onSubmit={handleSubmit}>
              <FormField $viewport={viewport}>
                <Input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  theme={theme}
                  placeholder="Name"
                  $viewport={viewport}
                />
                <Input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  theme={theme}
                  placeholder="Email"
                  $viewport={viewport}
                />
              </FormField>
              <FormField>
                <Textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  theme={theme}
                  placeholder="Message"
                />
              </FormField>
              <Button
                type="submit"
                disabled={submissionStatus.isSubmitting}
                theme={theme}
              >
                {submissionStatus.isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </StyledForm>
          )}
        </Form>
      </FormContainer>
    </ContactContainer>
  );
}

export default Contact;
