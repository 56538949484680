/**
 * createThemeSlice.js
 *
 * This file defines a Zustand slice for managing the application's theme state.
 * It provides functionality to set, toggle, and initialize themes.
 */

import { themes } from "../../styles/abstracts/themes";

/**
 * Creates a theme slice for the Zustand store.
 *
 * @param {Function} set - Zustand's set function to update the store
 * @param {Function} get - Zustand's get function to access the store state
 * @returns {Object} The theme slice with state and actions
 */
const createThemeSlice = (set, get) => ({
  /** @type {Object} The current theme object */
  theme: themes.light,

  /** @type {string} The name of the current theme */
  themeName: "light",

  /**
   * Sets the theme to a new theme
   * @param {string} newThemeName - The name of the new theme to set
   */
  setTheme: (newThemeName) => {
    const newTheme = themes[newThemeName];
    document.body.className = newThemeName;
    localStorage.setItem("themeName", newThemeName);
    set({ theme: newTheme, themeName: newThemeName });
  },

  /**
   * Toggles between light and dark themes
   */
  toggleTheme: () => {
    const currentThemeName = get().themeName;
    const newThemeName = currentThemeName === "light" ? "dark" : "light";
    const newTheme = themes[newThemeName];
    document.body.className = newThemeName;
    localStorage.setItem("themeName", newThemeName);
    set({ theme: newTheme, themeName: newThemeName });
  },

  /**
   * Initializes the theme from localStorage or defaults to light theme
   */
  initTheme: () => {
    return new Promise((resolve) => {
      const savedThemeName = localStorage.getItem("themeName") || "light";
      const savedTheme = themes[savedThemeName];
      document.body.className = savedThemeName;
      set({ theme: savedTheme, themeName: savedThemeName });
      resolve();
    });
  },
});

export default createThemeSlice;

/**
 * Usage example:
 *
 * import create from 'zustand';
 * import createThemeSlice from './createThemeSlice';
 *
 * const useStore = create((set, get) => ({
 *   ...createThemeSlice(set, get),
 *   // other slices...
 * }));
 *
 * // In a component:
 * const { theme, toggleTheme } = useStore();
 *
 * // Use theme object for styling
 * const style = { backgroundColor: theme.backgroundColor };
 *
 * // Toggle theme
 * <button onClick={toggleTheme}>Toggle Theme</button>
 */
