// styles/breakpoints.js

import { css } from "styled-components";

// This file contains utilities for managing breakpoints and creating responsive designs.
// It provides a set of predefined breakpoints, media queries, and helper functions
// to make working with responsive layouts easier and more consistent.

// Define width breakpoints for different device sizes

/* global breakpoints */
export const breakpoints = {
  mobileS: 360,
  mobileM: 390,
  mobileL: 435,
  tabletS: 562,
  tabletM: 778,
  tabletL: 950,
  laptopS: 1024,
  laptopM: 1280,
  laptopL: 1440,
  desktop: 1920,
  desktopL: 3000,
};

export const heightBreakpoints = {
  heightMIN: 390,
  heightXXS: 450,
  heightXS: 600,
  heightS: 700,
  heightM: 800,
  heightL: 900,
  heightXL: 1000,
  heightXXL: 1150,
  heightMAX: 3000,
};

// Helper function to create media queries for min-width or max-width
// It takes 'min' or 'max' as the first argument and the breakpoints object as the second
// Returns an object with media queries for each breakpoint
const createMediaQuery = (minMax, breakpoints) => {
  return Object.entries(breakpoints).reduce((acc, [key, value]) => {
    acc[key] = `(${minMax}-width: ${minMax === "max" ? value - 1 : value}px)`;
    return acc;
  }, {});
};

// Create min-width media queries for each breakpoint
// Usage example in styled-components:
// const Component = styled.div`
//   ${minWidth.tabletM} {
//     // Styles for screens wider than tabletM
//   }
// `;
export const minWidth = createMediaQuery("min", breakpoints);

// Create max-width media queries for each breakpoint
// Usage example in styled-components:
// const Component = styled.div`
//   ${maxWidth.laptopS} {
//     // Styles for screens narrower than laptopS
//   }
// `;
export const maxWidth = createMediaQuery("max", breakpoints);

// Define min-height media queries for small, medium, and large heights
export const minHeight = {
  xs: `(min-height: ${breakpoints.heightXS}px)`,
  sm: `(min-height: ${breakpoints.heightS}px)`,
  md: `(min-height: ${breakpoints.heightM}px)`,
  lg: `(min-height: ${breakpoints.heightL}px)`,
  xl: `(min-height: ${breakpoints.heightXL}px)`, // Added XL
};

export const maxHeight = {
  xs: `(max-height: ${breakpoints.heightXS - 1}px)`,
  sm: `(max-height: ${breakpoints.heightS - 1}px)`,
  md: `(max-height: ${breakpoints.heightM - 1}px)`,
  lg: `(max-height: ${breakpoints.heightL - 1}px)`,
  xl: `(max-height: ${breakpoints.heightXL - 1}px)`, // Added XL
};

// Define common aspect ratios
export const aspectRatios = {
  ultraWide: 21 / 9,
  wide: 16 / 9,
  standard: 4 / 3,
  square: 1,
  tall: 3 / 4,
  ultraTall: 9 / 16,
};

// Helper function to calculate aspect ratio given width and height
export const getAspectRatio = (width, height) => width / height;

// Helper function to create a media query for a specific aspect ratio
// 'type' parameter defaults to "max" if not provided
const createAspectRatioQuery = (ratio, type = "max") => {
  return `@media (${type}-aspect-ratio: ${Math.floor(ratio * 100)}/100)`;
};

// Create media queries for different aspect ratios
// Usage example in styled-components:
// const Component = styled.div`
//   ${aspectRatioQueries.wide} {
//     // Styles for wide aspect ratios
//   }
// `;
export const aspectRatioQueries = {
  ultraWide: createAspectRatioQuery(aspectRatios.ultraWide, "min"),
  wide: createAspectRatioQuery(aspectRatios.wide, "min"),
  standard: createAspectRatioQuery(aspectRatios.standard, "min"),
  square: createAspectRatioQuery(aspectRatios.square),
  tall: createAspectRatioQuery(aspectRatios.tall, "max"),
  ultraTall: createAspectRatioQuery(aspectRatios.ultraTall, "max"),
};

// Define complex media queries combining multiple conditions
export const combinedQueries = {
  mobileLandscape: `@media ${maxWidth.sm} and (max-height: ${breakpoints.sm}px) and (orientation: landscape)`,
  tabletLandscape: `@media ${minWidth.md} and ${maxWidth.lg} and (orientation: landscape)`,
  desktopWide: `@media ${minWidth.lg} and ${aspectRatioQueries.wide}`,
};

// Helper functions for creating media queries
// These make it easy to create responsive styles in components
// Usage example in styled-components:
// const Component = styled.div`
//   ${media.up('tabletM')} {
//     // Styles for screens wider than tabletM
//   }
//   ${media.down('laptopS')} {
//     // Styles for screens narrower than laptopS
//   }
//   ${media.between('tabletM', 'laptopS')} {
//     // Styles for screens between tabletM and laptopS
//   }
// `;
export const media = {
  up:
    (key) =>
    (...args) => css`
      @media (min-width: ${breakpoints[key]}px) {
        ${css(...args)}
      }
    `,
  down:
    (key) =>
    (...args) => css`
      @media (max-width: ${breakpoints[key] - 1}px) {
        ${css(...args)}
      }
    `,
  between:
    (start, end) =>
    (...args) => css`
      @media (min-width: ${breakpoints[start]}px) and (max-width: ${breakpoints[
          end
        ] - 1}px) {
        ${css(...args)}
      }
    `,
};

// Export all breakpoint-related utilities
// This allows us to import everything at once if needed
export default {
  breakpoints,
  heightBreakpoints,
  minWidth,
  maxWidth,
  minHeight,
  maxHeight,
  aspectRatios,
  getAspectRatio,
  aspectRatioQueries,
  combinedQueries,
  media,
};
