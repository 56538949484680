// components/Resume/ResumeCanvas.jsx

import React, { useState, useEffect, Suspense, lazy } from "react";
import styled from "styled-components";
import { Canvas } from "@react-three/fiber";
import { PresentationControls } from "@react-three/drei";
import { hexToRgba } from "../../utils/hexToRGBA";
import Loader from "../Loader/Loader";
import useStore from "../../stores/store";

// Lazy load scenes
const BetaBlox = lazy(() => import("../../scenes/BetaBlox"));
const DefaultScene = lazy(() => import("../../scenes/DefaultScene"));

const CanvasContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
`;

const CanvasLoader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => hexToRgba(theme.bodyBgSecondary, 0.5)};
  z-index: 15;
`;

const FadeInCanvas = styled(Canvas)`
  opacity: ${({ $isLoaded }) => ($isLoaded ? 1 : 0)};
  transition: opacity 1s ease-in-out;
`;

const Scene = ({ experienceId, isEmptyState, onLoad }) => {
  useEffect(() => {
    // add logic to load different 3D models or scenes based on the experienceId
  }, [experienceId]);

  if (isEmptyState) {
    return <DefaultScene onLoad={onLoad} />;
  }

  return <DefaultScene onLoad={onLoad} />;
};

const ResumeCanvas = ({ experienceId, isEmptyState }) => {
  const { theme } = useStore();
  const [sceneLoaded, setSceneLoaded] = useState(false);

  const handleSceneLoad = () => {
    setSceneLoaded(true);
  };

  return (
    <CanvasContainer>
      <Suspense
        fallback={
          <CanvasLoader theme={theme}>
            <Loader isLoading={true} text="Loading 3D Scene..." />
          </CanvasLoader>
        }
      >
        <FadeInCanvas
          $isLoaded={sceneLoaded}
          camera={{ position: [0, 0, 5], fov: 75 }}
        >
          <ambientLight intensity={0.5} />
          <pointLight position={[10, 10, 10]} />
          <PresentationControls
            global
            config={{ mass: 2, tension: 500 }}
            snap={{ mass: 4, tension: 1500 }}
            rotation={[0, 0.3, 0]}
            polar={[-Math.PI / 3, Math.PI / 3]}
            azimuth={[-Math.PI / 1.4, Math.PI / 2]}
          >
            <Scene
              experienceId={experienceId}
              isEmptyState={isEmptyState}
              onLoad={handleSceneLoad}
            />
          </PresentationControls>
        </FadeInCanvas>
      </Suspense>
    </CanvasContainer>
  );
};

export default ResumeCanvas;
