import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { fetchCaseStudy } from "../../services/CaseStudyService";
import Scrollbar from "../Scrollbar/Scrollbar";
import { useViewport } from "../../context/ViewportContext";
import useStore from "../../stores/store";
import {
  Wrapper,
  Content,
  ContentWrapper,
  ScrollContent,
} from "./StyledComponents";
import Hero from "./Hero/Hero";
import Mission from "./Mission/Mission";
import Scope from "./Scope/Scope";
import Challenges from "./Challenges/Challenges";
import Outcomes from "./Outcomes/Outcomes";
import Impact from "./Impact/Impact";
import Credits from "./Credits/Credits";
import Backstory from "./Backstory/Backstory";
import Goal from "./Goal/Goal";
import Gap from "./Gap/Gap";
import Gamble from "./Gamble/Gamble";
import Approach from "./Approach/Approach";
import Phases from "./Phases/Phases";
import Testimonials from "./Testimonials/Testimonials";
import ClosingThoughts from "./ClosingThoughts/ClosingThoughts";
import Loader from "../Loader/Loader";
import { BentoBox } from "../BentoBox/BentoBox";
import { breakpoints, media } from "../../styles/breakpoints";

const CaseStudyDetail = () => {
  const [caseStudy, setCaseStudy] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { caseStudySlug } = useParams();

  const viewport = useViewport();
  const { theme } = useStore();

  const leftMargin = useMemo(() => {
    if (!viewport) return "5.25rem";
    const { width } = viewport;

    if (width < breakpoints.mobileL) return "3rem";
    if (width < breakpoints.tabletL) return "3.5rem";
    if (width < breakpoints.laptopS) return "5.25rem";
    if (width < breakpoints.desktop) return "5.25rem";
    return "5.25rem";
  }, [viewport]);

  const fetchCaseStudyData = useCallback(async () => {
    try {
      const data = await fetchCaseStudy(caseStudySlug);
      setCaseStudy(data);
    } catch (error) {
      console.error("Error fetching case study detail:", error);
      setError(
        error.message || "An error occurred while fetching the case study."
      );
    } finally {
      setIsLoading(false);
    }
  }, [caseStudySlug]);

  useEffect(() => {
    fetchCaseStudyData();
  }, [fetchCaseStudyData]);

  const calculateRowPositions = useCallback(
    (caseStudy) => {
      let currentRow = 1;
      const positions = {
        hero: 1,
        mission: 2,
        scope: 2,
        challenges: 3,
        outcomes: 3,
        impact: 4,
        credits: 2,
      };
      currentRow = 6;

      const flexibleComponents = [
        "backstory",
        "goal",
        "gap",
        "gamble",
        "approach",
        "phases",
      ];

      flexibleComponents.forEach((component) => {
        if (caseStudy[component]) {
          positions[component] = currentRow;
          currentRow +=
            component === "phases" ? caseStudy[component].length : 1;
        }
      });

      // Handle testimonials separately
      if (caseStudy.testimonials && caseStudy.testimonials.length > 0) {
        positions.testimonials = currentRow;
        const { width } = viewport;
        if (width < breakpoints.tabletM) {
          currentRow += caseStudy.testimonials.length; // Each testimonial gets its own row
        } else {
          currentRow += Math.ceil(caseStudy.testimonials.length / 3); // Assuming max 3 columns
        }
      }
      positions.closingThoughts = currentRow;
      return positions;
    },
    [viewport]
  );

  const calculateTestimonialLayout = useCallback(
    (testimonials) => {
      if (!testimonials || testimonials.length === 0) return [];

      const { width } = viewport;
      let columns, span;

      if (width < breakpoints.tabletM) {
        columns = 1;
        span = 2;
      } else if (width < breakpoints.laptopS) {
        columns = Math.min(2, testimonials.length);
        span = 3;
      } else if (width < breakpoints.desktop) {
        columns = Math.min(3, testimonials.length);
        span = 2;
      } else {
        columns = Math.min(3, testimonials.length);
        span = 4;
      }

      return testimonials.map((_, index) => ({
        column: (index % columns) * span + 1,
        span: span,
        row: Math.floor(index / columns) + 1,
      }));
    },
    [viewport]
  );

  if (isLoading)
    return <Loader isLoading={isLoading} text="Loading Case Study..." />;
  if (error) return <ErrorMessage error={error} slug={caseStudySlug} />;
  if (!caseStudy) return <div>Case study not found. Slug: {caseStudySlug}</div>;

  const rowPositions = calculateRowPositions(caseStudy);
  const testimonialLayout = calculateTestimonialLayout(caseStudy.testimonials);

  console.log(rowPositions);

  return (
    <Wrapper theme={theme}>
      <Content>
        <Scrollbar
          scrollbarPosition="left"
          theme={theme}
          applyScrolling={true}
          forceScrollbar={true}
          trackMargin={{
            top: "0",
            bottom: "0",
            left: leftMargin,
            right: "0",
          }}
        >
          <ScrollContent>
            <ContentWrapper>
              <BentoBox>
                <CaseStudyComponents
                  caseStudy={caseStudy}
                  rowPositions={rowPositions}
                  testimonialLayout={testimonialLayout}
                />
              </BentoBox>
            </ContentWrapper>
          </ScrollContent>
        </Scrollbar>
      </Content>
    </Wrapper>
  );
};

const ErrorMessage = ({ error, slug }) => (
  <div className="error-container">
    <h2>Error Loading Case Study</h2>
    <p>{error}</p>
    <p>Slug: {slug}</p>
  </div>
);

const CaseStudyComponents = ({
  caseStudy,
  rowPositions,
  testimonialLayout,
}) => (
  <>
    <Hero caseStudy={caseStudy} row={rowPositions.hero} />
    <Mission mission={caseStudy.mission} row={rowPositions.mission} />
    <Scope scope={caseStudy.scope} row={rowPositions.scope} />
    <Credits credits={caseStudy.credits} row={rowPositions.credits} />
    <Challenges
      challenges={caseStudy.challenges}
      row={rowPositions.challenges}
    />
    <Outcomes outcomes={caseStudy.outcomes} row={rowPositions.outcomes} />
    <Impact impacts={caseStudy.impacts} row={rowPositions.impact} />
    {caseStudy.backstory && (
      <Backstory
        backstory={caseStudy.backstory}
        row={rowPositions.backstory}
        disableBackground={true}
      />
    )}
    {caseStudy.goal && (
      <Goal
        goal={caseStudy.goal}
        row={rowPositions.goal}
        disableBackground={true}
      />
    )}
    {caseStudy.gap && (
      <Gap
        gap={caseStudy.gap}
        row={rowPositions.gap}
        disableBackground={true}
      />
    )}
    {caseStudy.gamble && (
      <Gamble
        gamble={caseStudy.gamble}
        row={rowPositions.gamble}
        disableBackground={true}
      />
    )}
    {caseStudy.approach && (
      <Approach
        approach={caseStudy.approach}
        row={rowPositions.approach}
        disableBackground={true}
      />
    )}
    {caseStudy.phases && caseStudy.phases.length > 0 && (
      <Phases
        phases={caseStudy.phases}
        startRow={rowPositions.phases}
        disableBackground={true}
      />
    )}
    {caseStudy.testimonials && caseStudy.testimonials.length > 0 && (
      <>
        {caseStudy.testimonials.map((testimonial, index) => (
          <Testimonials
            key={`testimonial_${testimonial.id}`}
            testimonial={testimonial}
            row={`${rowPositions.testimonials} / span 1`}
            column={`${testimonialLayout[index].column} / span ${testimonialLayout[index].span}`}
          />
        ))}
      </>
    )}
    {caseStudy.closing_thoughts && (
      <ClosingThoughts
        closingThoughts={caseStudy.closing_thoughts}
        row={rowPositions.closingThoughts}
      />
    )}
  </>
);

export default CaseStudyDetail;
