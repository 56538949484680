// hooks/useResponsiveGSAP.js

import { useEffect, useRef, useMemo } from "react";
import { gsap } from "gsap";
import { breakpoints } from "../styles/breakpoints";

const useResponsiveGSAP = (animationConfigs, dependencies = []) => {
  const matchMediaRef = useRef(null);
  const animationsRef = useRef({});

  // Memoize the animationConfigs
  const memoizedAnimationConfigs = useMemo(
    () => animationConfigs,
    dependencies
  );

  useEffect(() => {
    // Error handling
    if (
      typeof memoizedAnimationConfigs !== "object" ||
      memoizedAnimationConfigs === null
    ) {
      console.error("Invalid animationConfigs provided to useResponsiveGSAP");
      return;
    }

    matchMediaRef.current = gsap.matchMedia();

    const sortedBreakpoints = Object.entries(breakpoints).sort(
      ([, a], [, b]) => b - a
    );

    sortedBreakpoints.forEach(([breakpointName, breakpointValue], index) => {
      const minWidth =
        index === sortedBreakpoints.length - 1
          ? 0
          : sortedBreakpoints[index + 1][1];
      const maxWidth = breakpointValue;

      matchMediaRef.current.add(
        `(min-width: ${minWidth}px) and (max-width: ${maxWidth}px)`,
        () => {
          const timeline = gsap.timeline();

          if (
            memoizedAnimationConfigs[breakpointName] &&
            Array.isArray(memoizedAnimationConfigs[breakpointName])
          ) {
            memoizedAnimationConfigs[breakpointName].forEach(
              (animation, animationIndex) => {
                if (typeof animation.animate === "function") {
                  gsap.context(() => {
                    // Store the animation in the ref for potential cleanup
                    animationsRef.current[
                      `${breakpointName}-${animationIndex}`
                    ] = animation.animate(timeline);
                  });
                }
              }
            );
          }
        }
      );
    });

    // Cleanup function
    return () => {
      // Kill the matchMedia instance
      matchMediaRef.current.kill();

      // Clean up specific animations
      Object.values(animationsRef.current).forEach((animation) => {
        if (animation && typeof animation.kill === "function") {
          animation.kill();
        }
      });
      animationsRef.current = {};
    };
  }, [memoizedAnimationConfigs]);

  return matchMediaRef;
};

export default useResponsiveGSAP;
