// createCaseStudySlice.js

import { queryClient } from "../store";
import * as CaseStudyService from "../../services/CaseStudyService";

const createCaseStudySlice = (set, get) => ({
  currentCaseStudy: null,
  caseStudies: [],
  isLoading: false,
  error: null,

  setCurrentCaseStudy: (caseStudy) => set({ currentCaseStudy: caseStudy }),
  setCaseStudies: (caseStudies) => set({ caseStudies }),

  fetchCaseStudy: async (slug) => {
    set({ isLoading: true, error: null });
    try {
      console.log(`Fetching case study with slug: ${slug}`);
      const caseStudy = await queryClient.fetchQuery(["caseStudy", slug], () =>
        CaseStudyService.fetchCaseStudy(slug)
      );
      console.log("Fetched case study:", caseStudy);
      set({ currentCaseStudy: caseStudy, isLoading: false });
      return caseStudy;
    } catch (error) {
      console.error("Error fetching case study:", error);
      set({ error: error.message, isLoading: false });
      throw error;
    }
  },

  fetchCaseStudies: async () => {
    set({ isLoading: true, error: null });
    try {
      console.log("Fetching all case studies");
      const caseStudies = await queryClient.fetchQuery(
        "caseStudies",
        CaseStudyService.fetchCaseStudies
      );
      console.log("Fetched case studies:", caseStudies);
      set({ caseStudies, isLoading: false });
      return caseStudies;
    } catch (error) {
      console.error("Error fetching case studies:", error);
      set({ error: error.message, isLoading: false });
      throw error;
    }
  },

  refetchCaseStudies: () => queryClient.invalidateQueries("caseStudies"),
});

export default createCaseStudySlice;
