import React, { useRef, useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import useStore from "../../stores/store";

// Styled components
const ScrollbarContainer = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  ${(props) => props.gridColumn && `grid-column: ${props.gridColumn};`}
`;

const ScrollbarContent = styled.div`
  height: 100%;
  overflow-y: scroll;
  padding-right: ${(props) =>
    props.scrollbarPosition === "left" ? "0" : "20px"};
  padding-left: ${(props) =>
    props.scrollbarPosition === "left" ? "20px" : "0"};
  margin-right: ${(props) =>
    props.scrollbarPosition === "left" ? "0" : "-20px"};
  margin-left: ${(props) =>
    props.scrollbarPosition === "left" ? "-20px" : "0"};
  box-sizing: content-box;
  -webkit-overflow-scrolling: touch;

  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ScrollbarTrack = styled.div`
  position: absolute;
  top: ${(props) => props.trackMargin.top};
  bottom: ${(props) => props.trackMargin.bottom};
  ${(props) => props.scrollbarPosition}: ${(props) =>
    props.scrollbarPosition === "left"
      ? props.trackMargin.left
      : props.trackMargin.right};
  width: 8px;
  border-radius: 4px;
  opacity: 1;
  transition: opacity 0.2s;
  z-index: 1000;
`;

const ScrollbarThumb = styled.div`
  position: absolute;
  width: 8px;
  background-color: ${({ theme }) => theme.primaryColor};
  border-radius: 4px;
  cursor: pointer;
  opacity: 1;
  transition:
    opacity 0.2s,
    top 0.1s;
  z-index: 1001;

  &:hover {
    opacity: 1;
  }
`;

const Scrollbar = React.forwardRef(
  (
    {
      children,
      forceScrollbar,
      style,
      scrollbarPosition = "right",
      gridColumn,
      trackMargin = { top: "0px", bottom: "0px", left: "0px", right: "0px" },
    },
    ref
  ) => {
    const { theme } = useStore();
    const contentRef = useRef(null);
    const thumbRef = useRef(null);
    const trackRef = useRef(null);
    const rafRef = useRef(null);
    const [thumbHeight, setThumbHeight] = useState(20);
    const [thumbTop, setThumbTop] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [startY, setStartY] = useState(0);

    const updateScrollbarThumb = useCallback(() => {
      if (!contentRef.current || !trackRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
      const trackHeight = trackRef.current.clientHeight;

      const newThumbHeight = Math.max(
        (clientHeight / scrollHeight) * trackHeight,
        20
      );
      const scrollRatio = scrollTop / (scrollHeight - clientHeight);
      const newThumbTop = scrollRatio * (trackHeight - newThumbHeight);

      rafRef.current = requestAnimationFrame(() => {
        setThumbHeight(newThumbHeight);
        setThumbTop(newThumbTop);
      });
    }, []);

    useEffect(() => {
      updateScrollbarThumb();
      window.addEventListener("resize", updateScrollbarThumb);
      return () => {
        window.removeEventListener("resize", updateScrollbarThumb);
        if (rafRef.current) {
          cancelAnimationFrame(rafRef.current);
        }
      };
    }, [updateScrollbarThumb]);

    useEffect(() => {
      updateScrollbarThumb();
    }, [children, updateScrollbarThumb]);

    const handleScroll = useCallback(
      (e) => {
        e.stopPropagation();
        if (!isDragging) {
          updateScrollbarThumb();
        }
      },
      [isDragging, updateScrollbarThumb]
    );

    const handleMouseDown = useCallback(
      (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
        setStartY(e.clientY - thumbTop);
      },
      [thumbTop]
    );

    const handleMouseMove = useCallback(
      (e) => {
        if (!isDragging || !contentRef.current || !trackRef.current) return;

        const { scrollHeight, clientHeight } = contentRef.current;
        const trackHeight = trackRef.current.clientHeight;

        const newTop = e.clientY - startY;
        const maxTop = trackHeight - thumbHeight;
        const boundedTop = Math.max(0, Math.min(newTop, maxTop));
        const scrollRatio = boundedTop / maxTop;

        rafRef.current = requestAnimationFrame(() => {
          contentRef.current.scrollTop =
            scrollRatio * (scrollHeight - clientHeight);
          setThumbTop(boundedTop);
        });
      },
      [isDragging, startY, thumbHeight]
    );

    const handleMouseUp = useCallback(() => {
      setIsDragging(false);
    }, []);

    const handleTrackClick = useCallback((e) => {
      e.preventDefault();
      e.stopPropagation();
      if (!contentRef.current || !trackRef.current) return;

      const { clientHeight, scrollHeight } = contentRef.current;
      const trackRect = trackRef.current.getBoundingClientRect();
      const clickRatio = (e.clientY - trackRect.top) / trackRect.height;

      rafRef.current = requestAnimationFrame(() => {
        contentRef.current.scrollTop =
          clickRatio * (scrollHeight - clientHeight);
      });
    }, []);

    useEffect(() => {
      if (isDragging) {
        window.addEventListener("mousemove", handleMouseMove);
        window.addEventListener("mouseup", handleMouseUp);
      } else {
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleMouseUp);
      }
      return () => {
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleMouseUp);
      };
    }, [isDragging, handleMouseMove, handleMouseUp]);

    return (
      <ScrollbarContainer style={style} theme={theme} gridColumn={gridColumn}>
        <ScrollbarContent
          ref={contentRef}
          onScroll={handleScroll}
          theme={theme}
          scrollbarPosition={scrollbarPosition}
        >
          {children}
        </ScrollbarContent>
        {(forceScrollbar ||
          (contentRef.current &&
            contentRef.current.scrollHeight >
              contentRef.current.clientHeight)) && (
          <ScrollbarTrack
            ref={trackRef}
            theme={theme}
            className="scrollbar-track"
            onClick={handleTrackClick}
            scrollbarPosition={scrollbarPosition}
            trackMargin={trackMargin}
          >
            <ScrollbarThumb
              ref={thumbRef}
              theme={theme}
              className="scrollbar-thumb"
              style={{
                height: `${thumbHeight}px`,
                top: `${thumbTop}px`,
              }}
              onMouseDown={handleMouseDown}
            />
          </ScrollbarTrack>
        )}
      </ScrollbarContainer>
    );
  }
);

Scrollbar.propTypes = {
  children: PropTypes.node.isRequired,
  forceScrollbar: PropTypes.bool,
  style: PropTypes.object,
  scrollbarPosition: PropTypes.oneOf(["left", "right"]),
  gridColumn: PropTypes.string,
  trackMargin: PropTypes.shape({
    top: PropTypes.string,
    bottom: PropTypes.string,
    left: PropTypes.string,
    right: PropTypes.string,
  }),
};

Scrollbar.defaultProps = {
  scrollbarPosition: "right",
  trackMargin: {},
};

Scrollbar.displayName = "Scrollbar";

export default Scrollbar;
