// components/CaseStudy/Impact/Impact.jsx

import React from "react";
import styled from "styled-components";
import Markdown from "react-markdown";
import { BentoItem } from "../../BentoBox/BentoBox";
import { media } from "../../../styles/breakpoints";

export const ImpactItem = styled(BentoItem)`
  grid-column: 1 / -1;
  grid-row: auto;
  height: fit-content;
  grid-row: 5;

  h2 {
    position: relative;
    z-index: 10;
  }

  ${media.up("desktop")`
    grid-column: 3 / span 5;
  `}

  ${media.between("laptopS", "desktop")`
    grid-column: 1 / -1;
  `}

  ${media.between("tabletM", "laptopS")`
    grid-column: 1/-1;
  `}

  ${media.between("mobileL", "tabletM")`
    grid-row: 6;
    grid-column: 2 / span 2;
    margin-right: 0.5rem;
  `}

  ${media.down("mobileL")`
    grid-row: 6;
    grid-column: 2 / span 2;
    margin-right: 0.5rem;
    margin-top: 2.5rem;
  `}
`;

const ImpactContent = styled(BentoItem)`
  border-radius: 10px;
  padding: 0.5rem 2rem 0.75rem 2rem;
  transition: all 0.3s ease;
  position: relative;

  &:hover {
    background-color: rgba(255, 255, 255, 0.75);
    transform: translateY(-5px);
  }

  ${media.up("desktop")`
    padding: 1.5rem 2rem 1rem 2rem;
  `}

  ${media.between("laptopS", "desktop")`
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  `}

  ${media.between("tabletM", "laptopS")`
    padding: 1rem 1.25rem 0.5rem 1.25rem;
  `}

  ${media.down("tabletM")`
    padding: 0.25rem 1.5rem 0.5rem 1rem;
  `}
`;

const ImpactList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  ${media.up("desktop")`
    gap: 0.75rem;
    padding-top: 1rem;
  `}

  ${media.between("laptopS", "desktop")`
    gap: 0.25rem;
    padding-top: 1rem;
  `}

  ${media.between("tabletM", "laptopS")`
    gap: 0rem;
    padding-top: 0rem;
  `}

  ${media.down("tabletM")`
    gap: 0.25rem;
    padding-top: 1rem;
  `}
`;

const ImpactEntry = styled.div`
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  transition: all 0.3s ease;
  color: ${(props) => props.theme.primaryColor || "#000"};

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    transform: translateY(-5px);
  }
`;

const Impact = ({ impacts }) => {
  if (!impacts || impacts.length === 0) return null;

  return (
    <ImpactItem disableBackground={true}>
      <h2>Impact</h2>
      <ImpactContent>
        <ImpactList>
          {impacts.map((impact) => (
            <ImpactEntry key={impact.id}>
              <Markdown>{impact.description}</Markdown>
            </ImpactEntry>
          ))}
        </ImpactList>
      </ImpactContent>
    </ImpactItem>
  );
};

export default Impact;
