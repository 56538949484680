/**
 * createTechnologySlice.js
 *
 * This file manages the technology-related state in our application.
 * It provides functionality for fetching and storing technology data.
 */

import { queryClient } from "../store";
import * as TechnologyService from "../../services/TechnologyService";

/**
 * Creates and returns the technology slice for our Zustand store.
 */
const createTechnologySlice = (set, get) => ({
  // Stores the list of technologies
  technologies: [],

  // Updates the technologies in the store
  setTechnologies: (technologies) => set({ technologies }),

  /**
   * Fetches technologies from the API and updates the store
   */
  fetchTechnologies: async () => {
    const technologies = await queryClient.fetchQuery(
      "technologies",
      TechnologyService.fetchTechnologies
    );
    set({ technologies });
    return technologies;
  },

  // Invalidates the technologies query, triggering a refetch on next request
  refetchTechnologies: () => queryClient.invalidateQueries("technologies"),
});

export default createTechnologySlice;

/**
 * Example usage in a component:
 *
 * import useStore from '../store';
 *
 * function TechnologyList() {
 *   const { technologies, fetchTechnologies } = useStore();
 *
 *   useEffect(() => {
 *     fetchTechnologies();
 *   }, []);
 *
 *   return (
 *     <ul>
 *       {technologies.map(tech => (
 *         <li key={tech.id}>{tech.name} - Category: {tech.category}</li>
 *       ))}
 *     </ul>
 *   );
 * }
 *
 * This example shows how to fetch and display a list of technologies.
 */
