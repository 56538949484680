// components/CaseStudy/ClosingThoughts/ClosingThoughts.jsx

import React from "react";
import styled from "styled-components";
import Markdown from "react-markdown";
import { BentoItem } from "../../BentoBox/BentoBox";
import { media } from "../../../styles/breakpoints";

export const ClosingThoughtsItem = styled(BentoItem)`
  grid-column: 1 / -1;
  grid-row: auto;
  height: fit-content;

  & h2 {
    position: relative;
    z-index: 10;
  }

  ${media.up("desktop")`
    grid-column: 2 / -2;
    grid-row: ${(props) => props.row};
  `}

  ${media.between("laptopL", "desktop")`
    grid-column: 2 / -2;
  `}

  ${media.between("laptopS", "laptopL")`
    grid-column: 2 / -1;
    margin-top: 5rem
  `}

  ${media.between("tabletM", "laptopS")`
    grid-column: 2 / -2;
    margin-top: 2.5rem
  `}

  ${media.between("mobileL", "tabletM")`
    grid-column: 2 /span 2;
    margin-right: 0.5rem;
    margin-top: 2.5rem;
  `}

  ${media.down("mobileL")`
    grid-column: 2 /span 2;
    margin-right: 0.5rem;
    margin-top: 2.5rem;
  `}
`;

const ClosingThoughtsContent = styled(BentoItem)`
  border-radius: 10px;
  padding: 1rem 2rem 0.75rem 2rem;
  position: relative;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.75);
    transform: translateY(-5px);
  }

  ${media.up("desktop")`
    padding: 1.5rem 2rem 1rem 2rem;
  `}

  ${media.between("laptopS", "desktop")`
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  `}

  ${media.between("tabletM", "laptopS")`
    padding: 1rem 1.25rem 0.5rem 1.25rem;
  `}

  ${media.down("tabletM")`
    padding: 1.25rem 1.5rem 0.5rem 1.5rem;
  `}
`;

const ClosingThoughts = ({ closingThoughts, row }) => {
  if (!closingThoughts) return null;

  return (
    <ClosingThoughtsItem row={row} disableBackground={true}>
      <h2>Closing Thoughts</h2>
      <ClosingThoughtsContent>
        <Markdown>{closingThoughts}</Markdown>
      </ClosingThoughtsContent>
    </ClosingThoughtsItem>
  );
};

export default ClosingThoughts;
