// components/SlideOut/SlideOut.jsx

import React, { useRef, useState, useEffect, useMemo } from "react";
import { useViewport } from "../../context/ViewportContext";
import useResponsiveGSAP from "../../hooks/useResponsiveGSAP";
import { X, ChevronLeft, ChevronRight } from "lucide-react";
import { createSlideOutAnimations } from "./anims";
import { StyledSlideOut, CloseButton, CollapseButton } from "./SlideOut.styles";
import useStore from "../../stores/store";

const SlideOut = ({
  children,
  isCollapsed,
  onCollapse,
  selectedItem,
  onItemSelect,
  onBackClick,
  isLoading,
  showCloseButton,
}) => {
  const { theme } = useStore();
  const viewport = useViewport();
  const slideOutRef = useRef(null);
  const collapseButtonRef = useRef(null);
  const [isContentReady, setIsContentReady] = useState(false);

  const animations = useMemo(
    () => createSlideOutAnimations(isCollapsed, theme, viewport),
    [isCollapsed, theme, viewport]
  );

  useResponsiveGSAP(animations, [isCollapsed, theme, viewport, isContentReady]);

  useEffect(() => {
    setIsContentReady(true);
  }, []);

  const childrenProps = {
    selectedItem,
    onItemSelect,
    onBackClick,
    isCollapsed,
    showCloseButton,
    theme,
    closeButton: showCloseButton && selectedItem && (
      <CloseButton onClick={onBackClick} theme={theme}>
        <X size={16} />
      </CloseButton>
    ),
  };

  return (
    <StyledSlideOut
      className="slide-out"
      theme={theme}
      $viewport={viewport}
      ref={slideOutRef}
      isCollapsed={isCollapsed}
    >
      <CollapseButton
        className="collapse-button"
        theme={theme}
        onClick={onCollapse}
        ref={collapseButtonRef}
        $viewport={viewport}
        isCollapsed={isCollapsed}
      >
        {isCollapsed ? <ChevronRight size={20} /> : <ChevronLeft size={20} />}
      </CollapseButton>
      {typeof children === "function"
        ? children(childrenProps)
        : React.Children.map(children, (child) =>
            React.cloneElement(child, childrenProps)
          )}
    </StyledSlideOut>
  );
};

export default SlideOut;
