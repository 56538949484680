// CaseStudyPage.jsx
import styled from "styled-components";
import CaseStudyGrid from "../components/CaseStudy/CaseStudyGrid";
import CaseStudyList from "../components/CaseStudy/CaseStudyList";

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const CaseStudiesPage = () => {
  return (
    <Container>
      <CaseStudyList />
    </Container>
  );
};

export default CaseStudiesPage;
