/**
 * viewportSlice.js
 *
 * This file manages the viewport-related state in the application.
 * It provides functionality for tracking and updating viewport dimensions and device type.
 */

import debounce from "lodash/debounce";
import { breakpoints } from "../../styles/breakpoints";

/**
 * Determines the device type based on the viewport width
 * @param {number} width - The current viewport width
 * @returns {string} The device type: 'mobile', 'tablet', or 'desktop'
 */
const getDeviceType = (width) => {
  if (width <= breakpoints.mobileL) return "mobile";
  if (width <= breakpoints.tabletL) return "tablet";
  return "desktop";
};

/**
 * Creates and returns the viewport slice for our Zustand store.
 * @param {function} set - Zustand's set function
 * @param {function} get - Zustand's get function
 * @returns {Object} The viewport slice
 */
const createViewportSlice = (set, get) => ({
  // The current viewport state
  viewport: {
    width: typeof window !== "undefined" ? window.innerWidth : 1024,
    height: typeof window !== "undefined" ? window.innerHeight : 768,
    isLandscape:
      typeof window !== "undefined"
        ? window.innerWidth > window.innerHeight
        : true,
    deviceType:
      typeof window !== "undefined"
        ? getDeviceType(window.innerWidth)
        : "desktop",
  },

  // Flag to check if the viewport has been initialized
  isViewportInitialized: false,

  /**
   * Sets the viewport state
   * @param {Object} newViewport - The new viewport state
   */
  setViewport: (newViewport) =>
    set((state) => ({ viewport: { ...state.viewport, ...newViewport } })),

  /**
   * Updates the viewport state based on current window dimensions
   * This function is debounced to prevent excessive updates
   */
  updateViewport: debounce(() => {
    if (typeof window === "undefined") return;

    const width = window.innerWidth;
    const height = window.innerHeight;
    set((state) => ({
      viewport: {
        ...state.viewport,
        width,
        height,
        isLandscape: width > height,
        deviceType: getDeviceType(width),
      },
    }));
  }, 250),

  /**
   * Initializes the viewport state and sets up event listeners
   * @throws {Error} If called more than once
   */
  initializeViewport: () => {
    const state = get();
    if (state.isViewportInitialized) {
      throw new Error("Viewport has already been initialized");
    }

    if (typeof window === "undefined") {
      console.warn("Window is not defined. Viewport initialization skipped.");
      return;
    }

    state.updateViewport();
    window.addEventListener("resize", state.updateViewport);
    window.addEventListener("orientationchange", state.updateViewport);
    set({ isViewportInitialized: true });
  },

  /**
   * Removes event listeners for viewport updates
   * @throws {Error} If called before initialization
   */
  cleanupViewport: () => {
    const state = get();
    if (!state.isViewportInitialized) {
      throw new Error("Viewport has not been initialized");
    }

    if (typeof window === "undefined") {
      console.warn("Window is not defined. Viewport cleanup skipped.");
      return;
    }

    window.removeEventListener("resize", state.updateViewport);
    window.removeEventListener("orientationchange", state.updateViewport);
    set({ isViewportInitialized: false });
  },

  /**
   * Retrieves the current viewport state
   * @throws {Error} If accessed before initialization
   * @returns {Object} The current viewport state
   */
  getViewport: () => {
    const state = get();
    if (!state.isViewportInitialized) {
      throw new Error(
        "Viewport has not been initialized. Call initializeViewport first."
      );
    }
    return state.viewport;
  },
});

export default createViewportSlice;

/**
 * Example usage in a component:
 *
 * import useStore from '../store';
 * import { useEffect } from 'react';
 *
 * function ResponsiveComponent() {
 *   const { initializeViewport, cleanupViewport, getViewport } = useStore();
 *
 *   useEffect(() => {
 *     initializeViewport();
 *     return cleanupViewport;
 *   }, []);
 *
 *   const viewport = getViewport();
 *
 *   return (
 *     <div>
 *       <p>Current device: {viewport.deviceType}</p>
 *       <p>Orientation: {viewport.isLandscape ? 'Landscape' : 'Portrait'}</p>
 *       <p>Viewport size: {viewport.width}x{viewport.height}</p>
 *     </div>
 *   );
 * }
 *
 * This example shows how to initialize viewport tracking and use viewport information in a component.
 */
