// stores/slices/createArticleSlice.js

import { queryClient } from "../store";
import * as ArticleService from "../../services/ArticleService";

/**
 * Article Slice
 *
 * This slice manages the state and actions related to articles in the application.
 * It integrates with React Query for efficient data fetching and caching.
 *
 * @param {Function} set - Function to update the store's state
 * @param {Function} get - Function to get the current state
 * @returns {Object} The article slice object with state and actions
 */
const createArticleSlice = (set, get) => ({
  /**
   * Array of articles
   * @type {Array<Object>}
   */
  articles: [],

  /**
   * Sets the articles in the store
   * @param {Array<Object>} articles - The articles to set
   */
  setArticles: (articles) => set({ articles }),

  /**
   * Fetches articles from the API and updates the store
   * @returns {Promise<Array<Object>>} The fetched articles
   */
  fetchArticles: async () => {
    const articles = await queryClient.fetchQuery(
      "articles",
      ArticleService.fetchArticles
    );
    set({ articles });
    return articles;
  },

  /**
   * Invalidates the articles query, forcing a refetch on the next request
   */
  refetchArticles: () => queryClient.invalidateQueries("articles"),
});

export default createArticleSlice;

// Usage example:
//
// import useStore from '../store';
//
// function ArticleList() {
//   const { articles, fetchArticles } = useStore();
//
//   useEffect(() => {
//     fetchArticles();
//   }, []);
//
//   return (
//     <ul>
//       {articles.map(article => (
//         <li key={article.id}>{article.title}</li>
//       ))}
//     </ul>
//   );
// }
